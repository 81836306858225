import { MouseEventHandler } from 'react'
import * as THREE from 'three'

import { ThreeInit } from './init'
import Style, { I_Style } from './style'

interface MouseEvents {
	onClick?: MouseEventHandler
	onContextMenu?: MouseEventHandler
	hoverIn?: () => void
	hoverOut?: () => void
	dragStart?: () => void
	drag?: (x: number, y: number) => void
	dragEnd?: () => void
}

// ORCHESTRA CLASS => SHOULD EXTENDS THREE.object !?
class ViewportElement {
	group: THREE.Group
	events: MouseEvents
	three: ThreeInit
	styles: Style

	constructor(three) {
		this.three = three
		this.group = new THREE.Group()
		this.styles = new Style()

		this.events = {
			drag: this.drag.bind(this),
			dragStart: this.dragStart.bind(this),
			dragEnd: this.dragEnd.bind(this),
			hoverIn: this.hoverIn.bind(this),
			hoverOut: this.hoverOut.bind(this),
			onClick: this.onClick.bind(this),
			onContextMenu: this.onContextMenu.bind(this),
		}
	}

	destruct() {}

	onContextMenu() {
		console.log('PARENT ctxMenu')
	}

	onClick() {
		console.log('PARENT onClick')
	}

	dragStart() {
		console.log('PARENT dragStart')
	}

	dragEnd() {
		console.log('PARENT dragEnd')
	}

	drag(x, y) {
		console.log('PARENT drag')
	}

	hoverIn(evt) {
		console.log('PARENT HoverIn')
	}

	hoverOut(evt) {
		console.log('PARENT HoverOut')
	}

	animate(d) {
		this.styles?.animate?.(d)
	}
}

export default ViewportElement
