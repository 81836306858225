//@ts-expect-error
import { notif } from '@ledr/layout'
import {
	FunctionComponent,
	useState,
	useEffect,
	useMemo,
	useContext,
} from 'react'
import useEntity from '../hook/entity'
import InstrumentsLinkerContext from '../context/instrumentsLinker'
import Loading from '../components/utilities/Loading'
import Error from '../components/utilities/Error'
import EntityForm from '../components/form/Entity'
import Avial, { AvialEntity, V_Entity } from '@ledr/ts-client'

import {
	Print,
	SidebarMenu,
	T_SidebarCategories,
	wmContext,
	WindowChild,
	Metadata,
	Tooltip,
} from '@ledr/instruments'

import { PrintEntityStore } from '../hoc/connectedComponents'

interface EntityWindowTabProps {
	options: any
	onChange: (e: any) => void
	//@ts-ignore
	selectedEntity: any // Avial.Entity;
	setSelectedEntity: any // Avial.Entity;
	history: any[]
	setHistory: any
	entityContent: AvialEntity
}

const EntityWindowTab: FunctionComponent<EntityWindowTabProps> = (props) => {
	function onToggleOption(key: string) {
		props.onChange({
			...props.options,
			[key]: !props.options[key],
		})
	}

	return (
		<>
			<div
				className={'windowTab'}
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					width: '100%',
				}}
			>
				<div style={{ display: 'flex' }}>
					{/*
					<button
						className={props.options.isValueTypeDisplayed ? "active" : ""}
						onClick={() => onToggleOption("isValueTypeDisplayed")}
					>
						Tag
					</button>
					<button
						className={props.options.isEdit ? "active" : ""}
						onClick={() => onToggleOption("isEdit")}
					>
						Edit
					</button>
						*/}
					<button
						className={props.options.isDebugPrinter ? 'active' : ''}
						onClick={() => onToggleOption('isDebugPrinter')}
					>
						debug
					</button>
					<button
						className={props.options.isDisplayRaw ? 'active' : ''}
						onClick={() => onToggleOption('isDisplayRaw')}
					>
						Raw
					</button>
				</div>

				<div style={{ display: 'flex' }}>
					<Tooltip
						// @ts-ignore
						text={
							<div>
								{props.history.map((h, i) => {
									return (
										<div
											key={i}
											onClick={() => {
												props.setSelectedEntity(h)
											}}
										>
											{h.toString()}
										</div>
									)
								})}
							</div>
						}
						position={'bottom'}
					>
						{/*
				// @ts-ignore */}
						<div style={{ display: 'flex' }}>
							<button
								className={
									props.options.isValueTypeDisplayed
										? 'active'
										: ''
								}
								onClick={() => {
									if (props.history.length > 1) {
										props.setSelectedEntity(
											props.history[1]
										)
										props.setHistory(props.history.slice(1))
									}
								}}
							>
								{'<'}
							</button>

							<button
								className={
									props.options.isValueTypeDisplayed
										? 'active'
										: ''
								}
								onClick={() => {}}
							>
								{'>'}
							</button>
						</div>
					</Tooltip>
					<EntityForm
						entity={props.selectedEntity}
						onSubmit={props.setSelectedEntity}
					/>
				</div>

				<button
					onClick={() => {
						navigator.clipboard.writeText(
							JSON.stringify(props.entityContent)
						)
						notif.info(
							<div style={{ textAlign: 'center' }}>
								Entity {props.selectedEntity.toString()} content
								<br />
								copied in clipboard
							</div>
						)
					}}
				>
					Json
				</button>
			</div>
		</>
	)
}

interface StoreProps {}

const Store: FunctionComponent<StoreProps> = (props) => {
	const MyContext = useContext(InstrumentsLinkerContext)
	const wm = useContext(wmContext)

	const [selectedEntity, setSelectedEntity] = useState(
		new Avial.values.V_Entity(
			//@ts-ignore
			wm.win?.data?.entity ?? '<0>'
		)
	)
	const [history, setHistory] = useState([])

	useEffect(() => {
		MyContext?.out?.['currentEntity']?.(selectedEntity)
	}, [MyContext?.out?.['currentEntity'], selectedEntity])

	useEffect(() => {
		MyContext.declareInOut({
			in: [{ port: 'currentEntity', setter: setSelectedEntity }],
			out: [{ port: 'currentEntity' }, { port: 'selectedEntity' }],
		})
		return () => {
			MyContext.unDeclareInOut()
		}
	}, [])

	const [options, setOptions] = useState({
		isValueTypeDisplayed: false,
		isEdit: false,
		isDebugPrinter: false,
		isDisplayRaw: false,
	})

	const entityStore = useEntity(selectedEntity)
	useEffect(() => {
		let newHistory = [
			selectedEntity,
			...history.filter((h) => !h.equals(selectedEntity)),
		]
		newHistory.splice(15, 10)
		setHistory(newHistory)
	}, [selectedEntity])

	const sidebarCategories: T_SidebarCategories = [
		[
			{
				icon: 'BsFileEarmarkCodeFill',
				color: '#0ba783',
				name: 'Contents',
				content: (
					<Loading
						isLoading={entityStore.isLoadingContents}
						message={`Loading ${selectedEntity.toString()} Contents`}
					>
						<Error error={entityStore.errorContents}>
							<div style={{ padding: '10px' }}>
								<PrintEntityStore
									selectedEntity={selectedEntity}
									options={options}
									onSelect={(entity: V_Entity) => {
										MyContext.out?.['selectedEntity']?.(
											entity
										)
									}}
								/>
							</div>
						</Error>
					</Loading>
				),
			},
			{
				icon: 'BsFillTagFill',
				color: '#729cd4',
				name: 'Metadata',
				content: options.isDisplayRaw ? (
					<div style={{ padding: '10px' }}>
						<Loading
							isLoading={entityStore.isLoadingMetadata}
							message={`Loading ${selectedEntity.toString()} Contents`}
						>
							<Print
								name={'Metadata'}
								value={
									entityStore.metadata
										? entityStore?.metadata
										: 'Loading Metadata'
								}
								maxDeepness={100}
							/>
						</Loading>
					</div>
				) : entityStore?.metadata ? (
					<Metadata data={entityStore?.metadata} />
				) : (
					<>Loading</>
				),
			},
		],
	]

	const tab = useMemo(
		() => (
			<EntityWindowTab
				options={options}
				onChange={(e) => {
					setOptions(e)
				}}
				selectedEntity={selectedEntity}
				setSelectedEntity={setSelectedEntity}
				history={history}
				setHistory={setHistory}
				entityContent={entityStore.contents}
			/>
		),
		[
			options,
			selectedEntity,
			setSelectedEntity,
			history,
			setHistory,
			entityStore.contents,
		]
	)
	const win = useMemo(
		() => <SidebarMenu categories={sidebarCategories} />,
		[options, entityStore, MyContext.out?.['selectedEntity']]
	)
	return <WindowChild tab={tab}>{win}</WindowChild>
}

export default Store
