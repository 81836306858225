import ViewportElement from './ViewportElement'
import { CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js'
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js'

import { createRoot } from 'react-dom/client'

// [REACT 2/3 init] and [FREE] (and [updateLifecycle!?]) functions
// context provider, custom hook
// wrapper that land component in the right env
// (newarly seamless as native but with threeXYetc)

class JSX2DNode extends ViewportElement {
	element: CSS2DObject
	div: HTMLElement
	root
	constructor(three, jsx) {
		super(three)
		this.div = document.createElement('div')
		//this.div.style.pointerEvents = "auto";
		//	this.div.style.pointerEvents = "none";

		this.root = createRoot(this.div)
		this.root.render(jsx)

		this.element = new CSS2DObject(this.div)
		this.group.add(this.element)
	}

	destruct() {
		this.root.unmount()
		this.div.remove()
		this.group.remove(this.element)
	}
}

class JSX3DNode extends ViewportElement {
	element: CSS3DObject
	div: HTMLElement
	root

	constructor(three, jsx) {
		super(three)
		this.div = document.createElement('div')
		//this.div.style.pointerEvents = "auto";
		this.div.style.pointerEvents = 'none'

		this.root = createRoot(this.div)
		this.root.render(jsx)

		this.element = new CSS3DObject(this.div)
		this.group.add(this.element)
	}

	destruct() {
		this.root.unmount()
		this.div.remove()
		this.group.remove(this.element)
	}
}

export { JSX2DNode, JSX3DNode }
