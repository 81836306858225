//@ts-expect-error
import { notif } from '@ledr/layout'

function generateToken(n) {
	var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
	var token = ''
	for (var i = 0; i < n; i++) {
		token += chars[Math.floor(Math.random() * chars.length)]
	}
	return token
}

import Avial, {
	RequestLog,
	AvialEntity,
	Session,
	V_Entity,
	V_Interchange,
} from '@ledr/ts-client'

import overseer from '../ledr-overseer'
import { addLogAction, editLogAction } from '../store/log/actions'
import { getSpace, addSpace, removeSpace } from './space/space'
import Panels from './panels/panels'

import {
	loadEntityContentsSuccessAction,
	loadEntityMetadataSuccessAction,
} from '../store/entities/actions'

import {
	endpointChangeStatusAction,
	newEndpointAction,
} from '../store/endpoint/actions'

class Api {
	overseer: overseer
	store
	name
	scheme
	host
	port
	session: Session
	keychain
	interval
	sslPem

	panels: Panels

	getSpace: typeof getSpace = getSpace
	addSpace: typeof addSpace = addSpace
	removeSpace: typeof removeSpace = removeSpace

	constructor(opts, overseer) {
		this.overseer = overseer
		this.name = opts.name
		this.host = opts.host
		this.port = opts.port
		this.sslPem = opts.sslPem
		this.scheme = opts.scheme

		this.session = null
		this.keychain = null
		this.interval = null

		console.info(
			`API - ['${this.name}'] ${this.scheme} ${this.host}:${this.port} mounted`
		)

		this.overseer.store.dispatch(
			newEndpointAction({ name: opts.name, host: opts.host })
		)
		this.connect()
		this.panels = new Panels(this, overseer)
	}

	connect() {
		let levels = {
			N: 'null',
			A: 'avesterra',
			S: 'success',
			I: 'info',
			W: 'warn',
			E: 'error',
			F: 'fatal',
			D: 'debug',
			T: 'test',
		}
		this.session = new Session({
			// this.scheme
			host: this.host,
			port: this.port,
			auth: new Avial.values.V_Authorization(0x1n),
			sockets: 4,
			ca_paths: [],
			logs: false,
			secureWebSocket: this.scheme === 'wss' ? true : false,
			log: (msg) => {
				let msgId = generateToken(8)
				let l = levels[msg.level] ?? 'null'

				this.overseer.store.dispatch(
					addLogAction({
						msgId: msgId,
						timestamp: msg.date,
						type: l, //requestLog?.res?.isError() ? 'error' : 'debug',
						channel: 'hgtp',
						msg: msg.msg,
						statusCode: 0, //requestLog?.res?.isError() ? 1 : 0,
					})
				)
				console.log('AI CARAMBA' + msg)
			},

			onRequest: this.logger.bind(this),
			onSocketState: this.socketState.bind(this),
		})

		console.log('NEW TS - SESSION', this.session)

		this.overseer.store.subscribe(() => {
			let keychain = this.overseer.store.getState().user.keychain
			if (!keychain) return
			this.keychain = new Avial.values.V_Authorization(
				keychain.accesses[keychain.current]?.token ?? ''
			)
			this.session.opts.auth = this.keychain
		})

		if (this.overseer.store.getState().user.keychain) {
			let keychain = this.overseer.store.getState().user.keychain
			if (!keychain) return
			this.keychain = new Avial.values.V_Authorization(
				keychain.accesses[keychain.current]?.token ?? ''
			)
			this.session.opts.auth = this.keychain
		}
		//})
	}

	socketState(sockets) {
		this.overseer.store.dispatch(
			endpointChangeStatusAction({
				name: this.name,
				status: 'open',
				channels: sockets,
			})
		)
	}

	logger(requestLog: RequestLog) {
		let msgId = generateToken(8)

		this.overseer.store.dispatch(
			addLogAction({
				msgId: msgId,
				timestamp: Date.now(),
				type: 'waiting', //requestLog?.res?.isError() ? 'error' : 'debug',
				channel: 'hgtp',
				msg: requestLog,
				statusCode: requestLog?.res?.isError() ? 1 : 0,
			})
		)

		requestLog.on('changed', async () => {
			this.overseer.store.dispatch(
				editLogAction({
					msgId: msgId,
					timestamp: Date.now(),
					type:
						!requestLog.req && !requestLog.res
							? 'pending'
							: !requestLog.res
							? 'pending'
							: requestLog.res?.isError()
							? 'error'
							: 'success',

					channel: 'hgtp',
					msg: requestLog,
					statusCode: requestLog?.res?.isError() ? 1 : 0,
				})
			)
		})
	}

	async login(email: string, password: string) {
		return new Promise(async (resolve, reject) => {
			let user = {
				firstName: '-FirstName-',
				lastName: '-LastName-',
				email: email,
				identity: '<0|0|0>',
			}

			const loginResponse = await this.session.call({
				command: 'INVOKE',
				entity: '<1000|1000|4201>',
				precedence: 'AVESTERRA',
				method: 'GET',
				authorization: '00000000-0000-0000-0000-000000000002',
				key: email,
				value: new Avial.values.V_Text(password),
			})

			console.log('LOGIN TRY', loginResponse)

			if (loginResponse.isError()) {
				reject(loginResponse)
				//	keychain.value
				return
			}
			//@ts-ignore
			user.identity = loginResponse.value.value.identity
			let newKeychain = {
				...user,
				keychain: {
					current: 0,
					accesses: [
						{
							compartment: email,
							//@ts-ignore
							token: loginResponse.value.value.token.toString(),
						},
					],
				},
			}
			console.log(newKeychain)
			resolve(newKeychain)
		})
	}

	async getEntity(entity) {
		return this.get(`entity/${entity.nid}/${entity.hid}/${entity.uid}`)
	}

	async invoke(entity: V_Entity, obj: object) {
		return new Promise(async (resolve, reject) => {
			const invokeResult = await this.session.atapi.entities.invoke({
				authorization: this.keychain,
				entity: entity,
				value: new V_Interchange(JSON.stringify(obj)),
				//precedence: Avial.Taxonomy.Precedence.byName.GRAPH,
				timeout: 1000n,
			})
			invokeResult.isError()
				? reject(invokeResult)
				: resolve(invokeResult)
		})
	}

	async get(e) {
		let entity = e
		if (typeof e === 'string') {
			entity = {
				nid: Number(e.split('/')[1]),
				hid: Number(e.split('/')[2]),
				uid: BigInt(e.split('/')[3]),
			}
		}
		return new Promise(async (resolve, reject) => {
			const metadata = await this.session.atapi.entities.fetch({
				authorization: this.keychain,
				entity: entity,
			})
			const contents = await this.session.atapi.entities.invoke({
				entity: entity,
				method: 'RETRIEVE',
				authorization: this.keychain,
			})

			if (metadata.isError() || contents.isError()) {
				reject({
					metadata: metadata,
					contents: contents,
				})
				return
			}

			console.log(
				'DEBUG',
				metadata,
				contents,
				//@ts-ignore
				new AvialEntity(JSON.stringify(contents.value.value))
			)

			let entityPID
			try {
				//@ts-ignore
				entityPID = JSON.parse(metadata.value).Headings.Entity
			} catch (e) {
				console.log(e)
				reject()
				return
			}
			/*
			this.overseer.store.dispatch(
				loadEntityMetadataSuccessAction(
					new Avial.values.V_Entity(entityPID),
					metadata.value
				)
			)

			this.overseer.store.dispatch(
				loadEntityContentsSuccessAction(
					new Avial.values.V_Entity(entity),
					//@ts-ignore
					new AvialEntity(JSON.stringify(contents.value.value))
				)
			)
*/
			resolve({
				//@ts-ignore
				metadata: JSON.parse(metadata.value),
				//@ts-ignore
				contents: new AvialEntity(JSON.stringify(contents.value.value)),
			})
		})
	}

	/*
	post(path: string, data: any) {
		console.log("==========================");
		let bb = JSON.stringify({
			metadata: data.metadata,
			registry: data.registry,
			content: Comprehensive.EntityFormat_ToString(
				FormatAvial.EntityFormat_ToComprehensive(data.content)
			),
		});

		console.log("==========================");
		return this.fetchEndpoint(path, {
			method: "POST",
			body: bb,
		});
	}

	put(path: string, data: any) {
		return this.fetchEndpoint(path, {
			method: "PUT",
			body: Comprehensive.EntityFormat_ToString(
				FormatAvial.EntityFormat_ToComprehensive(data)
			),
		});
	}

	del(path: string) {
		return this.fetchEndpoint(path, {
			method: "DELETE",
		});
	}

	//----------------------------------------------------------------

	loadPanels() {
		if (!this.overseer.store.getState().user) return;

		const userIdentityString = this.overseer.store.getState().user
			.identity;
		const userIdentity = Entity.fromString(userIdentityString);

		this.getEntity(userIdentity).then((e) => {
			const user = this.overseer.store.getState().entities[userIdentityString];
			const applicationRegistry =
				user.contents.Attributes["APPLICATION_ATTRIBUTE"][0].Values[0].value;

			this.getEntity(applicationRegistry).then((x) => {
				const app = this.overseer.store.getState().entities[
					Entity.toString(applicationRegistry)
				];
				let appWm = app.contents.Properties[0][2].value;
				this.getEntity(appWm).then((y) => {
					//@ts-ignore
					const wm = this.overseer.store.getState().entities[y.result];
					const wmContent =
						wm.contents.Attributes["APPLICATION_ATTRIBUTE"][0].Values[0].value;
					this.overseer.store.dispatch(panelLoadAllAction(JSON.parse(wmContent)));
				});
			});
		});
	}

	savePanel(userIdentity: V_Entity, data: any) {
		return this.fetchEndpoint(
			`panels/${userIdentity.nid}/${userIdentity.hid}/${userIdentity.uid}/`,
			{
				method: "POST",
				body: JSON.stringify(data),
			}
		);
	}

//- FILE ---------------------------------------------------------

	uploadFile(formData: FormData) {
		return this.fetchEndpoint(
			"file/upload",
			{
				method: "POST",
				body: formData,
			},
			true
		);
	}

	downloadFile(file: AvialType.File) {
		return this.fetchEndpoint(`file/${file.nid}/${file.hid}/${file.uid}`, {
			method: "GET",
		});
	}

//- EXECUTOR -----------------------------------------------------

	execute(template: AvialType.Template, outlet: AvialType.Outlet) {
		return this.fetchEndpoint(
			`execute/${template.nid}/${template.hid}/${template.uid}`,
			{
				method: "POST",
				body: JSON.stringify({
					template: template,
					outlet: outlet,
				}),
			}
		);
	}
	*/

	createEntity = async (data: Object, ___?: any): Promise<any> =>
		new Promise(async (resolve, reject) => {
			let res = await this.session.call({
				command: 'INVOKE',
				entity: '<14>',
				method: 'CREATE',
				//@ts-ignore
				name: data.content.Name,
				//@ts-ignore
				key: data.content.Key,
				//@ts-ignore
				context: data.metadata.context,
				//@ts-ignore
				class: data.metadata.class,
				//@ts-ignore
				category: data.metadata.category,
				authorization: this.keychain,
			})

			if (res.isError()) {
				reject()
				return
			}
			resolve(res.value) // return entity value
		})

	storeEntity = async (entity, content): Promise<any> =>
		new Promise(async (resolve, reject) => {
			let res2 = await this.session.call({
				command: 'INVOKE',
				method: 'STORE',
				entity: entity,
				//@ts-ignore
				value: new V_Interchange(JSON.stringify(content)),
				mode: 'UPDATE',
				authorization: this.keychain,
			})
			resolve(res2)
		})

	registerEntity = async (registry, entity, name, key): Promise<any> =>
		new Promise(async (resolve, reject) => {
			let res3 = await this.session.call({
				command: 'INVOKE',
				entity: registry,
				method: 'INCLUDE',
				aspect: 'PROPERTY',
				name: name,
				key: key,
				value: entity,
				authorization: this.keychain,
			})
			resolve(res3)
		})

	purgeEntity = async (entity): Promise<any> =>
		new Promise(async (resolve, reject) => {
			let res3 = await this.session.call({
				command: 'INVOKE',
				entity: entity,
				method: 'PURGE',
				authorization: this.keychain,
			})
			resolve(res3)
		})

	changeEntity = async (entity, data): Promise<any> =>
		new Promise(async (resolve, reject) => {
			let res3 = await this.session.atapi.entities.change({
				entity: entity,
				name: data.name,
				key: data.key,
				class: data.class,
				category: data.category,
				context: data.context,
				authorization: this.keychain,
			})
			resolve(res3)
		})

	uploadFile = async (_?: any, __?: any, ___?: any): Promise<any> =>
		new Promise(() => {
			alert('notImplemented')
		})

	put = async (_?: any, __?: any, ___?: any): Promise<any> =>
		new Promise(() => {
			alert('notImplemented PUT')
		})
	execute = async (_?: any, __?: any, ___?: any): Promise<any> =>
		new Promise(() => {
			alert('notImplemented')
		})
	del = async (_?: any, __?: any, ___?: any): Promise<any> =>
		new Promise(() => {
			alert('notImplemented')
		})
	signup = async (_?: any, __?: any, ___?: any, ____?: any): Promise<any> =>
		new Promise(() => {
			alert('notImplemented')
		})
	forgotPassword = async (
		_?: any,
		__?: any,
		___?: any,
		____?: any
	): Promise<any> =>
		new Promise(() => {
			alert('notImplemented')
		})
	confirmEmail = async (_?: any, __?: any, ___?: any): Promise<any> =>
		new Promise(() => {
			alert('notImplemented')
		})
	resetPassword = async (_?: any, __?: any, ___?: any): Promise<any> =>
		new Promise(() => {
			alert('notImplemented')
		})
}

export default Api
