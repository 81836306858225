import {
	FunctionComponent,
	useState,
	useEffect,
	useContext,
	useRef,
	useMemo,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Avial, { Frame } from '@ledr/ts-client'
import { WindowChild } from '@ledr/instruments'
import MonacoEditor, { Monaco } from '@monaco-editor/react'
import { type editor } from 'monaco-editor'

import ApiContext from '../../context/api'
import InstrumentsLinkerContext from '../../context/instrumentsLinker'
import { addLogAction } from '../../store/log/actions'

import { monarchHGTP, completionHGTP, textToFrame } from './Editor_Monarch_HGTP'

import { AppState } from '../../store/types'
import StaticTemplates from './TerminalTemplates'
import './Terminal.scss'

// HACK TO WORK WITH SYSTEM.JS
//@ts-ignore
const systemJsDefine = window.define
//@ts-ignore
window.define = null
// HACK TO WORK WITH SYSTEM.JS

//https://www.checklyhq.com/blog/customizing-monaco
//https://www.checklyhq.com/blog/customizing-monaco
//https://www.checklyhq.com/blog/customizing-monaco

interface I_monacoCTX {
	editor: editor.IStandaloneCodeEditor
	monaco: Monaco
}

interface TerminalEditorProps {}
const TerminalEditor: FunctionComponent<TerminalEditorProps> = (props) => {
	const api = useContext(ApiContext)
	const dispatch = useDispatch()
	const MyContext = useContext(InstrumentsLinkerContext)

	const [monacoCTX, setMonacoCTX] = useState<I_monacoCTX | undefined>()
	const [HGTP_RESPONSE, setHGTP_RESPONSE] = useState<Frame>()

	const [filesOpen, setFilesOpen] = useState(StaticTemplates)
	const [currentFileId, setCurrentFileId] = useState(0)
	const [content, setContent] = useState(
		StaticTemplates?.[currentFileId]?.content
	)

	const token = useSelector(
		(state: AppState) =>
			state.user.keychain.accesses[state.user.keychain.current].token
	)

	const [value, setValue] = useState('')
	const [entity, setEntity] = useState('<1>')
	const [frame, setFrame] = useState(new Frame())

	function replaceContentLine(content, name, value) {
		let arrayLine = content?.split?.(/\n/gi)
		let id = arrayLine.findIndex((l) => l.indexOf(name) !== -1)
		let newValue = `${name}        ${value}`

		if (id !== -1) arrayLine[id] = newValue
		else arrayLine.push(newValue)

		return arrayLine.join('\n')
	}

	async function EXEC() {
		let response = await api.session.call(frame)
		if (response.isError()) return
		setHGTP_RESPONSE(response)
	}

	useEffect(() => {
		let arrayLine = replaceContentLine(content, 'ENTITY', entity)
		arrayLine = replaceContentLine(arrayLine, 'VALUE', `"${value}"`)
		arrayLine = replaceContentLine(arrayLine, 'AUTHORIZATION', token)

		setContent(arrayLine)
		monacoCTX?.editor.getModel().setValue(arrayLine)
	}, [currentFileId, value, entity, token])

	function onChange(c, evt) {
		if (content.localeCompare(filesOpen[currentFileId]?.content)) {
			let newTemplates = [...filesOpen]
			newTemplates[currentFileId].status = 'edited'
			setFilesOpen(newTemplates)
		}
		setContent(c)
	}

	function load(i: number) {
		setCurrentFileId(i)
		monacoCTX?.editor.getModel().setValue(filesOpen[i].content)
		setContent(filesOpen[i].content)
	}

	useEffect(() => {
		let frame = textToFrame(content)
		setFrame(frame)

		MyContext?.out?.['content']?.({ content, frame })
		//@ts-ignore
		MyContext?.out?.['hgtp']?.(frame.toBinary())
	}, [MyContext?.out?.['content'], MyContext?.out?.['hgtp'], content])

	useEffect(() => {
		let r = {}
		if (HGTP_RESPONSE) {
			try {
				r = HGTP_RESPONSE
				MyContext?.out?.['response']?.(r)
			} catch (e) {
				console.log(e)
			}
			MyContext?.out?.['THEN']?.()
		}
	}, [MyContext?.out?.['response'], MyContext?.out?.['THEN'], HGTP_RESPONSE])

	useEffect(() => {
		MyContext.declareInOut({
			in: [
				{ port: 'content', setter: setContent },
				{ port: 'value', setter: (v) => setValue(JSON.stringify(v)) },
				{ port: 'entity', setter: (v) => setEntity(v.toString()) },
			],
			out: [
				{ port: 'content' },
				{ port: 'hgtp' },
				{ port: 'response' },
				{ port: 'THEN', type: 'cb' },
			],
		})
		return () => {
			MyContext.unDeclareInOut()
		}
	}, [])

	useEffect(() => {
		monacoCTX?.editor?.addAction({
			id: 'Execute',
			label: 'Execute',
			keybindings: [
				monacoCTX?.monaco.KeyMod.CtrlCmd |
					monacoCTX?.monaco.KeyCode.Enter,
			],
			precondition: null,
			keybindingContext: null,
			contextMenuGroupId: 'hgtp',
			contextMenuOrder: 0,
			run: (ed) => {
				EXEC()
			},
		})

		monacoCTX?.editor?.addAction({
			id: 'Save as',
			label: 'Save as',
			//@ts-ignore
			keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyN],
			precondition: null,
			keybindingContext: null,
			contextMenuGroupId: 'navigation',
			contextMenuOrder: 1.5,
			run: (ed) => {
				const result = window.prompt('FileName', '')
				//@ts-ignore
				setFilesOpen([
					...filesOpen,
					{ name: result, status: '', content: content },
				])
			},
		})

		monacoCTX?.editor?.addAction({
			id: 'Save',
			label: 'Save',
			//@ts-ignore
			keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS],
			precondition: null,
			keybindingContext: null,
			contextMenuGroupId: 'navigation',
			contextMenuOrder: 1.5,
			run: (ed) => {
				let newTemplates = [...filesOpen]

				newTemplates[currentFileId] = { ...newTemplates[currentFileId] }
				newTemplates[currentFileId].status = ''
				newTemplates[currentFileId].content = content
				setFilesOpen(newTemplates)

				//setTemplates([...templates, { name: result, content: content }]);
			},
		})
	}, [monacoCTX, content, EXEC, filesOpen, setFilesOpen, currentFileId])

	useEffect(() => {
		return () => {
			monacoCTX?.editor.dispose?.()
		}
	}, [monacoCTX])

	async function handleEditorDidMount(
		editor: editor.IStandaloneCodeEditor,
		monaco: Monaco
	) {
		setMonacoCTX({ editor, monaco })

		if (!monaco.languages.getLanguages().find((l) => l.id === 'hgtp')) {
			monaco.languages.register({ id: 'hgtp' })
			monaco.languages.setMonarchTokensProvider('hgtp', monarchHGTP)
			monaco.editor.defineTheme('hgtp', {
				base: 'vs-dark',
				inherit: true,
				rules: [
					{
						token: 'comment',
						foreground: '#5d7988',
						fontStyle: 'italic',
					},
					{ token: 'constant', foreground: '#0000ff' },

					{ token: 'keyword', foreground: '#FF7F00' },

					{ token: 'type.string', foreground: '#00AA66' },
					{ token: 'type.entity', foreground: '#00FF66' },
					{ token: 'type.auth', foreground: '#66FF00' },
					{ token: 'type.number', foreground: '#66FF66' },

					{ token: 'type.other', foreground: '#505077' },
					{ token: '', foreground: '#FF0000' },
				],
				colors: {
					'editor.background': '#00000000',
				},
			})
			monaco.languages.registerCompletionItemProvider('hgtp', {
				//@ts-ignore
				provideCompletionItems: (model, position) => {
					const wordBeforePosition = model.getWordUntilPosition({
						lineNumber: position.lineNumber,
						column: position.column - 1,
					})

					const wordUntilPosition =
						model.getWordUntilPosition(position)
					if (
						wordBeforePosition.word.trim() === '' ||
						wordUntilPosition.word.trim() === ''
					) {
						const keywords = completionHGTP(monaco)

						const suggestions = keywords.map((id) => ({
							label: id.label,
							kind: id.kind,
							description: id.description,
							documentation: id.description,
							insertText: id.insertText,
							detail: id.description,
							insertTextRules: id.insertTextRules,
							range: {
								startLineNumber: position.lineNumber,
								startColumn: wordUntilPosition.startColumn,
								endLineNumber: position.lineNumber,
								endColumn: wordUntilPosition.endColumn - 1,
							},
							command: { id: 'editor.action.insertLineAfter' },
						}))

						const textUntilPosition = model.getValueInRange({
							startLineNumber: position.lineNumber,
							startColumn: 1,
							endLineNumber: position.lineNumber,
							endColumn: position.column,
						})

						if (textUntilPosition.match(/CLASS/m)) {
							suggestions.push({
								label: 'CLASS xxx',
								kind: 'xxx',
								description: 'xxx',
								documentation: 'xxx',
								insertText: 'xxx',
								detail: 'xxx',
								insertTextRules:
									monaco.languages
										.CompletionItemInsertTextRule
										.InsertAsSnippet,
								range: {
									startLineNumber: position.lineNumber,
									startColumn: wordUntilPosition.startColumn,
									endLineNumber: position.lineNumber,
									endColumn: wordUntilPosition.endColumn - 1,
								},
								command: {
									id: 'editor.action.insertLineAfter',
								},
							})
						}

						return { suggestions }
					}
				},
			})
			/*
monaco.languages.registerHoverProvider('hgtp', {
		provideHover: function (model, position) {

				const word = model.getWordUntilPosition(position);
				return {

						range: new monaco.Range(
						position.lineNumber,
						word.startColumn,
						position.lineNumber,
						word.endColumn
						),
						contents: [
								{
										supportHtml: true,
										value: '<div style="color red; class="test">Custom provider</div>'
								}
						]
				};
		}
});
		 */
		}

		monaco.editor.setTheme('hgtp')
	}

	const tab = useMemo(
		() => (
			<>
				<button>HGTP</button>
				<button onClick={EXEC}> EXEC </button>
			</>
		),
		[EXEC, load, filesOpen]
	)

	const options = {
		selectOnLineNumbers: true,
		fontSize: 13,
		colorDecorators: true,
		lineNumbers: (n: number) => `${n.toString()} |`,
		codeLens: true,
		minimap: {
			enabled: false,
		},
	}

	const win = useMemo(
		() => (
			<div className={'terminal'}>
				<div className="fileList">
					{filesOpen.map((t, i) => (
						<div
							className={`file ${
								currentFileId === i ? 'selected' : ''
							}`}
							onClick={() => load(i)}
						>
							<div className={`state ${t.status}`}></div>
							{t.name}
						</div>
					))}
					<div className={'pad'}></div>
				</div>
				<MonacoEditor
					height="100%"
					theme="vs-dark"
					defaultLanguage="hgtp"
					language="hgtp"
					defaultValue={content}
					options={options}
					//onMount={ () => { window.define = systemJsDefine }}
					onMount={handleEditorDidMount}
					onChange={onChange}
				/>
			</div>
		),

		[
			token,
			handleEditorDidMount,
			EXEC,
			onChange,
			filesOpen,
			load,
			filesOpen,
			currentFileId,
		]
	)
	return <WindowChild tab={tab}>{win}</WindowChild>
}

export default TerminalEditor
