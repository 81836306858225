import {
	ADD_LOG,
	AddLogAction,
	CLEAR_LOG,
	ClearLogAction,
	EDIT_LOG,
	EditLogAction,
} from './types'
import { I_Log } from '@ledr/instruments'

export const editLogAction = (log: I_Log): EditLogAction => ({
	type: EDIT_LOG,
	payload: log,
})

export const addLogAction = (log: I_Log): AddLogAction => ({
	type: ADD_LOG,
	payload: log,
})

export const clearLogAction = (): ClearLogAction => ({
	type: CLEAR_LOG,
	payload: null,
})
