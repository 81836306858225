import { FunctionComponent, useState } from 'react'
import { Link } from 'react-router-dom'
import './Login.scss'
import packageJson from '../../../../package.json'

interface LoginFormProps {
	login: any
}

const LoginForm: FunctionComponent<LoginFormProps> = (props) => {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [remember, setRemember] = useState(false)

	return (
		<div className={'login'}>
			<h1
				style={{
					letterSpacing: '15px',
					textIndent: '15px',
					fontSize: '30px',
					margin: '0px',
				}}
			>
				MAESTRO
			</h1>

			<div
				style={{
					letterSpacing: '3px',
					textIndent: '3px',
					padding: '10px 0px 70px 0px',
					fontSize: '18px',
					opacity: '0.4',
				}}
			>
				{packageJson.version}
			</div>
			<input
				id="email"
				style={{
					padding: '5px',
					height: '35px',
				}}
				name="email"
				type="email"
				placeholder={'Email'}
				value={email}
				onChange={(e) => setEmail(e.target.value)}
			/>
			<input
				style={{
					padding: '5px',
					height: '35px',
					marginTop: '10px',
				}}
				id="password"
				name="password"
				type="password"
				placeholder={'Password'}
				value={password}
				onChange={(e) => setPassword(e.target.value)}
			/>

			<div className={'rememberForgot'}>
				<div className={'remember'}>
					<input
						type="checkbox"
						checked={remember}
						onChange={() => setRemember(!remember)}
						disabled={true}
					/>
					Remember me
				</div>

				<button
					id={'loginButton'}
					className={'loginButton'}
					style={{}}
					onClick={() => {
						props.login(email, password)
					}}
				>
					Login
				</button>
			</div>

			<hr />

			<div className={'forgot'}>
				<Link
					to={{
						pathname: `/forgot`,
						search: email.length ? `?email=${email}` : '',
					}}
					disabled={true}
				>
					Forgot Password ?
				</Link>
			</div>

			<div className={'newAccountButton'}>
				<Link to={'/signup'}>Sign up</Link>
			</div>
		</div>
	)
}

export default LoginForm
