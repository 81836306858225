export let iconClass = {
	NULL_CLASS: 'MdImageNotSupported',
	SERVER_CLASS: 'AiOutlineHdd',
	RESTAURANT_CLASS: 'MdOutlineRestaurantMenu',
	AVESTERRA_CLASS: 'AiOutlineShareAlt',
	HOTEL_CLASS: 'RiHotelLine',
	LIST_CLASS: 'BsListTask',
	ADAPTER_CLASS: 'MdSettingsInputSvideo',
	INSTRUMENT_CLASS: 'AiOutlineBarChart',
	REGISTRY_CLASS: 'MdApps',
	CITY_CLASS: 'MdLocationCity',
	IDENTITY_CLASS: 'BiIdCard',
	FOLDER_CLASS: 'AiFillFolderOpen',
	SPACE_CLASS: 'RiEarthFill',
	PERSON_CLASS: 'BsPersonCircle',
	FAMILY_CLASS: 'MdFamilyRestroom',
	EXAMPLE_CLASS: 'BsFileEarmarkCheck',
	TABLE_CLASS: 'MdTableBar',
	AIRPORT_CLASS: 'MdLocalAirport',
	EMPLOYEE_CLASS: 'MdOutlineHandyman',
	HOUSEKEEPING_CLASS: 'MdDryCleaning',
	ROOM_CLASS: 'MdRoomPreferences',
	LOCATION_CLASS: 'MdLocationPin',
	ACCOUNT_CLASS: 'MdAccountBalanceWallet',
	RECEPTION_CLASS: 'MdAssignmentReturned',
	TEST_CLASS: 'RiTestTubeFill',
	COUNTRY_CLASS: 'MdFlag',
	PUBLICATION_CLASS: 'MdDataObject',
	TIMER_CLASS: 'MdOutlineTimer',
	CATEGORY_CLASS: 'MdOutlineCategory',
	ANALYTIC_CLASS: 'MdAnalytics',
	TEMPLATE_CLASS: 'RiCompassesFill',
	VEHICLE_CLASS: 'RiCarFill',
	FEATURE_CLASS: 'MdOutlineSettingsSuggest',
	UNIVERSITY_CLASS: 'MdSchool',
	CONFERENCE_CENTER_CLASS: 'MdCampaign',
	RELATIONSHIP_CLASS: 'BsArrowThroughHeart',
	BUSINESS_CLASS: 'MdAccountBalance',
	TEAM_CLASS: 'RiGroupFill',
	EVENT_CLASS: 'MdEvent',
	MONEY_CLASS: 'MdMonetizationOn',
	TRANSACTION_CLASS: 'MdMonetizationOn',
	FILTER_CLASS: 'RiFilterFill',
	ACTION_CLASS: 'BsArrowUpRightCircleFill',
	FILE_CLASS: 'BsFileEarmarkBinary',

	// PATCH FOR MISSING CLASSES TAXO
	SAPIEN_CLASS: 'MdGroups', // ASSOCIATION_CLASS
}

export let classColor = {
	// HUE VALUE, 0 red, 200 blue
	FILTER_CLASS: 0,
	TEAM_CLASS: 200,
	ACCOUNT_CLASS: 60,
	ACTION_CLASS: 30,
}

function genRandom(inputString) {
	let hash = 0
	for (let i = 0; i < inputString.length; i++) {
		const charCode = inputString.charCodeAt(i)
		hash = (hash << 3) - hash + charCode
	}
	return (hash & 0x7fffffff) % 360
}

export function iconClassColor(inputString) {
	return classColor[inputString] ?? genRandom(inputString)
}
