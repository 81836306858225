import Avial, {
	V_Entity,
	EntityMetadata,
	HgtpError,
	AvialEntity,
} from '@ledr/ts-client'

import {
	LoadEntityDataSuccessAction,
	LOAD_ENTITY_DATA_SUCCESS,
	LoadEntityMetadataAction,
	LOAD_ENTITY_METADATA,
	LoadEntityMetadataSuccessAction,
	LOAD_ENTITY_METADATA_SUCCESS,
	LoadEntityMetadataErrorAction,
	LOAD_ENTITY_METADATA_ERROR,
	LoadEntityContentsAction,
	LOAD_ENTITY_CONTENTS,
	LoadEntityContentsSuccessAction,
	LOAD_ENTITY_CONTENTS_SUCCESS,
	LoadEntityContentsErrorAction,
	LOAD_ENTITY_CONTENTS_ERROR,
} from './types'
import { EntitiesStoreType } from './reducer'

export const loadEntityDataSuccessAction = (
	entity: V_Entity,
	metadata: EntityMetadata,
	contents: AvialEntity
): LoadEntityDataSuccessAction => ({
	type: LOAD_ENTITY_DATA_SUCCESS,
	entity: entity,
	metadata,
	contents,
})

export const loadEntityMetadataAction = (
	entity: V_Entity
): LoadEntityMetadataAction => ({
	type: LOAD_ENTITY_METADATA,
	entity: entity,
})

export const loadEntityMetadataSuccessAction = (
	entity: V_Entity,
	metadata: EntityMetadata
): LoadEntityMetadataSuccessAction => ({
	type: LOAD_ENTITY_METADATA_SUCCESS,
	entity: entity,
	metadata,
})

export const loadEntityMetadataErrorAction = (
	entity: V_Entity,
	error: HgtpError
): LoadEntityMetadataErrorAction => ({
	type: LOAD_ENTITY_METADATA_ERROR,
	entity: entity,
	error,
})

export const loadEntityContentsAction = (
	entity: V_Entity
): LoadEntityContentsAction => ({
	type: LOAD_ENTITY_CONTENTS,
	entity,
})

export const loadEntityContentsSuccessAction = (
	entity: V_Entity,
	contents: AvialEntity
): LoadEntityContentsSuccessAction => ({
	type: LOAD_ENTITY_CONTENTS_SUCCESS,
	entity,
	contents,
})

export const loadEntityContentsErrorAction = (
	entity: V_Entity,
	error: HgtpError
): LoadEntityContentsErrorAction => ({
	type: LOAD_ENTITY_CONTENTS_ERROR,
	entity,
	error,
})
