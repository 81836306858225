import Avial, { V_Value } from '@ledr/ts-client'
import { ValueInput } from '@ledr/instruments'
import { FunctionComponent, useState, useEffect } from 'react'
import { useCallback } from 'react'
import { Handle, Position } from 'react-flow-renderer'
import './adapterNode.scss'
import windows from '../index'
import { Icon, Print } from '@ledr/instruments'

interface ValueNodeProps {}
const ValueNode: FunctionComponent<ValueNodeProps> = (props) => {
	const [value, setValue] = useState<V_Value>(new Avial.values.V_Null(''))
	function setV(newValue) {
		console.log('setV', newValue, value)
		setValue(newValue)
	}
	useEffect(() => {
		console.log('VALUE CHANGED', value)
	}, [value])

	let input = ['input_A']

	let output = ['output_A']
	let PAD = 30

	return (
		//@ts-ignor
		<div className={`flowNode `}>
			<div className="nodeHeader">VALUE</div>

			<div className="nodeContent nodrag nowheel">
				<ValueInput
					value={value}
					onChange={(v) => {
						console.log('OnChange')
						setV(v)
					}}
				/>

				{/*
					<Print value={props} />
					<Print value={value} />
				*/}
			</div>

			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
				}}
			>
				<div className={'customHandleWrapper'}>
					{input.map((o, i) => (
						<div className={'customHandle left'}>
							<Handle
								className="handle"
								key={i}
								type="target"
								position={Position.Left}
								id={o}
							/>
							{o}
						</div>
					))}
				</div>

				<div className={'customHandleWrapper'}>
					{output.map((o, i) => (
						<div className={'customHandle right'}>
							{o}
							<Handle
								className="handle"
								key={i}
								type="source"
								position={Position.Right}
								id={o}
							>
								<div
									style={{
										position: 'relative',
										width: '100px',
										marginLeft: '-100px',
										textAlign: 'right',
										//backgroundColor:"green"
									}}
								></div>
							</Handle>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default ValueNode
