import Avial, { V_Entity } from '@ledr/ts-client'
import * as THREE from 'three'
import cameraController from './camera/camera'
import mouseController from './mouse/mouse'
import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer.js'
import { CSS3DRenderer } from 'three/examples/jsm/renderers/CSS3DRenderer.js'

import Nodes from './nodes/nodes'
import Force from './force/force'

/*
import Nodes from './node/nodes'
import Links from './node/links'
*/

export interface contextMenuEntry {
	name: string
	onClick: (...args: any) => void
}

/* SHOULD ADD THREE AND (SELF) */
export type Animate = (delta: number) => void

class ThreeInit {
	reactLifeCycle

	domElement
	width
	height

	scene
	simpleCamera
	simpleMouse
	renderer

	needToBeResized
	children: any[]

	rootEntity: V_Entity
	selectedEntity: V_Entity
	selectedEntities: any[]
	nodes: Nodes

	force: Force
	/*
	links: Links
	*/

	contextMenu: contextMenuEntry[]
	step: number
	constructor(domElement) {
		this.domElement = domElement
		this.needToBeResized = false
		this.children = []

		this.rootEntity = new Avial.values.V_Entity('<0|0|1>')

		/*
		this.nodes = new Nodes(this)
		this.links = new Links(this.nodes)
		*/

		this.width = domElement.offsetWidth
		this.height = domElement.offsetHeight

		// = RENDERER ======================
		this.renderer = new THREE.WebGLRenderer({
			antialias: true,
			alpha: true,
			//logarithmicDepthBuffer: true,
		})

		this.renderer.setSize(this.width, this.height)
		domElement.appendChild(this.renderer.domElement)

		let css2DRenderer = new CSS2DRenderer()
		css2DRenderer.setSize(this.width, this.height)
		css2DRenderer.domElement.style.position = 'absolute'
		css2DRenderer.domElement.style.pointerEvents = 'none'
		css2DRenderer.domElement.style.top = '0px'
		domElement.appendChild(css2DRenderer.domElement)

		let css3DRenderer = new CSS3DRenderer()
		css3DRenderer.setSize(this.width, this.height)
		css3DRenderer.domElement.style.position = 'absolute'
		css3DRenderer.domElement.style.pointerEvents = 'none'
		css3DRenderer.domElement.style.top = '0px'
		domElement.appendChild(css3DRenderer.domElement)

		// = SCENE =========================
		this.scene = new THREE.Scene()
		//this.scene.fog = new THREE.Fog(0x000000, 5, 10)
		// = NODES =========================

		this.nodes = new Nodes(this)
		this.scene.add(this.nodes.group)

		// = HELPERS =======================

		const gridHelper = new THREE.GridHelper(100, 1000, 0x242446, 0x212121)
		gridHelper.setRotationFromAxisAngle(
			new THREE.Vector3(1, 0, 0),
			-Math.PI / 2
		)

		const gridHelper2 = new THREE.GridHelper(100, 100, 0x242446, 0x303030)
		gridHelper2.setRotationFromAxisAngle(
			new THREE.Vector3(1, 0, 0),
			-Math.PI / 2
		)

		gridHelper.position.set(0, 0, -0.1)
		gridHelper2.position.set(0, 0, -0.1)

		this.scene.add(gridHelper, gridHelper2)

		// = CAMERA ========================
		this.simpleCamera = new cameraController(this)

		this.simpleMouse = new mouseController(this)

		this.simpleMouse.onSelect = (evt, entity) => {
			console.log('BUTTON', evt.button)
			if (evt.button === 0) {
				// LEFT BUTTON
				console.log(entity)
				this.selectedEntity = entity
				this.triggerReactLifeCycle()
			}
			/*
			if (evt.button === 2) {
				// RIGHT BUTTON
				this.nodes.nodes[entity.toString()].popHoverGear(
					this.contextMenu
				)
			}
			*/
		}

		// = OBJECTS =======================

		const handleWindowResize = () => {
			this.width = this.domElement.offsetWidth
			this.height = this.domElement.offsetHeight

			this.renderer.setSize(this.width, this.height, true)
			this.simpleCamera.updateProjection()
			this.needToBeResized = false
			css2DRenderer.setSize(this.width, this.height)
			css3DRenderer.setSize(this.width, this.height)
		}

		new ResizeObserver((entry) => {
			this.needToBeResized = true
		}).observe(this.domElement)

		var clock = new THREE.Clock()

		const animate = () => {
			const ta = performance.now()
			if (this.needToBeResized) handleWindowResize()

			let delta = clock.getDelta()
			this.step = requestAnimationFrame(animate)

			this.simpleCamera.animate()

			this.children.forEach((e) => {
				if (e.animate) e.animate(delta)
			})

			let root = this.nodes.nodes.find((n) => {
				return n.data.pid === this.rootEntity.toString()
			})

			if (root) {
				root.nextPos = new THREE.Vector3(0, 0, 0)
				root.group.position.set(0, 0, 0)
			}

			this.nodes.animate(delta)

			const tb = performance.now()

			const t0 = performance.now()
			this.renderer.render(this.scene, this.simpleCamera.camera)
			const t1 = performance.now()
			css2DRenderer.render(this.scene, this.simpleCamera.camera)
			const t2 = performance.now()
			css3DRenderer.render(this.scene, this.simpleCamera.camera)
			const t3 = performance.now()
			/*
			console.log(
				'TOTAL',
				t3 - ta,

				'animateChildren',
				tb - ta,

				'scene',
				t1 - t0,
				'css2d',
				t2 - t1,
				'css3d',
				t3 - t2
			)
			*/
		}

		this.force = new Force(this)
		animate()
	}

	setSelectedEntity(entity: V_Entity) {
		this.selectedEntity = entity
		// IF THREE.OPTS.CAMERA_ALWAYS_FOLLOW
		// FIND ENTITY POSITION
		// SET CAMERA NEW TARGET
	}

	triggerReactLifeCycle() {
		this.reactLifeCycle(this)
	}

	dismount() {
		// WILLUNMOUNT
		// remove addevent listner
		// this.mount.removeChild(this.renderer.domElement);
		this.force.destruct()
		cancelAnimationFrame(this.step)
	}

	addChildren(children) {
		this.children.push(children)
		this.scene.add(children.group)
	}

	removeChildren(children) {
		console.log(children)
		// POP CHILDREN BY ID FROM THIS.CHILDREN
		this.scene.remove(children)
	}

	setRoot(entity) {
		this.rootEntity = entity
		this.simpleCamera.newTarget(new THREE.Vector3())
	}

	setCurrent(entity) {
		let node = this.nodes.nodes.find(
			(n) => n.data.pid === entity.toString()
		)
		if (!node) {
			console.log('SELECTED NODE NOT IN GRAPH')
			return
		}

		this.nodes.nodes.forEach((n) => n.styles.desactivate('selected'))
		node.styles.activate('selected')
		let t = new THREE.Vector3(0, 0, 0)
		//@ts-ignore
		node.group.getWorldPosition(t)
		this.simpleCamera.newTarget(t)
	}
}

export { ThreeInit }
