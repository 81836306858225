import {
	USER_CHANGE_KEYCHAIN,
	USER_ADD_KEYCHAIN,
	LOGIN_SUCCESS,
	LOGOUT,
	SettingsActionType,
} from './types'

export interface SettingsStoreType {
	firstName?: string
	lastName?: string
	email?: string
	identity?: string
	jwt?: string
	keychain?: any
	keyboard?: any
}

let initialState: SettingsStoreType = {}

function settingsStoreReducer(
	state = initialState,
	action: SettingsActionType
): SettingsStoreType {
	switch (action.type) {
		case USER_CHANGE_KEYCHAIN:
			return {
				...state,
				keychain: {
					...state.keychain,
					current: action.current,
				},
			}
		case USER_ADD_KEYCHAIN:
			return {
				...state,
				keychain: {
					...state.keychain,
					accesses: [
						...state.keychain.accesses,
						{
							compartment: action.name,
							token: action.token,
						},
					],
				},
			}
		case LOGIN_SUCCESS:
			return {
				...state,
				...action.payload,
			}
		case LOGOUT:
			return initialState
		default:
			return state
	}
}

export default settingsStoreReducer
