import Avial, { V_Value } from '@ledr/ts-client'
import { ValueInput } from '@ledr/instruments'
import { FunctionComponent, useState, useEffect } from 'react'
import { useCallback } from 'react'
import { Handle, Position } from 'react-flow-renderer'
import './adapterNode.scss'
import windows from '../index'
import { Icon, Print } from '@ledr/instruments'

interface OperatorNodeProps {}
const OperatorNode: FunctionComponent<OperatorNodeProps> = (props) => {
	const [value, setValue] = useState<V_Value>(new Avial.values.V_Null(''))

	let input = ['input_A']
	let output = ['output_A']
	let PAD = 30

	function setV(newValue) {
		console.log('setV', newValue, value)
		setValue(newValue)
	}
	return (
		//@ts-ignor
		<div className={`flowNode `}>
			<div className="nodeHeader">OPERATOR</div>

			<div className="nodeContent">
				<hr />
				<ValueInput value={value} onChange={setV} />
				<Print value={props} />
				<Print value={value} />
			</div>

			{input.map((o, i) => (
				<Handle
					key={i}
					type="target"
					style={{
						top: `calc(50% + ${
							i * PAD - ((input.length - 1) * PAD) / 2
						}px)`,
						background: '#F00',
					}}
					position={Position.Left}
					id={o}
				/>
			))}

			{output.map((o, i) => (
				<Handle
					key={i}
					type="source"
					style={{
						top: `calc(50% + ${
							i * PAD - ((output.length - 1) * PAD) / 2
						}px)`,
						background: '#F00',
					}}
					position={Position.Right}
					id={o}
				>
					<div
						style={{
							position: 'relative',
							width: '100px',
							marginLeft: '-100px',
							textAlign: 'right',
							backgroundColor: 'green',
						}}
					>
						Hey
					</div>
				</Handle>
			))}

			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
				}}
			>
				<div className={'customHandleWrapper'}>
					{input.map((o, i) => (
						<div className={'customHandle left'}>
							<Handle
								className="handle"
								key={i}
								type="target"
								position={Position.Left}
								id={o}
							/>
							{o}
						</div>
					))}
				</div>

				<div className={'customHandleWrapper'}>
					{output.map((o, i) => (
						<div className={'customHandle right'}>
							{o}
							<Handle
								className="handle"
								key={i}
								type="source"
								position={Position.Right}
								id={o}
							>
								<div
									style={{
										position: 'relative',
										width: '100px',
										marginLeft: '-100px',
										textAlign: 'right',
										//backgroundColor:"green"
									}}
								></div>
							</Handle>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default OperatorNode
