import React, { FC, useState, useMemo, useEffect, ReactNode } from 'react'
import InstrumentsLinkerOverseerContext from '../../context/instrumentsLinkerOverseer'
import useKeyboard from '../../hook/keyboard'
import './instrumentLinkerOverseer.scss'
import InstrumentLinkerSvgPath from './InstrumentLinkerSvgPath'
import ToolBelt from './ToolBelt'

// CLOSURE PATCH
function closurePorts(setAllPorts, allPorts) {
	let value = allPorts
	function increment(n) {
		value = value.filter((p) => p.winId !== n.winId)
		value.push(n)
		setAllPorts(value)
	}

	function unSet(winId) {
		value = value.filter((p) => p.winId !== winId)
		setAllPorts(value)
	}

	return [increment, unSet]
}

function closureLinks(setAllPorts, allPorts) {
	let value = allPorts
	function increment(n) {
		if (
			value.find(
				(v) =>
					v.winId === n.winId &&
					v.port === n.port &&
					v.type === n.type
			)
		) {
			return
		}
		value.push(n)
		setAllPorts(value)
	}

	function unSet(n) {
		value = []
		setAllPorts([])
	}

	return [increment, unSet]
}

function closureCache(setCache, cache) {
	let value = cache
	function increment(key, data) {
		value[key] = data
		setCache(value)
	}

	function unSet(n) {
		value = []
		setCache([])
	}

	return [increment, unSet]
}
interface instrumentLinkerOverseerProps {
	links: any[]
	onChange?: (links) => void
	children: ReactNode
}

const instrumentLinkerOverseer: FC<instrumentLinkerOverseerProps> = (props) => {
	const [cached, setCached] = useState({})
	const [setCache, unsetCache] = closureCache(setCached, cached)

	const [allPorts, setAllPorts] = useState([])
	const [setPorts, unsetPorts] = closurePorts(setAllPorts, allPorts)

	const [allRef, setAllRef] = useState([])
	const [setRef, removeRef] = closureLinks(setAllRef, allRef)

	const [instrumentLinker, setInstrumentsLinker] = useState(false)
	const [allLinks, setAllLinks] = useState(props.links)

	useEffect(() => {
		setAllLinks(props.links)
	}, [props.links])
	useKeyboard([{ action: 'showInstrumentsLinker', cb: setInstrumentsLinker }])

	const deleteLink = (id: number) => {
		setAllLinks([...allLinks?.slice(0, id), ...allLinks?.slice(id + 1)])
	}

	const links = useMemo(() => {
		props.onChange?.(allLinks)
		const newLinks = allLinks?.map((link) => {
			const setters = allPorts
				?.find((ap) => ap?.winId === link?.inNode?.winId)
				?.portIn?.map((port) => port.setter)
			return { ...link, setters }
		})

		let win = {}

		newLinks?.forEach((l) => {
			// EXTERNAL INSTRUMENT READ AS IN (what is registered as OUT)
			// EXTERNAL INSTRUMENT READ AS IN (what is registered as OUT)

			if (!win[l.inNode.winId]) win[l.inNode.winId] = {}

			if (!win[l.inNode.winId][l.outNode.port])
				win[l.inNode.winId][l.outNode.port] = []

			l.setters?.forEach((setter) => {
				win[l.inNode.winId][l.outNode.port].push(setter)
			})
		})

		let out = {}
		for (const winId in win) {
			if (!out[winId]) out[winId] = {}

			for (const port in win[winId]) {
				out[winId][port] = (data) => {
					for (const p in win[winId]) {
						win?.[winId]?.[p]?.forEach((s) => {
							console.log(s?.toString())
							s?.(data)
						})
					}
				}
			}
		}

		return out
	}, [allLinks, allPorts])

	return (
		<InstrumentsLinkerOverseerContext.Provider
			value={{
				show: instrumentLinker,
				setNewLink: (link) => setAllLinks([...allLinks, link]),
				setNewPorts: setPorts,
				unsetPorts: unsetPorts,
				setCached: (winId, key, data) => {
					setCache(winId + key, data)
					console.log('TOCACHE', winId, cached, key, data)
				},
				cached: cached,
				declareRef: setRef,
				removeRef: () => setAllRef([]),
				allLinks: allLinks,
				allPorts: allPorts,
				links: links,
			}}
		>
			<>
				{/*
				<ToolBelt />
				*/}

				<div
					className={instrumentLinker ? 'linkerOn' : ''}
					style={{
						height: '100%',
						width: '100%',
					}}
				>
					{props.children}
				</div>

				{instrumentLinker && (
					<InstrumentLinkerSvgPath
						allLinks={allLinks}
						allRef={allRef}
						deleteLink={deleteLink}
					/>
				)}

				{/*
				<ToolBelt />
				*/}
			</>
		</InstrumentsLinkerOverseerContext.Provider>
	)
}

export default instrumentLinkerOverseer
