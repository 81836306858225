import {
	FunctionComponent,
	useState,
	useEffect,
	useMemo,
	useContext,
} from 'react'
import { WindowChild, Print, avesterraDrawer } from '@ledr/instruments'
import Avial, { V_Value, V_Locutor } from '@ledr/ts-client'
import { ValueInput } from '@ledr/instruments'

import InstrumentsLinkerContext from '../context/instrumentsLinker'

//CRUCIAL
//CRUCIAL
function locutorToValue(locutor: V_Locutor) {
	// RECURSION DANS LA RESOLUTION DU LOCUTOR

	return locutor?.value?.VALUE ?? new Avial.values.V_Null('')
}
//CRUCIAL
//CRUCIAL

const ValueBuilder: FunctionComponent = () => {
	const MyContext = useContext(InstrumentsLinkerContext)
	const [debug, setDebug] = useState(false)
	const [value, setValue] = useState<V_Value>(new Avial.values.V_Null(''))
	const [locutor, setLocutor] = useState<V_Locutor>(
		new Avial.values.V_Locutor('{}')
	)

	useEffect(() => {
		let newV = locutorToValue(locutor)
		console.log(newV)
		setValue(newV)
	}, [locutor])

	useEffect(() => {
		MyContext?.out?.['value']?.(value)
	}, [MyContext?.out?.['value'], value])

	useEffect(() => {
		MyContext.declareInOut({
			in: [
				{ port: 'value', setter: setValue },
				{ port: 'locutor', setter: setLocutor },
			],
			out: [{ port: 'value' }],
		})
		return () => {
			MyContext.unDeclareInOut()
		}
	}, [setValue])

	const tab = useMemo(
		() => (
			<>
				<button
					className={debug ? 'active' : ''}
					onClick={() => setDebug(!debug)}
				>
					debug
				</button>
			</>
		),
		[debug, setDebug]
	)
	const win = useMemo(
		() => (
			<>
				<div
					style={{
						width: '100%',
						justifyContent: 'stretch',
						display: 'grid',
						gridTemplateColumns: 'auto auto auto auto',
					}}
				></div>
				<ValueInput value={value} onChange={setValue} />

				{debug && <Print value={value} maxDeepness={100} />}
			</>
		),
		[value, setValue, debug]
	)

	return <WindowChild tab={tab}>{win}</WindowChild>
}

export default ValueBuilder
