import { FunctionComponent } from 'react'
import Avial, { Features, Feature } from '@ledr/ts-client'
import { ValueInput, Icon } from '@ledr/instruments'

interface FeaturesProps {
	features: Features
	onChange: (newFeatures: Features) => void
}

const FeaturesComponent: FunctionComponent<FeaturesProps> = (props) => {
	const changeFeature = (oldFeature: Feature, newFeature: Feature) => {
		props.onChange(
			//@ts-ignore
			props.features.map((f) => (f === oldFeature ? newFeature : f))
		)
	}

	const addFeature = () => {
		props.onChange(
			//@ts-ignore
			[
				...props.features,
				new Avial.model.Feature('', '', new Avial.values.V_Null('')),
			]
		)
	}
	const deleteFeature = (featureToDelete) => {
		//@ts-ignore
		props.onChange(props.features.filter((f) => f !== featureToDelete))
	}

	return (
		<>
			{props.features.map((feature, i) => (
				<div
					style={{
						display: 'grid',
						gridTemplateColumns: 'repeat(3,1fr) min-content',
					}}
				>
					<div className={'cell'}>
						<input
							style={{ height: '22px', width: '100%' }}
							type={'text'}
							value={feature.Name}
							onChange={(evt) =>
								changeFeature(
									feature,
									new Avial.model.Feature(
										evt.target.value,
										feature.Key,
										feature.Value
									)
								)
							}
							placeholder={'Name'}
						/>
					</div>
					<div className={'cell'}>
						<input
							style={{ height: '22px', width: '100%' }}
							type={'text'}
							value={feature.Key}
							onChange={(evt) =>
								changeFeature(
									feature,
									new Avial.model.Feature(
										feature.Name,
										evt.target.value,
										feature.Value
									)
								)
							}
							placeholder={'Key'}
						/>
					</div>
					<div className={'cell'}>
						<ValueInput
							value={feature.Value}
							onChange={(newValue) =>
								changeFeature(
									feature,
									new Avial.model.Feature(
										feature.Name,
										feature.Key,
										newValue
									)
								)
							}
						/>
					</div>
					<button
						style={{
							background: 'rgba(255,0,0,0.2)',
							color: 'rgba(255,0,0,1)',
							padding: '1px',
							margin: '1px',
							height: '100%',
						}}
						onClick={() => deleteFeature(feature)}
					>
						<Icon name={'MdOutlineCancel'} size={20} />
					</button>
				</div>
			))}

			<button
				style={{
					background: 'rgba(0,255,0,0.2)',
					color: 'rgba(0,255,0,1)',
					padding: '1px',
					width: '100%',
				}}
				onClick={() => addFeature()}
			>
				<Icon name={'MdAddCircleOutline'} size={20} />
			</button>
		</>
	)
}

export default FeaturesComponent
