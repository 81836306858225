import {
	FunctionComponent,
	useState,
	useEffect,
	useContext,
	useMemo,
} from 'react'
import Avial from '@ledr/ts-client'
import { Print } from '@ledr/instruments'
import ApiContext from '../context/api'

import { WindowChild } from '@ledr/instruments'
import './Mount.scss'

interface MountProps {}

const Mount: FunctionComponent<MountProps> = (props) => {
	const [list, setList] = useState(null)

	const api = useContext(ApiContext)
	useEffect(() => {
		let res = api.session
			.call({
				command: 'INVOKE',
				entity: '<4200>',
				method: 'EXECUTE',
				name: 'LIST',
				authorization: api.keychain,
			})
			.then((res) => {
				if (res.isError()) return
				setList(res.value)
			})
	}, [])

	const tab = useMemo(() => <>MOUNT TAB</>, [])
	const win = useMemo(
		() => (
			<div
				className="Mount"
				style={{
					display: 'grid',
					gridTemplateColumns: 'auto auto auto auto auto',
				}}
			>
				<div className="head">Name</div>
				<div className="head">Version</div>
				<div className="head">Endpoint</div>
				<div className="head">Package ID</div>
				<div className="head">Action</div>
				{list?.map((l) => (
					<>
						<div>{l.name}</div>
						<div>{l.version}</div>
						<div>{l.endpoint}</div>
						<div>{l.package_id}</div>
						<div>
							<button>Start/Stop</button>
						</div>
					</>
				))}
			</div>
		),
		[list]
	)
	return <WindowChild tab={tab}>{win}</WindowChild>
}

export default Mount
