import { FunctionComponent } from 'react'

import keyboardContext from '../../context/keyboard'
import Browser from './Browser'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import ApiContext from '../../context/api'

import { useKeyboardMain } from '../../hook/keyboard'

interface MainAppProps {
	basename: string
	overseer: any
	api: any
}

const MainApp: FunctionComponent<MainAppProps> = (props) => {
	return (
		<ApiContext.Provider value={props.api}>
			<Provider store={props.overseer.store}>
				<keyboardContext.Provider value={useKeyboardMain()}>
					<BrowserRouter basename={`/${props.basename}`}>
						<Browser />
					</BrowserRouter>
				</keyboardContext.Provider>
			</Provider>
		</ApiContext.Provider>
	)
}

export default MainApp
