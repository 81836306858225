import {
	FunctionComponent,
	useState,
	useEffect,
	useContext,
	useMemo,
} from 'react'
//@ts-ignore
import Avial, { AvialEntity } from '@ledr/ts-client'
import {
	SearchSelect,
	EntityInput,
	WindowChild,
	wmContext,
} from '@ledr/instruments'
import InstrumentsLinkerContext from '../context/instrumentsLinker'
import ApiContext from '../context/api'
import useEntity from '../hook/entity'

import { Icon } from '@ledr/instruments'
import Properties from '../components/avialModel/Properties'
import Facts from '../components/avialModel/Facts'
interface CreateEntityProps {}

const CreateEntity: FunctionComponent<CreateEntityProps> = (props) => {
	const wm = useContext(wmContext)

	const MyContext = useContext(InstrumentsLinkerContext)
	const api = useContext(ApiContext)

	const [registry, setRegistry] = useState(new Avial.values.V_Entity('<25>'))
	const [entity, setEntity] = useState(
		//@ts-ignore
		new Avial.values.V_Entity(wm.win.data?.entity ?? '<0>')
	)

	const [opts, setOpts] = useState({
		//@ts-ignore
		newEntity: wm.win.data?.entity ? false : true,
	})

	const entityStore = useEntity(entity)

	let [error, setError] = useState(null)
	let [context, setContext] = useState('')
	let [name, setName] = useState('')
	let [key, setKey] = useState('')
	let [klass, setKlass] = useState('')
	let [category, setCategory] = useState('')

	let [value, setValue] = useState(
		entityStore.contents || new AvialEntity('{}')
	)
	useEffect(() => {
		setValue(entityStore.contents || new AvialEntity('{}'))
		setName(entityStore.contents?.Name ?? '')
		setKey(entityStore.contents?.Key ?? '')

		setCategory(
			Avial.Taxonomy.Category.byId[
				//@ts-ignore
				entityStore?.metadata?.Headings?.Category ?? 0
			].toString()
		)
		setKlass(
			Avial.Taxonomy.Class.byId[
				//@ts-ignore
				entityStore?.metadata?.Headings?.Class ?? 0
			].toString()
		)
		setContext(
			Avial.Taxonomy.Context.byId[
				//@ts-ignore
				entityStore?.metadata?.Headings?.Context ?? 0
			].toString()
		)
	}, [entityStore.contents])

	const changeValue = (value) => {
		try {
			let v = new AvialEntity(value)
			setValue(v)
			setError(null)
		} catch (e) {
			setValue(value)
			setError(e)
		}
	}

	const onSubmit = async () => {
		value.Name = name
		value.Key = key

		if (!error) {
			if (opts.newEntity) {
				let response = await api.createEntity({
					registry: registry,
					metadata: {
						context: context,
						class: klass,
						category: category,
						name: name,
						key: key,
					},
					content: value,
				})
				let isStored = await api.storeEntity(response, value)
				let isRegistered = await api.registerEntity(
					registry,
					response,
					name,
					key
				)

				// CACHE REFRESH
				await api.get(response)
				await api.get(registry)
			} else {
				await api.purgeEntity(entity)
				await api.storeEntity(entity, value)
				await api.changeEntity(entity, {
					name: name,
					key: key,
					class: klass,
					category: category,
					context: context,
				})
				// CACHE REFRESH
				await api.get(entity)
			}
			MyContext?.out?.['THEN']?.()
		}
	}

	///////////////////////////////////////////////////
	useEffect(() => {
		MyContext.declareInOut({
			in: [
				{ port: 'entity', setter: setEntity },
				{ port: 'registry', setter: setRegistry },
			],
			out: [{ port: 'THEN', type: 'cb' }],
		})
		return () => {
			MyContext.unDeclareInOut()
		}
	}, [])
	///////////////////////////////////////////////////

	const tab = useMemo(
		() => (
			<>
				<div style={{ display: 'flex' }}>
					<button
						style={{ marginRight: '10px', float: 'right' }}
						onClick={() => {
							setOpts({ ...opts, ['newEntity']: true })
						}}
						className={!opts.newEntity ? '' : 'active'}
					>
						CREATE
					</button>

					<button
						style={{ marginRight: '10px', float: 'right' }}
						onClick={() => {
							setOpts({ ...opts, ['newEntity']: !opts.newEntity })
						}}
						className={opts.newEntity ? '' : 'active'}
					>
						EDIT
					</button>

					<div style={{ display: 'flex' }}>
						{opts.newEntity ? (
							<>
								<div
									style={{
										marginTop: '3px',
										marginRight: '4px',
									}}
								>
									INTO
								</div>
								<EntityInput
									value={registry}
									onChange={setRegistry}
								/>
								<button style={{ padding: '3px' }}>
									<Icon name={'BiTargetLock'} size={16} />
								</button>
								{/*
								LOCUTOR TO STORE APPEND INTO [Property,FACT,Value,Facet,feature]
									*/}
							</>
						) : (
							<>
								<div
									style={{
										marginTop: '3px',
										marginRight: '4px',
									}}
								>
									FROM
								</div>
								<EntityInput
									value={entity}
									onChange={setEntity}
								/>
							</>
						)}
					</div>
				</div>
				<button
					style={{ marginRight: '10px', float: 'right' }}
					onClick={onSubmit}
					disabled={error ? true : false}
				>
					EXECUTE
				</button>
			</>
		),
		[registry, onSubmit]
	)

	const win = useMemo(
		() => (
			<div className="AvialEntity">
				{/*
				<hr/>
					TODO
				<br/>
				LOAD cat/class/context
				<br/>
				DISPLAY LABEL FOR cat/class/context
				<hr/>
					 */}

				<div
					style={{ display: 'flex', gap: '5px', padding: '3px 3px' }}
				>
					<input
						type="text"
						style={{
							width: '100%',
							height: '22px',
						}}
						value={name}
						onChange={(e) => setName(e.target.value)}
						placeholder="Name"
					/>

					<input
						type="text"
						style={{
							width: '100%',
							height: '22px',
						}}
						value={key}
						onChange={(e) => setKey(e.target.value)}
						placeholder="Key"
					/>
				</div>
				<div
					style={{ display: 'flex', gap: '5px', padding: '0px 5px' }}
				>
					<SearchSelect
						value={category}
						list={Avial.Taxonomy.Category.byName}
						onChange={setCategory}
						placeholder={'category'}
					/>

					<SearchSelect
						value={klass}
						list={Avial.Taxonomy.Class.byName}
						onChange={setKlass}
						placeholder={'class'}
					/>

					<SearchSelect
						value={context}
						list={Avial.Taxonomy.Context.byName} //Avial.Taxonomy.ContextStringEnum}
						onChange={setContext}
						placeholder={'context'}
					/>
				</div>

				<Facts
					value={value?.Facts}
					onChange={(facts) => {
						console.log(value, facts)
						let newValue = new AvialEntity(JSON.stringify(value))
						newValue.Facts = facts
						setValue(newValue)
					}}
				/>

				<Properties
					value={value?.Properties}
					onChange={(properties) => {
						let newValue = new AvialEntity(JSON.stringify(value))
						newValue.Properties = properties
						setValue(newValue)
					}}
				/>
				{
					<textarea
						value={JSON.stringify(value)}
						onChange={(e) => {
							changeValue(e.target.value)
						}}
						style={{
							color: error ? 'red' : '',
							height: '300px',
							width: 'calc(100% - 20px)',
							padding: '10px',
							margin: '10px',
							pointerEvents: 'auto',
						}}
					/>
				}
				{error && (
					<div
						style={{
							margin: '10px',
							color: 'red',
						}}
					>
						char: {error.at} error: {error.message}
					</div>
				)}
			</div>
		),
		[registry, name, key, klass, category, context, value, MyContext, opts]
	)

	return <WindowChild tab={tab}>{win}</WindowChild>
}

export default CreateEntity
