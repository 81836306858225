import { I_LogTypes, I_LogChannels, I_Log } from '@ledr/instruments'
import { ADD_LOG, CLEAR_LOG, EDIT_LOG, LogActions } from './types'

interface LogStoreType {
	types: I_LogTypes[]
	channels: I_LogChannels[]
	logs: I_Log[]
}

let initialState: LogStoreType = {
	types: [],
	channels: [],
	logs: [],
}

function addLog(state: LogStoreType, payload: I_Log): LogStoreType {
	const typeId = state.types.findIndex((type) => type.name === payload.type)
	const channelId = state.channels.findIndex(
		(channel) => channel.name === payload.channel
	)
	let newTypes = [...state.types]
	let newChannels = [...state.channels]

	if (typeId === -1)
		newTypes.push({ name: payload.type, nb: 1, display: true })
	else
		newTypes[typeId] = {
			...newTypes[typeId],
			nb: newTypes[typeId].nb + 1,
		}

	if (channelId === -1)
		newChannels.push({ name: payload.channel, nb: 1, display: true })
	else
		newChannels[channelId] = {
			...newChannels[channelId],
			nb: newChannels[channelId].nb + 1,
		}

	let newState = {
		types: newTypes,
		channels: newChannels,
		logs: [...state.logs, payload],
	}
	return newState
}

function editLog(state: LogStoreType, payload: I_Log): LogStoreType {
	let idToEdit = state.logs.findIndex((l) => l.msgId === payload.msgId)
	if (idToEdit === -1) return { ...state }
	// FIND LOG BY msg.Id

	let newTypes = [...state.types]
	let newChannels = [...state.channels]

	const channelId = state.channels.findIndex(
		(channel) => channel.name === payload.channel
	)

	// if type has changed
	if (state.logs[idToEdit].type !== payload.type) {
		let idOldType = newTypes.findIndex(
			(t) => t.name === state.logs[idToEdit].type
		)

		if (newTypes[idOldType].nb === 1) {
			newTypes = [
				...newTypes.slice(0, idOldType),
				...newTypes.slice(idOldType + 1),
			]
		} else
			newTypes[idOldType] = {
				...newTypes[idOldType],
				nb: newTypes[idOldType].nb - 1,
			}

		const typeId = newTypes.findIndex((type) => type.name === payload.type)
		if (typeId === -1)
			newTypes.push({ name: payload.type, nb: 1, display: true })
		else {
			newTypes[typeId] = {
				...newTypes[typeId],
				nb: newTypes[typeId].nb + 1,
			}
		}
	}

	let newState = {
		types: newTypes,
		channels: newChannels,
		logs: [
			...state.logs.slice(0, idToEdit),
			{ ...payload },
			...state.logs.slice(idToEdit + 1),
		],
	}
	return newState
}

function logStoreReducer(
	state = initialState,
	action: LogActions
): LogStoreType {
	switch (action.type) {
		case ADD_LOG:
			return addLog(state, action.payload)
		case EDIT_LOG:
			return editLog(state, action.payload)
		case CLEAR_LOG:
			return initialState
		default:
			return state
	}
}

export default logStoreReducer
