import React, { FunctionComponent, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../store/types'
import { Print } from '@ledr/instruments'
import { RequestLog } from '@ledr/ts-client'

function generateRandomASCII() {
	const min = 32 // Space character
	const max = 126 // Tilde (~) character
	let str = ''
	for (let i = 0; i < 10 + Math.floor(Math.random() * 20); i++) {
		const randomASCII = Math.floor(Math.random() * (max - min + 1)) + min
		str += String.fromCharCode(randomASCII)
	}
	return str
}

function frameToText(frame) {
	const CUT = 100
	const line = []

	for (const key in frame) {
		//@ts-ignore
		if (frame[key] && frame[key]?.toInteger?.() !== 0 && frame[key] != 0) {
			//@ts-ignore
			let valueStr = frame[key]?.toString() ?? ''
			if (
				(key === 'entity' ||
					key === 'outlet' ||
					key === 'auxiliary' ||
					key === 'ancillary') &&
				valueStr === '<0|0|0>'
			) {
				continue
			}

			if (key === 'value') valueStr = generateRandomASCII()

			if (
				(key === 'authorization' || key === 'authority') &&
				//@ts-ignore
				frame[key]?.value === 0n
			) {
				continue
			}

			line.push(
				<div style={{ display: 'flex' }}>
					<div
						style={{
							opacity: 0.5,
							width: '100px',
						}}
					>
						{key.toUpperCase()}
					</div>
					<div
						style={
							key === 'value'
								? {
										filter: 'blur(2px)',
								  }
								: {}
						}
					>
						{valueStr.substring(0, 150)}
						{valueStr.length > 100
							? `... (${valueStr.length - 100} more chars)`
							: ''}
					</div>
				</div>
			)
		}
	}

	return line.length ? line : <br />
}

interface PureLogProps {}

const PureLog: FunctionComponent<PureLogProps> = (props) => {
	let size = 15
	const logStore = useSelector((state: AppState) => state.logs).logs.slice(
		-size
	)

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				position: 'absolute',
				bottom: '0px',
				left: '0px',
				marginTop: '5px',
			}}
		>
			{logStore.map((log, i) => {
				return (
					<div
						style={{
							opacity: `${(i + 3) / size} `,
							fontSize: '11px',
							margin: '5px',
						}}
					>
						{log?.msg?.constructor === RequestLog ? (
							<>
								{
									//@ts-ignore
									frameToText(log.msg?.req)
								}
								<br />
								{
									<div
										style={
											//@ts-ignore
											log.msg?.res?.isError()
												? { color: 'red' }
												: { color: 'white' }
										}
									>
										{
											//@ts-ignore
											frameToText(log.msg?.res)
										}
									</div>
								}
							</>
						) : (
							<>
								{
									//@ts-ignore
									log.msg
								}
							</>
						)}
					</div>
				)
			})}
		</div>
	)
}

export default PureLog
