import * as THREE from 'three'
import { ThreeInit } from '../init'
import Avial from '@ledr/ts-client'
import { MouseSelect } from './mouseSelect'

window.addEventListener('contextmenu', (e) => e.preventDefault())

function getMouseNormal(event, div) {
	let offset = div.getBoundingClientRect()
	const mouseX = event.clientX - offset.left
	const mouseY = event.clientY - offset.top

	return new THREE.Vector2(
		(mouseX / div.width) * 2 - 1,
		-(mouseY / div.height) * 2 + 1
	)
}

class mouseController {
	three: ThreeInit
	renderer
	cameraController
	scene

	mouseSelect: MouseSelect
	pressed: boolean
	movementX: number
	movementY: number
	mouseStart: THREE.Vector2

	hovered
	captured

	onSelect: (evt, entity) => void

	constructor(three) {
		this.three = three
		this.renderer = three.renderer
		this.cameraController = three.simpleCamera
		this.scene = three.scene

		this.pressed = false
		this.movementX = 0
		this.movementY = 0

		this.hovered = null

		window.addEventListener('mousemove', this.onMouseMove.bind(this), false)

		window.addEventListener('mouseup', this.onMouseUp.bind(this), false)

		three.domElement.addEventListener(
			'mousedown',
			this.onMouseDown.bind(this),
			false
		)

		three.domElement.addEventListener(
			'mouseout',
			() => {
				if (this.hovered?.object.userData?.hoverOut)
					this.hovered?.object.userData?.hoverOut()
				this.hovered = null
			},
			false
		)
		three.domElement.addEventListener(
			'wheel',
			(e) => {
				//this.cameraController.target = null
				this.cameraController.onScroll(e.deltaY, e.deltaX)
			},
			false
		)
	}

	onMouseDown(evt) {
		this.movementX = 0
		this.movementY = 0

		this.pressed = true

		this.mouseStart = getMouseNormal(evt, this.renderer.domElement)

		if (this.hovered) this.captured = this.hovered

		if (evt.shiftKey) {
			if (!this.mouseSelect) {
				this.mouseSelect = new MouseSelect(
					this.three,
					this.cameraController.camera
				)
				this.scene.add(this.mouseSelect)
			}
		}
	}

	onMouseUp(evt) {
		this.captured = undefined

		this.pressed = false
		if (
			(this.movementX > 5 || this.movementX < -5) &&
			(this.movementY > 5 || this.movementY < -5)
		) {
			// JUST DRAGGED
			return
		} else {
			this.onMouseClick(evt)
			// CLICKED
		}
		this.movementX = 0
		this.movementY = 0

		if (!evt.shiftKey) {
			if (evt.target !== this.renderer.domElement) return
			this.mouseSelect?.dispose?.()
			this.mouseSelect = null
		}
	}

	onMouseClick(evt) {
		if (this.hovered) {
			// CHANGE SELECTED ENTITY
			// MUST BE OUTSIDE MOUSE CONTROLLER
			let entity = this.hovered.object.userData.entity
			if (entity) {
				// UPDATE CAMERA
				if (evt.button === 0) {
					let newTarget = new THREE.Vector3()
					this.cameraController.newTarget(
						this.hovered.object.getWorldPosition(newTarget)
					)
				}

				this.onSelect(evt, new Avial.values.V_Entity(entity))
			}
		}
	}

	mouseDragging(x, y) {
		this.movementX += x
		this.movementY += y
		if (!this.captured) this.cameraController.drag(x, y)
		else {
			this.captured.object.userData.drag?.(x, y)
		}
	}

	onMouseMove(event) {
		let mouse = getMouseNormal(event, this.renderer.domElement)

		if (this.pressed) {
			if (event.shiftKey) this.mouseSelect?.update(this.mouseStart, mouse)
			else this.mouseDragging(event.movementX, event.movementY)
		}

		if (event.target !== this.renderer.domElement) return

		const raycaster = new THREE.Raycaster()
		raycaster.setFromCamera(mouse, this.cameraController.camera)

		const intersected = raycaster
			.intersectObjects(this.scene.children, true)
			.find((e) => e?.object?.userData?.hoverIn)

		if (!intersected) {
			if (this.hovered?.object.userData?.hoverOut)
				this.hovered?.object.userData?.hoverOut()
			this.hovered = null
			return
		}
		if (this.hovered?.object !== intersected.object) {
			if (this.hovered?.object.userData?.hoverOut)
				this.hovered?.object.userData?.hoverOut()

			if (intersected.object.userData.hoverIn) {
				intersected.object.userData.hoverIn()
				this.hovered = intersected
			}
		}
	}
}

export default mouseController
