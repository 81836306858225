import React, { FunctionComponent, useState } from 'react'
import Avial, { Fact, Facts } from '@ledr/ts-client'
import { Icon } from '@ledr/instruments'
import {
	ValueInput,
	InputsConstructors,
	SearchSelect,
	FoldGrey,
	FoldWrap,
} from '@ledr/instruments'
import FacetsComponent from './Facets'
import Features from './Features'
import Table from './Table'

interface FoldGreyEditProps {
	name: string
	children: any
}

const FoldGreyEdit: FunctionComponent<FoldGreyEditProps> = (props) => {
	return (
		<FoldGrey
			title={
				<div
					style={{
						display: 'flex',
						width: '100%',
						justifyContent: 'space-between',
					}}
				>
					<div style={{ marginTop: '5px' }}>{props.name}</div>
				</div>
			}
		>
			{props.children}
		</FoldGrey>
	)
}

interface FactProps {
	fact: Fact
	onChange: (p: Fact) => void
	onDelete: () => void
}

const FactComponent: FunctionComponent<FactProps> = (props) => {
	const deleteValue = () => {
		const newFact = new Avial.model.Fact(
			props.fact.Attribute,
			new Avial.values.V_Null(''),
			props.fact.Facets,
			props.fact.Features
		)
		newFact.Table = props.fact.Table
		props.onChange(newFact)
	}

	const changeValue = (newValue) => {
		const newFact = new Avial.model.Fact(
			props.fact.Attribute,
			newValue,
			props.fact.Facets,
			props.fact.Features
		)
		newFact.Table = props.fact.Table
		props.onChange(newFact)
	}

	const changeFeatures = (newValue) => {
		const newFact = new Avial.model.Fact(
			props.fact.Attribute,
			props.fact.Value,
			props.fact.Facets,
			newValue
		)
		newFact.Table = props.fact.Table
		props.onChange(newFact)
	}

	const deleteFeatures = () => {
		const newFact = new Avial.model.Fact(
			props.fact.Attribute,
			props.fact.Value,
			props.fact.Facets,
			[]
		)
		newFact.Table = props.fact.Table
		props.onChange(newFact)
	}
	const changeFacets = (newValue) => {
		const newFact = new Avial.model.Fact(
			props.fact.Attribute,
			props.fact.Value,
			newValue,
			props.fact.Features
		)
		newFact.Table = props.fact.Table
		props.onChange(newFact)
	}

	const deleteFacets = () => {
		const newFact = new Avial.model.Fact(
			props.fact.Attribute,
			props.fact.Value,
			[],
			props.fact.Features
		)
		newFact.Table = props.fact.Table
		props.onChange(newFact)
	}

	const changeAttribute = (newAttribute) => {
		const newFact = new Avial.model.Fact(
			newAttribute + '_ATTRIBUTE',
			props.fact.Value,
			props.fact.Facets,
			props.fact.Features
		)
		newFact.Table = props.fact.Table
		props.onChange(newFact)
	}

	const changeTable = (newTable) => {
		const newFact = new Avial.model.Fact(
			props.fact.Attribute,
			props.fact.Value,
			props.fact.Facets,
			props.fact.Features
		)
		newFact.Table = newTable
		console.log('NEWFACT', newFact)
		props.onChange(newFact)
	}

	const deleteTable = () => {
		const newFact = new Avial.model.Fact(
			props.fact.Attribute,
			props.fact.Value,
			props.fact.Facets,
			props.fact.Features
		)
		props.onChange(newFact)
	}
	return (
		<FoldWrap
			title={
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						width: '100%',
					}}
				>
					<SearchSelect
						value={props.fact.Attribute}
						list={Avial.Taxonomy.Attribute.byName}
						onChange={changeAttribute}
					/>
					<button
						style={{
							background: 'rgba(255,0,0,0.2)',
							color: 'rgba(255,0,0,1)',
							padding: '1px',
							margin: '1px',
						}}
						onClick={() => props.onDelete()}
					>
						<Icon name={'MdOutlineCancel'} size={20} />
					</button>
				</div>
			}
			color={'#b98eff'}
		>
			{(subProps) => (
				<>
					<FoldGrey
						isFolded={
							Object.entries(InputsConstructors).find(
								([k, v]) => v === props.fact.Value.constructor
							)?.[0] === 'null'
						}
						title={`Value (${
							Object.entries(InputsConstructors).find(
								([k, v]) => v === props.fact.Value.constructor
							)?.[0]
						})`}
					>
						{/*@ts-ignore*/}
						<div style={{ margin: '10px' }}>
							{props.fact.Value && (
								<div style={{ display: 'flex' }}>
									<ValueInput
										value={props.fact.Value}
										onChange={changeValue}
									/>
								</div>
							)}
						</div>
					</FoldGrey>

					<FoldGrey
						isFolded={!props.fact.Facets.length}
						title={`Facets (${props.fact.Facets.length})`}
					>
						{/*@ts-ignore*/}
						<div style={{ margin: '10px' }}>
							<FacetsComponent
								facets={props.fact.Facets}
								onChange={changeFacets}
							/>
						</div>
					</FoldGrey>

					<FoldGrey
						isFolded={!props.fact.Features.length}
						title={`Features (${props.fact.Features.length})`}
					>
						{/*@ts-ignore*/}
						<div style={{ margin: '10px' }}>
							{props.fact.Features && (
								<Features
									features={props.fact.Features}
									onChange={changeFeatures}
								/>
							)}
						</div>
					</FoldGrey>

					<FoldGrey
						isFolded={
							!props.fact.Table.columns.length &&
							!props.fact.Table.length
						}
						title={`Table (${props.fact.Table.columns.length} Columns - ${props.fact.Table.length} Rows)`}
					>
						{/*@ts-ignore*/}
						<div style={{ margin: '10px' }}>
							{props.fact.Table && (
								<Table
									table={props.fact.Table}
									onChange={changeTable}
								/>
							)}
						</div>
					</FoldGrey>
				</>
			)}
		</FoldWrap>
	)
}

interface FactsProps {
	value: Facts
	onChange: (p: Facts) => void
}

const FactsComponent: FunctionComponent<FactsProps> = (props) => {
	const addFact = () => {
		if (props.value)
			props.onChange(
				new Avial.model.Facts(
					...props.value,
					new Avial.model.Fact('NULL_ATTRIBUTE')
				)
			)
		else
			props.onChange(
				new Avial.model.Facts(new Avial.model.Fact('NULL_ATTRIBUTE'))
			)
	}

	const changeFact = (oldFact, newFact) => {
		props.onChange(
			new Avial.model.Facts(
				...props.value.map((f) => (f === oldFact ? newFact : f))
			)
		)
	}

	const deleteFact = (fact) => {
		props.onChange(
			new Avial.model.Facts(...props.value.filter((f) => f !== fact))
		)
	}

	return (
		<FoldWrap title={'Facts'} color={'#ff7de9'}>
			{(subProps) => (
				<div style={{}}>
					{props.value?.map((fact, i) => (
						<FactComponent
							fact={fact}
							onChange={(newFact) => changeFact(fact, newFact)}
							onDelete={() => deleteFact(fact)}
						/>
					))}

					<div
						style={{
							padding: '10px',
							width: '100%',
						}}
					>
						<button
							style={{
								background: 'rgba(0,255,0,0.2)',
								color: 'rgba(0,255,0,1)',
								padding: '1px',
								width: '100%',
							}}
							onClick={() => addFact()}
						>
							<Icon name={'MdAddCircleOutline'} size={20} />
						</button>
					</div>
				</div>
			)}
		</FoldWrap>
	)
}

export default FactsComponent
