import React, { FunctionComponent, useState, useEffect, useMemo } from 'react'
import { Icon } from '@ledr/instruments'
import { clearLogAction } from '../store/log/actions'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../store/types'
import { Logger, LogTab, WindowChild } from '@ledr/instruments'

interface LoggerWinProps {}

const LoggerWin: FunctionComponent<LoggerWinProps> = (props) => {
	const logStore = useSelector((state: AppState) => state.logs)
	const dispatch = useDispatch()

	const currentChannels = logStore.channels

	const tab = useMemo(
		() => (
			<div
				style={{
					overflow: 'hidden',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					width: '100%',
				}}
			>
				<button
					onClick={() => {
						dispatch(clearLogAction())
					}}
				>
					<Icon
						name={'BiTrash'}
						size={16}
						//@ts-ignore
						style={{ marginTop: '3px' }}
					/>
				</button>

				<LogTab
					channels={currentChannels}
					onClickChannel={() => {}}
					types={logStore.types}
				/>
			</div>
		),
		[logStore, currentChannels]
	)
	const win = useMemo(
		() => (
			<Logger
				logs={logStore.logs.filter((log) => {
					return (
						currentChannels.find(
							(c) => c.name === log.channel && c.display === true
						) !== undefined
					)
				})}
			/>
		),
		[logStore, currentChannels]
	)

	return <WindowChild tab={tab}>{win}</WindowChild>
}

export default LoggerWin
