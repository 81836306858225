import { FunctionComponent, useState } from 'react'
import Avial, { V_Value, Annotations } from '@ledr/ts-client'
import { ValueInput, Icon, SearchSelect } from '@ledr/instruments'

interface AnnotationsProps {
	value: Annotations
	onChange: (value: Annotations) => void
}

const AnnotationsComponent: FunctionComponent<AnnotationsProps> = (props) => {
	const onChange = (value) => {
		//props.onChange(newValue)
	}

	const addNewAnnotation = () => {
		let newAnnotation = {}

		if (props.value)
			Object.entries(props.value).forEach(([key, value]) => {
				newAnnotation[key] = JSON.stringify(value)
			})

		newAnnotation['NULL_ATTRIBUTE'] = JSON.stringify(
			new Avial.values.V_Null('')
		)
		console.log('HERE', newAnnotation)
		props.onChange(newAnnotation)
	}

	const changeAnnotationKey = (oldKey: string, newKey: string) => {
		let newAnnotation = {}
		Object.entries(props.value).forEach(([key, value]) => {
			if (key === oldKey)
				newAnnotation[newKey + '_ATTRIBUTE'] = JSON.stringify(value)
			else newAnnotation[key] = JSON.stringify(value)
		})
		console.log(oldKey, newKey, newAnnotation)
		props.onChange(newAnnotation)
	}

	const changeAnnotationValue = (keyToEdit: string, newValue: V_Value) => {
		let newAnnotation = {}

		Object.entries(props.value).forEach(([key, value]) => {
			if (key === keyToEdit) newAnnotation[key] = JSON.stringify(newValue)
			else newAnnotation[key] = JSON.stringify(value)
		})
		props.onChange(newAnnotation)
	}

	const deleteAnnotation = (keyToDel: string) => {
		let newAnnotation = {}
		Object.entries(props.value).forEach(([key, value]) => {
			if (key !== keyToDel) newAnnotation[key] = JSON.stringify(value)
		})
		props.onChange(newAnnotation)
	}

	return (
		<>
			{props.value &&
				Object.entries(props.value).map(([key, value]) => (
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<div
							style={{
								minWidth: '100px',
							}}
						>
							<SearchSelect
								value={key}
								list={Avial.Taxonomy.Attribute.byName}
								onChange={(v) => {
									changeAnnotationKey(key, v)
								}}
								placeholder={'category'}
							/>
						</div>
						<ValueInput
							value={value}
							onChange={(newValue) => {
								changeAnnotationValue(key, newValue)
							}}
						/>

						<button
							style={{
								background: 'rgba(255,0,0,0.2)',
								color: 'rgba(255,0,0,1)',
								padding: '1px',
								margin: '1px',
							}}
							onClick={() => deleteAnnotation(key)}
						>
							<Icon name={'MdOutlineCancel'} size={20} />
						</button>
					</div>
				))}

			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
				}}
			>
				<button
					style={{
						background: 'rgba(0,255,0,0.2)',
						color: 'rgba(0,255,0,1)',
						padding: '1px',
						margin: '1px auto',
						width: '100%',
					}}
					onClick={() => addNewAnnotation()}
				>
					<Icon name={'MdAddCircleOutline'} size={20} />
				</button>
			</div>
		</>
	)
}

export default AnnotationsComponent
