import store from './store/index'
import hgtp from './api/hgtp'

import { addLogAction, editLogAction } from './store/log/actions'

function generateToken(n) {
	var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
	var token = ''
	for (var i = 0; i < n; i++) {
		token += chars[Math.floor(Math.random() * chars.length)]
	}
	return token
}
export interface appsType {
	[key: string]: {
		logged: boolean
		route: string
		apiName: string
		needApi: boolean
	}
}

class Overseer {
	singleSpa
	store: typeof store
	loginMode: 'standalone' | ''
	api
	apps: appsType
	auth

	constructor(apps: appsType, singleSpa, loginMode, auth) {
		this.singleSpa = singleSpa
		this.loginMode = loginMode ?? ''
		this.store = store
		this.api = {}
		this.apps = apps
		this.auth = auth

		this.store.dispatch(
			addLogAction({
				msgId: generateToken(8),
				timestamp: Date.now(),
				type: 'info', //requestLog?.res?.isError() ? 'error' : 'debug',
				channel: 'core',
				msg: 'Maestro core init',
				statusCode: 0, //requestLog?.res?.isError() ? 1 : 0,
			})
		)
	}

	start() {
		console.log(
			'- OVERSEER START -------------------------------------------'
		)

		this.store.subscribe(() => {
			//console.log("Overseer : STORE HAS BEEN UPDATED");
			this.singleSpa.triggerAppChange()
		})

		window.onpopstate = (event) => {
			// console.log("Overseer: LOCATION HAS BEEN UPDATED");
		}

		this.registerAllApps()
		this.singleSpa.start()

		const appNames = this.singleSpa.getAppNames()
		const status = this.singleSpa.getAppStatus('app1')
		const mountedAppNames = this.singleSpa.getMountedApps()
		console.log({
			apps: this.apps,
			appNames,
			status,
			mountedAppNames,
		})

		console.log(
			'- OVERSEER START END ---------------------------------------'
		)
	}

	registerAllApps() {
		for (const [appName, appOpts] of Object.entries(this.apps)) {
			this.store.dispatch(
				addLogAction({
					msgId: generateToken(8),
					timestamp: Date.now(),
					type: 'info', //requestLog?.res?.isError() ? 'error' : 'debug',
					channel: 'core',
					msg: `Registering ${appName}`,
					statusCode: 0, //requestLog?.res?.isError() ? 1 : 0,
				})
			)

			this.singleSpa.registerApplication({
				name: appName,
				app: async () => System.import(appName),
				activeWhen: (location) => {
					if (
						appOpts.logged !== undefined &&
						((appOpts.logged && !this.isLogged()) ||
							(!appOpts.logged && this.isLogged()))
					)
						return false

					if (
						(!appOpts.route ||
							(appOpts.route &&
								location.pathname.startsWith(
									`/${appOpts.route}`
								))) &&
						(!appOpts.needApi ||
							(appOpts.needApi &&
								this.store.getState().endpoints[0].status ===
									'open'))
					)
						return true
					return false
				},

				customProps: {
					domElementGetter: () => document.getElementById(appName),
					basename: appOpts.route,
					overseer: this,
					api: this.api[appOpts.apiName],
					loginMode: this.loginMode,
					auth: this.auth,
				},
			})
		}
	}

	createEndpoint({ name, scheme, host, port, sslPem }) {
		this.store.dispatch(
			addLogAction({
				msgId: generateToken(8),
				timestamp: Date.now(),
				type: 'info', //requestLog?.res?.isError() ? 'error' : 'debug',
				channel: 'core',
				msg: `Create endpoint ${name}`,
				statusCode: 0, //requestLog?.res?.isError() ? 1 : 0,
			})
		)

		if (this.api[name]) {
			console.error(`API [${name}] already mounted`)
			console.warn(this.api[name])
			return
		}

		this.api[name] = new hgtp(
			{
				name,
				scheme,
				host,
				port,
				sslPem,
			},
			this
		)
	}

	isLogged() {
		return this.store.getState().user.jwt ||
			this.store.getState().user.keychain
			? true
			: false
	}
}

// IMPORTANT
// EXPORT SAME INSTANCE FOR ALL IMPORTS
export default Overseer

/*

 window.SystemJS = window.System

			function insertNewImportMap(newMapJSON) {
				const newScript = document.createElement('script')
				newScript.type = 'systemjs-importmap'
				newScript.text = JSON.stringify(newMapJSON)
				const allMaps = document.querySelectorAll('script[type="systemjs-importmap"]')

				allMaps[allMaps.length - 1].insertAdjacentElement( 'afterEnd', newScript)
			}

			const devDependencies = {
				imports: {
					react: 'http://localhost:8000/react.development.js',
					'react-dom': 'http://localhost:8000/react-dom.development.js',
					'react-dom/server': 'http://localhost:8000/react-dom-server.browser.development.js',
					'single-spa': 'http://localhost:8000/single-spa.min.js',
					lodash: 'http://localhost:8000/lodash.js',
					rxjs: 'http://localhost:8000/rxjs.umd.js',
				}
			}

			const prodDependencies = {
				imports: {
					react: 'http://localhost:8000/react.production.min.js',
					'react-dom': 'http://localhost:8000/react-dom.production.min.js',
					'react-dom/server': 'http://localhost:8000/react-dom-server.browser.production.min.js',
					'single-spa': 'http://localhost:8000/single-spa.min.js',
					lodash: 'http://localhost:8000/lodash.min.js',
					rxjs: 'http://localhost:8000/rxjs.umd.min.js',
				}
			}

			const devMode = true // you will need to figure out a way to use a set of production dependencies instead
			if (devMode) {
				insertNewImportMap(devDependencies)
			} else {
				insertNewImportMap(prodDependencies)
			}

 */
