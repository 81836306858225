import { FC, ReactNode } from 'react'
import './DefaultLayout.scss'

interface DefaultLayoutProps {
	headerLeft?: JSX.Element
	header?: JSX.Element
	headerCenter?: JSX.Element
	headerRight?: JSX.Element
	bodyLeft?: JSX.Element
	bodyCenter?: JSX.Element
	bodyRight?: JSX.Element
	footerLeft?: JSX.Element
	footerCenter?: JSX.Element
	footerRight?: JSX.Element
	children: ReactNode
}

const DefaultLayout: FC<DefaultLayoutProps> = (props) => {
	return (
		<div className={'defaultlayout_layout'}>
			<header className={`shadow defaultlayout_header`}>
				{props.header}
			</header>
			<section className={'defaultlayout_body'}>
				{props.bodyLeft && (
					<aside className={`shadow defaultlayout_bodyLeft`}>
						{props.bodyLeft}
					</aside>
				)}
				<main className={'defaultlayout_bodyCenter'}>
					{props.bodyCenter}
				</main>
				{props.bodyRight && (
					<aside className={`shadow defaultlayout_bodyRight`}>
						{props.bodyRight}
					</aside>
				)}
			</section>
			{(props.footerLeft || props.footerCenter || props.footerRight) && (
				<footer className={'defaultlayout_footer'}>
					<div className={'defaultlayout_footerLeft'}>
						{props.footerLeft}
					</div>
					<div className={'defaultlayout_footerCenter'}>
						{props.footerCenter}
					</div>
					<div className={'defaultlayout_footerRight'}>
						{props.footerRight}
					</div>
				</footer>
			)}
		</div>
	)
}

export const Layout: FC<DefaultLayoutProps> = (props) => {
	return <div className={'defaultlayout_layout'}>{props.children}</div>
}
export const Header: FC<DefaultLayoutProps> = (props) => {
	return <header className={`defaultlayout_header`}>{props.children}</header>
}

export const Body: FC<DefaultLayoutProps> = (props) => {
	return <section className={'defaultlayout_body'}>{props.children}</section>
}

export const Left: FC<DefaultLayoutProps> = (props) => {
	return (
		<aside className={`shadow defaultlayout_bodyLeft`}>
			{props.children}
		</aside>
	)
}

export const Center: FC<DefaultLayoutProps> = (props) => {
	return <main className={'defaultlayout_bodyCenter'}>{props.children}</main>
}
export const Right: FC<DefaultLayoutProps> = (props) => {
	return (
		<aside className={`shadow defaultlayout_bodyRight`}>
			{props.children}
		</aside>
	)
}

export default DefaultLayout
