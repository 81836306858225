import React, { FC, ReactNode, useState } from 'react'
import './Hud.scss'

interface PanelProps {
	position: 'top' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight'
	children: ReactNode
}

const Panel: FC<PanelProps> = (props) => {
	const [isOpen, setIsOpen] = useState(true)

	return (
		<div className={`hud ${props.position ?? 'topLeft'}`}>
			{props.children}
		</div>
	)
}

interface PanelContentProps {
	title: string
	isOpen?: boolean
	children: ReactNode
}

const PanelContent: FC<PanelContentProps> = (props) => {
	const [isOpen, setIsOpen] = useState(props.isOpen ?? false)

	return (
		<div className={'threeVisibilityTool'}>
			<div className={'title'} onClick={() => setIsOpen(!isOpen)}>
				{' '}
				{props.title}
				<span className={'isOpen'}>{isOpen ? '▼' : '▶'}</span>
			</div>

			{isOpen && (
				<div style={{ flex: 1 }}>
					<div className={'content'}>{props.children}</div>
				</div>
			)}
		</div>
	)
}

export { Panel, PanelContent }
