import {
	FunctionComponent,
	useState,
	useEffect,
	useContext,
	useMemo,
} from 'react'
import Avial from '@ledr/ts-client'
import { Print } from '@ledr/instruments'
import ApiContext from '../context/api'

import { WindowChild } from '@ledr/instruments'
import './Mount.scss'

interface PackageProps {}

const Package: FunctionComponent<PackageProps> = (props) => {
	const [list, setList] = useState(null)

	const api = useContext(ApiContext)

	const tab = useMemo(() => <>PACKAGE TAB</>, [])
	const win = useMemo(() => <>package</>, [list])
	return <WindowChild tab={tab}>{win}</WindowChild>
}

export default Package
