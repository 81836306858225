import { FC, ReactNode } from 'react'
import './Popup.scss'

interface PopupProps {
	show: boolean
	close?: () => void
	children: ReactNode
}

const Popup: FC<PopupProps> = (props) => {
	if (!props.show) return null

	const close = () => {
		if (props.close) props.close()
	}

	return (
		<div
			className={'popUp'}
			style={{
				backgroundColor: 'rgba(0,0,0,0)',
			}}
			onClick={(e) => {
				e.stopPropagation()
				if (e.target === e.currentTarget) close()
			}}
		>
			<div
				className={'popUpModale'}
				style={{
					backgroundColor: 'rgba(0,0,0,0)',
				}}
			>
				{props.close && (
					<button
						className={'closeButton'}
						onClick={() => props.close()}
					>
						X
					</button>
				)}
				<div
					className={'popUpContent'}
					style={{
						backgroundColor: 'rgba(0,0,0,0)',
						border: 'none',
					}}
				>
					{props.children}
				</div>
			</div>
		</div>
	)
}

export default Popup
