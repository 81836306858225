//@ts-expect-error
import { notif } from '@ledr/layout'
import {
	FunctionComponent,
	useState,
	useEffect,
	useMemo,
	useContext,
} from 'react'
import useEntity from '../hook/entity'
import InstrumentsLinkerContext from '../context/instrumentsLinker'
import Loading from '../components/utilities/Loading'
import Error from '../components/utilities/Error'
import EntityForm from '../components/form/Entity'
import Avial, { AvialEntity, V_Entity } from '@ledr/ts-client'
import './EntityMenu.scss'

import {
	Icon,
	Print,
	T_SidebarCategories,
	wmContext,
	WindowChild,
	Metadata,
	Tooltip,
} from '@ledr/instruments'

import { PrintEntityStore } from '../hoc/connectedComponents'

interface EntityWindowTabProps {
	options: any
	onChange: (e: any) => void
	//@ts-ignore
	currentEntity: any // Avial.Entity;
	setCurrentEntity: any // Avial.Entity;
	history: any[]
	setHistory: any
	entityContent: AvialEntity
}

const EntityWindowTab: FunctionComponent<EntityWindowTabProps> = (props) => {
	function onToggleOption(key: string) {
		props.onChange({
			...props.options,
			[key]: !props.options[key],
		})
	}

	return (
		<>
			<div
				className={'windowTab'}
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					width: '100%',
				}}
			>
				<div style={{ display: 'flex' }}>
					{/*
					<button
						className={props.options.isValueTypeDisplayed ? "active" : ""}
						onClick={() => onToggleOption("isValueTypeDisplayed")}
					>
						Tag
					</button>
					<button
						className={props.options.isEdit ? "active" : ""}
						onClick={() => onToggleOption("isEdit")}
					>
						Edit
					</button>
						*/}
					<button
						className={props.options.isDisplayRaw ? 'active' : ''}
						onClick={() => onToggleOption('isDisplayRaw')}
					>
						Raw
					</button>
				</div>

				<div style={{ display: 'flex' }}>
					<Tooltip
						// @ts-ignore
						text={
							<div>
								{props.history.map((h, i) => {
									return (
										<div
											key={i}
											onClick={() => {
												props.setCurrentEntity(h)
											}}
										>
											{h.toString()}
										</div>
									)
								})}
							</div>
						}
						position={'bottom'}
					>
						{/*
				// @ts-ignore */}
						<div style={{ display: 'flex' }}>
							<button
								className={
									props.options.isValueTypeDisplayed
										? 'active'
										: ''
								}
								onClick={() => {
									if (props.history.length > 1) {
										props.setCurrentEntity(props.history[1])
										props.setHistory(props.history.slice(1))
									}
								}}
							>
								{'<'}
							</button>

							<button
								className={
									props.options.isValueTypeDisplayed
										? 'active'
										: ''
								}
								onClick={() => {}}
							>
								{'>'}
							</button>
						</div>
					</Tooltip>
					<EntityForm
						entity={props.currentEntity}
						onSubmit={props.setCurrentEntity}
					/>
				</div>
			</div>
		</>
	)
}

interface EntityMenuProps {}

const EntityMenu: FunctionComponent<EntityMenuProps> = (props) => {
	const MyContext = useContext(InstrumentsLinkerContext)
	const wm = useContext(wmContext)

	const [selectedEntity, setSelectedEntity] = useState(
		new Avial.values.V_Entity(
			//@ts-ignore
			wm.win?.data?.entity ?? '<0>'
		)
	)
	const [currentEntity, setCurrentEntity] = useState(
		new Avial.values.V_Entity(
			//@ts-ignore
			wm.win?.data?.entity ?? '<0>'
		)
	)
	const [history, setHistory] = useState([])

	useEffect(() => {
		MyContext?.out?.['currentEntity']?.(currentEntity)
	}, [MyContext?.out?.['currentEntity'], currentEntity])

	useEffect(() => {
		MyContext?.out?.['selectedEntity']?.(selectedEntity)
	}, [MyContext?.out?.['selectedEntity'], selectedEntity])

	useEffect(() => {
		MyContext.declareInOut({
			in: [{ port: 'currentEntity', setter: setCurrentEntity }],
			out: [{ port: 'currentEntity' }, { port: 'selectedEntity' }],
		})
		return () => {
			MyContext.unDeclareInOut()
		}
	}, [])

	const [options, setOptions] = useState({
		isValueTypeDisplayed: false,
		isEdit: false,
		isDisplayRaw: false,
	})

	const entityStore = useEntity(currentEntity)

	useEffect(() => {
		let newHistory = [
			currentEntity,
			...history.filter((h) => !h.equals(currentEntity)),
		]
		newHistory.splice(15, 10)
		setHistory(newHistory)
	}, [currentEntity])

	const tab = useMemo(
		() => (
			<EntityWindowTab
				options={options}
				onChange={(e) => {
					setOptions(e)
				}}
				currentEntity={currentEntity}
				setCurrentEntity={setCurrentEntity}
				history={history}
				setHistory={setHistory}
				entityContent={entityStore.contents}
			/>
		),
		[
			options,
			currentEntity,
			setCurrentEntity,
			history,
			setHistory,
			entityStore.contents,
		]
	)
	const win = useMemo(
		() => (
			<Loading
				isLoading={entityStore.isLoadingContents}
				message={`Loading ${currentEntity.toString()} Contents`}
			>
				<Error error={entityStore.errorContents}>
					<div
						className={'EntityMenuWrap'}
						style={{ padding: '10px' }}
					>
						{entityStore?.contents?.Properties?.map((e) => (
							<div
								className="item"
								//@ts-ignore
								onClick={() => setSelectedEntity(e.Value)}
							>
								<div className="menuItem">
									<div className={'icon'}>
										<Icon
											name={
												e.Annotations?.[
													'AVESTERRA_ATTRIBUTE'
												]?.toString() ?? ''
											}
											size={30}
										/>
									</div>
									<div className={'name'}>{e.Name}</div>
								</div>

								<div>{e.Value.toString()}</div>
							</div>
						))}
						<hr />
						<Print value={entityStore?.contents?.Properties} />
					</div>
				</Error>
			</Loading>
		),
		[options, entityStore, MyContext.out?.['currentEntity']]
	)
	return <WindowChild tab={tab}>{win}</WindowChild>
}

export default EntityMenu
