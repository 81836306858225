import { FunctionComponent, useState, useEffect } from 'react'
import Avial from '@ledr/ts-client'
import { EntityInput } from '@ledr/instruments'

interface EntityFormProps {
	entity?: any
	onSubmit: any
}

const EntityForm: FunctionComponent<EntityFormProps> = (props) => {
	const defaultValue = new Avial.values.V_Entity('<0|0|0>')
	const [entity, setEntity] = useState(defaultValue)
	useEffect(() => setEntity(props.entity), [props.entity])
	return (
		<>
			<EntityInput
				value={props.entity}
				onChange={(e) => {
					setEntity(e)
				}}
			/>

			<button
				onClick={() => {
					props.onSubmit(entity)
				}}
			>
				GO
			</button>
		</>
	)
}
export default EntityForm
