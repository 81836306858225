//@ts-ignore
import { notif } from '@ledr/layout'
import { FunctionComponent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Signup, Confirm, Login, Forgot, Reset } from '../../hoc/login'
import { Switch, Route } from 'react-router-dom'
import Popup from '../../components/utilities/Popup'
import Avial from '@ledr/ts-client'
import Logo from '../../components/menu/logo96.png'
import { AppState } from '../../store/types'
import packageJson from '../../../../package.json'
import {
	loginAction,
	logoutAction,
	userChangeKeychainAction,
	userAddKeychainAction,
} from '../../store/user/actions'
import { Layout } from '../../components/layout/DefaultLayout'
import { Tooltip, AuthInput, Icon } from '@ledr/instruments'
import PureLog from './PureLog'

const isStandAlone = true
interface logProps {
	loginMode: 'standalone' | ''
	auth: string
}
const Browser: FunctionComponent<logProps> = (props) => {
	const { endpoints } = useSelector((state: AppState) => ({
		endpoints: state.endpoints,
	}))

	const [auth, setAuth] = useState(
		props.auth || '00000000-0000-0000-0000-000000000000'
	)

	const dispatch = useDispatch()

	let standaloneLog = () => {
		let newKeychain = {
			firstName: '-FirstName-',
			lastName: '-LastName-',
			email: 'standalone',
			identity: '<0|0|0>',
			keychain: {
				identity: 'Standalone',
				current: 0,
				accesses: [
					{
						compartment: 'Standalone',
						token: auth,
					},
				],
			},
		}

		dispatch(loginAction(newKeychain))
		notif.success('logged')
	}

	return (
		<Layout>
			<div
				style={{
					margin: '5px',
					display: 'flex',
				}}
			>
				<img
					alt={'Ledr logo'}
					className={'logo'}
					src={Logo}
					style={{
						width: '37px',
						height: '37px',
					}}
				/>
				<div
					style={{
						marginTop: '3px',
						marginLeft: '5px',
						fontSize: '30px',
						fontWeight: 'bold',
					}}
				>
					LEDR
				</div>
			</div>
			<div
				style={{
					position: 'absolute',
					display: 'flex',
					top: '0px',
					right: '0px',
					margin: '5px',
				}}
			>
				{endpoints.map((e, i) => (
					<div
						key={i}
						style={{
							padding: '5px 5px',
							display: 'flex',
							border: '1px solid rgba(255, 255, 255, 0.1)',
							backgroundColor: 'rgba(0, 0, 0, 0.2)',
							borderRadius: '4px',
							color: 'grey',
						}}
					>
						<>
							{
								//@ts-ignore
								e?.channels?.map?.((e, i) => (
									<div
										key={i}
										style={{
											margin: '1px',
											width: '15px',
											height: '15px',
											borderRadius: '2px',

											opacity: e?.open
												? e?.in_use
													? 1
													: 0.5
												: 1,
											backgroundColor: e?.open
												? e?.in_use
													? 'chocolate'
													: 'green'
												: 'red',
										}}
									></div>
								))
							}
						</>
					</div>
				))}

				{endpoints.map((e, i) => (
					<div
						key={i}
						style={{
							marginLeft: '5px',
							padding: '5px 5px',
							display: 'flex',
							border: '1px solid rgba(255, 255, 255, 0.1)',
							backgroundColor: 'rgba(0, 0, 0, 0.2)',
							borderRadius: '4px',
							color: 'grey',
						}}
					>
						{e.host}
					</div>
				))}
			</div>

			<div
				style={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform:
						'translate(calc(-50% + 0.5px), calc(-50% + 0.5px))',
					zIndex: 1000000,
					/*
					position: 'fixed',
					top: '0px',
					left: '0px',
					width: '100vw',
					height: '100vh',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
 */
				}}
			>
				{props.loginMode !== 'standalone' ? (
					<Switch>
						<Route path="/signup">
							<Signup />
						</Route>

						<Route path="/forgot">
							<Forgot />
						</Route>

						<Route
							exact
							path="/resetPassword/:email/:token"
							children={<Reset />}
						/>
						<Route
							exact
							path="/confirm/:email/:token"
							children={<Confirm />}
						/>

						<Route>
							<Login />
						</Route>
					</Switch>
				) : (
					<div style={{ textAlign: 'center' }}>
						<h1
							style={{
								letterSpacing: '8px',
								fontSize: '30px',
							}}
						>
							MAESTRO
						</h1>
						<div
							style={{
								width: '370px',
								margin: '25px auto',
							}}
						>
							<AuthInput
								value={
									new Avial.values.V_Authorization(
										props.auth ?? auth
									)
								}
								onChange={(value) => setAuth(value.toString())}
							/>
						</div>

						<button
							style={{
								fontSize: '18px',
								height: '36px',
								padding: '0px 20px',
							}}
							onClick={standaloneLog}
						>
							Enter
						</button>
					</div>
				)}
			</div>

			<PureLog />

			<div
				className="menuButton"
				onClick={() => document.body.requestFullscreen()}
				style={{
					position: 'absolute',
					bottom: '0px',
					right: '0px',
					margin: '0px 5px 10px',
				}}
			>
				<a>
					Fullscreen
					<Icon
						name={'RiFullscreenFill'}
						size={25}
						style={{ margin: '0px 0px -6px 5px' }}
					/>
					{
						//RiFullscreenExitFill
					}
				</a>
			</div>
		</Layout>
	)
}

export default Browser
