import React, { FunctionComponent, ReactNode, useState } from 'react'
import HudContext from '../../context/hud'
import { Panel, PanelContent } from './Panel'

function closureWidget(setAllPorts, allPorts) {
	let value = allPorts

	function increment(n: HudElement[]) {
		n = n.filter((nn) => value.findIndex((v) => nn.name === v.name) === -1)

		value = value.filter(
			(v) => n.findIndex((nn) => nn.name === v.name) === -1
		)

		value.push(...n)
		setAllPorts(value)
	}

	function getTest() {
		return value
	}

	return [increment, getTest()]
}

export interface HudElement {
	name: string
	x: 'left' | 'center' | 'right'
	y: 'top' | 'center' | 'bottom'
	widget?: (data: any) => {}
}

export interface HudMethods {
	test: any
	declare: (newHudElement: HudElement[]) => void
	setData: (name: string, data: any) => void
}

interface HudProps {
	children?: ReactNode
	visible: boolean
}
const Hud: FunctionComponent<HudProps> = (props) => {
	const [test, setAllPorts] = useState([])
	const [setTest, getTest] = closureWidget(setAllPorts, test)

	//const [test, setTest] = useState([]);
	let methods: HudMethods = {
		test,
		declare: setTest,
		setData: (name, data) => {
			//@ts-ignore
			let toSet = getTest.find((t) => t.name === name)
			if (!toSet) return
			toSet.data = data
			setTest([toSet])
		},
	}

	if (!props.visible)
		return (
			<HudContext.Provider value={methods}>
				{props.children}
			</HudContext.Provider>
		)
	return (
		<HudContext.Provider value={methods}>
			{props.children}

			<Panel position={'topLeft'}>
				{
					//@ts-ignore
					getTest
						.filter((e) => e.x === 'left' && e.y === 'top')
						.map((t) => t.widget(t.data))
				}
			</Panel>

			<Panel position={'topRight'}>
				{
					//@ts-ignore
					getTest
						.filter((e) => e.x === 'right' && e.y === 'top')
						.map((t) => t.widget(t.data))
				}
			</Panel>
			<Panel position={'bottomLeft'}>
				{
					//@ts-ignore
					getTest
						.filter((e) => e.x === 'center' && e.y === 'center')
						.map((t) => t.widget(t.data))
				}
			</Panel>

			<Panel position={'bottomRight'}>
				{
					//@ts-ignore
					getTest
						.filter((e) => e.x === 'right' && e.y === 'bottom')
						.map((t) => t.widget(t.data))
				}
			</Panel>
		</HudContext.Provider>
	)
}

export default Hud
