import { I_Log } from '@ledr/instruments'

export const ADD_LOG = 'ADD_LOG'

export interface AddLogAction {
	type: typeof ADD_LOG
	payload: I_Log
}

export const CLEAR_LOG = 'CLEAR_LOG'

export interface ClearLogAction {
	type: typeof CLEAR_LOG
	payload: null
}

export const EDIT_LOG = 'EDIT_LOG'

export interface EditLogAction {
	type: typeof EDIT_LOG
	payload: I_Log
}

export type LogActions = AddLogAction | ClearLogAction | EditLogAction
