import * as THREE from 'three'
import ForceNode from './forceNode'
import ViewportElement from '../ViewportElement'
import { JSX2DNode, JSX3DNode } from '../JSXObject'
import Locutor from '../../../components/Locutor'
import { iconClassColor } from '../../../style/icons'

function setOpacity(obj, opacity) {
	obj.children.forEach((child) => {
		setOpacity(child, opacity)
	})
	if (obj.material) {
		obj.material.opacity = opacity
	}
}

// EXTENDS OVER ORCHESTRA THREE CLASS
class ForceLink extends ViewportElement {
	group: THREE.Group
	mesh: THREE.Mesh
	line: THREE.Line
	selected: boolean
	locutors: any

	hovered: boolean

	src: ForceNode
	dst: ForceNode

	JSXLocutor: JSX2DNode

	constructor(src, dst, locutors, three) {
		super(three)
		this.group.renderOrder = 10

		this.locutors = locutors
		this.group.userData.object = this

		this.hovered = false
		this.src = src
		this.dst = dst

		this.src.addLinkChild(this)
		this.dst.addLinkParent(this)

		this.styles.registerStyle([
			{
				name: 'default',
				set: () => {
					setOpacity(this.group, 1)
				},
				remove: () => {},
				animate: () => {
					/*
					let distance = this.src.group.position.distanceTo(
						this.dst.group.position
					)
					 */
					//@ts-ignore
					//this.line.material.color.r = distance;
					//@ts-ignore
					//this.line.material.color.g = 1 - distance;
					//@ts-ignore
					//this.line.material.linewidth = distance * 10;
				},
			},
			{
				name: 'shadow',
				set: () => {
					setOpacity(this.group, 0.1)
				},
				remove: () => {
					setOpacity(this.group, 1)
				},
				animate: (d) => {},
			},
			{
				name: 'locutor',
				set: () => {
					this.initLocutors()
				},
				remove: () => {
					this.JSXLocutor.destruct(),
						this.group.remove(this.JSXLocutor.group)
				},
				animate: (d) => {
					let dst_rel = this.dst.group.position.clone()
					let locPos = this.src.group.position
						.clone()
						.lerp(dst_rel, 0.5)

					this.JSXLocutor.group.position.set(
						locPos.x,
						locPos.y,
						locPos.z
					)
					console.log(
						this.src.group.position,
						this.dst.group.position,
						this.JSXLocutor.group.position
					)
				},
			},
			{
				name: 'inhib',
				set: () => {
					this.group.visible = false
				},
				remove: () => {
					this.group.visible = true
				},
				animate: (d) => {},
			},
		])

		this.styles.activate('default')
		/*
		const materialLine = new THREE.LineBasicMaterial({
			transparent: true,
			color: new THREE.Color(0x000000).setHSL(
				iconClassColor(this.dst.data.class ?? '') / 360,
				1.0,
				0.6
			),
			linewidth: this.locutors.length * this.locutors.length,
		})

		const geometryLine = new THREE.BufferGeometry().setFromPoints([
			src.group.position,
			dst.group.position,
		])
 */
		//this.line = new THREE.Line(geometryLine, materialLine)
		//		this.group.add(this.line)
	}

	initLocutors() {
		this.JSXLocutor = new JSX2DNode(
			this.three,

			(
				<div
					style={{
						pointerEvents: 'none',
						borderRadius: '2px',
						backgroundColor: 'rgba(255,255,255,0.8)',
						padding: '1px',
						fontSize: '11px',
					}}
				>
					{this.locutors.map((loc) => (
						<div
							style={{
								borderRadius: '2px',
								backgroundColor: 'rgba(32,32,32,1)',
								margin: '2px',
								padding: '4px',
								fontSize: '11px',
							}}
						>
							<Locutor value={loc} />
						</div>
					))}
				</div>
			)
		)

		this.group.add(this.JSXLocutor.group)
		this.JSXLocutor.group.scale.setScalar(0.001)
	}

	destruct() {
		this.src.removeLinkChild(this)
		this.dst.removeLinkParent(this)
		//@ts-ignore

		//	this.line.material.dispose()
		//	this.line.geometry.dispose()
	}

	/*
	unSelect() { this.selected = false; }
	select() { this.selected = true; }

	hoverIn() {
		console.log("hoverIn", this)
		this.hovered = true;
	}

	hoverOut() {
		console.log("hoverOut", this)
		this.hovered = false;
	}
	 */

	animate(d) {
		super.animate(d)

		/*
		let p = this.line.geometry.attributes.position.array
		p[0] = this.src.group.position.x
		p[1] = this.src.group.position.y
		p[2] = this.src.group.position.z
		p[3] = this.dst.group.position.x
		p[4] = this.dst.group.position.y
		p[5] = this.dst.group.position.z
		this.line.geometry.attributes.position.needsUpdate = true
		this.line.geometry.computeBoundingSphere()
 */
	}
}

export default ForceLink
