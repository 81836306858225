import * as THREE from 'three'
import Avial from '@ledr/ts-client'

import ViewportElement from '../ViewportElement'

import { JSX2DNode, JSX3DNode } from '../JSXObject'

import Mouse from '../components/Mouse'
import ContextualMenu from '../components/ContextualMenu'

import ForceLink from './forceLink'
import Label from '../components/Label'

import Edge from './edge'

function setOpacity(obj, opacity) {
	obj.children.forEach((child) => {
		setOpacity(child, opacity)
	})
	if (obj.material) {
		obj.material.opacity = opacity
	}
}

// EXTENDS OVER ORCHESTRA THREE CLASS //
class ForceNode extends ViewportElement {
	actualID: number // FORCE BUFFERED NODE ID
	data: any

	selected: boolean
	hovered: boolean
	fixed: boolean = false
	isEdge: boolean = false
	hidden: boolean = false
	dragging: boolean = false

	JSXNode: JSX3DNode
	JSXCtxMenu: JSX2DNode
	edge: Edge

	linkChildren: ForceLink[] = []
	linkParents: ForceLink[] = []

	nextPos: THREE.Vector3
	virtualPos: THREE.Vector3
	repulsion: number

	constructor(obj, three) {
		super(three)
		this.virtualPos = new THREE.Vector3()
		this.repulsion = 1

		this.styles.activate('default')

		this.data = obj.data
		this.group.userData.object = this

		this.setNextPos(new THREE.Vector3(obj.pos.x, obj.pos.y, obj.pos.z))
		this.group.position.set(obj.pos.x, obj.pos.y, obj.pos.z)

		this.styles.registerStyle([
			{
				name: 'default',
				set: () => {},
				remove: () => {},
				animate: () => {},
			},
			{
				name: 'selected',
				set: () => {
					this.JSXNode.div.style.backgroundColor = 'white'
					this.JSXNode.div.style.borderRadius = '100%'
					/* return { TIMESTAMP:0, ANIMATION:{}} */
				},
				remove: () => {
					console.log('STYLE rm SELECTED', this)
					this.JSXNode.div.style.backgroundColor = ''
				},
				animate: (/*delta, */ closure) => {
					/*delta: 16 in ms*/
					/*closure = { TIMESTAMP:0, ANIMATION:{}, } */
				},
			},
			{
				name: 'fix',
				set: () => {
					this.JSXNode.div.style.backgroundColor = 'grey'
					this.JSXNode.div.style.borderRadius = '100%'
				},
				remove: () => {
					this.JSXNode.div.style.backgroundColor = ''
				},
				animate: () => {
					this.nextPos = this.group.position.clone()
				},
			},
			{
				name: 'edge',
				set: () => {
					this.JSXNode.div.className = 'edge'
					this.edge = new Edge(this, this.three)
					this.group.add(this.edge.group)
					this.linkChildren.forEach((e) => e.styles.activate('inhib'))
				},
				remove: () => {
					this.JSXNode.div.className = ''
					this.edge.remove()
					this.group.remove(this.edge.group)
					this.linkChildren.forEach((e) =>
						e.styles.desactivate('inhib')
					)
				},
				animate: () => {
					this.edge.animate()
				},
			},
			{
				name: 'highlight',
				set: () => {
					this.JSXNode.div.style.opacity = '1'
					//@ts-ignore
					this.three.links2.forEach((link) => {
						link.styles.activate('shadow')
					})
				},
				remove: () => {
					//@ts-ignore
					this.three.nodes2.forEach((nodes) => {
						nodes.JSXNode.div.style.opacity = '1'
					})
					//@ts-ignore
					this.three.links2.forEach((link) => {
						link.styles.desactivate('shadow')
					})
				},
				animate: (d) => {},
			},
			{
				name: 'hover',
				set: () => {
					this.group.scale.setScalar(1.5)

					//@ts-ignore
					this.three.nodes2.forEach((nodes) => {
						nodes.JSXNode.div.style.opacity = '0.1'
					})

					//@ts-ignore
					this.three.links2.forEach((link) => {
						link.styles.activate('shadow')
					})

					this.linkChildren.forEach((link) => {
						link.dst.JSXNode.div.style.opacity = '0.8'
						link.src.JSXNode.div.style.opacity = '1'
						link.dst.group.scale.setScalar(1.2)
						setOpacity(link.group, 1)
					})
					this.JSXNode.div.style.opacity = '1'
				},
				remove: () => {
					this.group.scale.setScalar(1)

					//@ts-ignore
					this.three.nodes2.forEach((nodes) => {
						nodes.JSXNode.div.style.opacity = '1'
					})
					//@ts-ignore
					this.three.links2.forEach((link) => {
						link.styles.desactivate('shadow')
						link.dst.group.scale.setScalar(1)
					})
				},
				animate: (d) => {
					this.nextPos = this.group.position.clone()
				},
			},
			{
				name: 'locutors',
				set: () => {
					// FAIRE TOMBER ATTRACTION A 0
					// FAIRE TOMBER ATTRACTION A 0
					// FAIRE TOMBER ATTRACTION A 0
					// FAIRE TOMBER ATTRACTION A 0

					//this.repulsion = 2;
					this.linkChildren.forEach((link) => {
						//link.dst.repulsion = 2;
					})
					this.linkChildren.forEach((link) => {
						link.styles.activate('locutor')
					})
				},
				remove: () => {
					this.repulsion = 1
					this.linkChildren.forEach((link) => {
						link.dst.repulsion = 1
					})
					//@ts-ignore
					this.three.links2.forEach((link) => {
						link.styles.desactivate('locutor')
					})
				},
				animate: (d) => {},
			},
		])

		//this.initView();
	}

	destruct() {
		if (this.JSXNode) {
			this.JSXNode.destruct()
			this.group.remove(this.JSXNode.group)
		}
	}

	initView() {
		this.destruct()

		this.JSXNode = new JSX3DNode(
			this.three,
			(
				<Mouse
					{...this.events}
					onContextMenu={this.onContextMenu.bind(this)}
				>
					{this.linkChildren.length !== 0 && (
						<div
							style={{
								backgroundColor: 'white',
								color: 'black',
								padding: '4px',
								position: 'absolute',
								borderRadius: '100px',
								marginTop: '-10px',
								marginLeft: '50px',
							}}
						>
							{this.linkChildren.length}
						</div>
					)}
					<Label data={this.data} />
				</Mouse>
			)
		)

		this.JSXNode.group.scale.setScalar(0.002)
		this.group.add(this.JSXNode.group)
	}

	destroyContextMenu() {
		if (this.JSXCtxMenu) {
			this.JSXCtxMenu.destruct()
			this.group.remove(this.JSXCtxMenu.group)
			this.JSXCtxMenu = null
		}
	}

	onContextMenu() {
		this.JSXCtxMenu = new JSX2DNode(
			this.three,
			(
				<div className={'shadow'}>
					<Mouse
						{...this.events}
						onClick={() => {}}
						onWheel={(evt) => {
							evt.preventDefault()
							evt.stopPropagation()
						}}
						drag={() => {}}
						hoverOut={(evt) => {
							this.hoverOut()
							this.destroyContextMenu()
						}}
						onContextMenu={this.onContextMenu.bind(this)}
					>
						<ContextualMenu
							entries={[
								...this.three.contextMenu,
								{
									name: this.fixed ? 'UN-FIX' : 'FIX',
									onClick: (entity) => {
										console.log('CTXMENU onclick', this)
										if (!this.fixed)
											this.styles.activate('fix')
										else this.styles.desactivate('fix')
										this.fixed = !this.fixed
										console.log('ctxMenu')
										this.three.force.updateFixed()
									},
								},
								{
									name: this.isEdge ? 'Point' : 'Edge',
									onClick: (entity) => {
										if (!this.isEdge)
											this.styles.activate('edge')
										else this.styles.desactivate('edge')

										this.isEdge = !this.isEdge
									},
								},
							]}
							data={this.data}
							debug={this}
							close={() => {
								this.hoverOut()
								this.destroyContextMenu()
							}}
						/>
					</Mouse>
				</div>
			)
		)
		this.group.add(this.JSXCtxMenu.group)
	}

	onClick() {
		this.three.setSelectedEntity(new Avial.values.V_Entity(this.data.pid))
		this.three.triggerReactLifeCycle()

		this.select(!this.selected)
	}

	unSelect() {
		this.selected = false
	}

	select(bool: boolean) {
		this.selected = true
		//@ts-ignore
		this.three.nodes2.forEach((nodes) => {
			nodes.styles.desactivate('selected')
		})
		this.styles.activate('selected')
	}

	hoverIn(evt) {
		this.hovered = true
		this.styles.activate('hover')
		if (evt.shiftKey) this.styles.activate('locutors')
	}

	hoverOut() {
		this.styles.desactivate('locutors')
		this.styles.desactivate('hover')
		this.hovered = false
	}

	dragStart() {
		console.log('dragStart')
		this.dragging = true
		this.three.force.updateFixed()
	}

	dragEnd() {
		console.log('dragEnd')
		this.dragging = false
		this.three.force.updateFixed()
	}

	drag(x: number, y: number) {
		this.group.position.set(
			this.group.position.x + x / 50,
			this.group.position.y + -y / 50,
			this.group.position.z
		)
		this.nextPos = this.group.position
		this.three.force.moveNode(this.actualID, this.nextPos)
	}

	setNextPos(nextPos) {
		this.nextPos = nextPos
	}

	addLinkChild(link) {
		this.linkChildren.push(link)
	}
	addLinkParent(link) {
		this.linkParents.push(link)
	}

	removeLinkChild(link) {
		this.linkChildren.splice(
			this.linkChildren.findIndex((l) => l === link),
			1
		)
	}
	removeLinkParent(link) {
		this.linkParents.splice(
			this.linkParents.findIndex((l) => l === link),
			1
		)
	}

	animate(d) {
		super.animate(d)

		this.group.position.set(this.nextPos.x, this.nextPos.y, this.nextPos.z)
		this.JSXNode.group.quaternion.copy(
			this.three.simpleCamera.camera.quaternion
		)

		const distance = this.three.simpleCamera.camera.position.distanceTo(
			this.group.position
		)

		if (distance > this.linkChildren.length + 4)
			this.JSXNode.element.visible = false
		else this.JSXNode.element.visible = true

		let indice = Math.log(this.linkChildren.length / 10 + 1) + 1
		this.group.position.setZ(indice / 1000)
		this.group.scale.setScalar(indice)
		this.group.renderOrder = indice
	}
}

export default ForceNode
