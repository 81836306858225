import React, { FunctionComponent, useState } from 'react'
import Avial, { V_Value, Properties } from '@ledr/ts-client'
import { ValueInput, Icon, FoldWrap, Print } from '@ledr/instruments'
import AnnotationsComponent from './Annotations'

interface PropertiesProps {
	value: Properties
	onChange: (p: Properties) => void
}

const PropertiesComponent: FunctionComponent<PropertiesProps> = (props) => {
	const [newName, setNewName] = useState('')
	const [newKey, setNewKey] = useState('')
	const [newValue, setNewValue] = useState<V_Value>(
		new Avial.values.V_Null('')
	)

	function addProperty() {
		setNewName('')
		setNewKey('')
		setNewValue(new Avial.values.V_Null(''))
		props.onChange(
			new Avial.model.Properties(
				...(props.value ?? []),
				new Avial.model.Property([
					newName,
					newKey,
					JSON.stringify(newValue),
				])
			)
		)
	}

	function editProperty(name, key, value, annotation, i) {
		console.log(name, key, value, annotation, i)
		props.onChange(
			new Avial.model.Properties(
				...props.value.slice(0, i),
				new Avial.model.Property([
					name,
					key,
					JSON.stringify(value),
					annotation,
				]),
				...props.value.slice(i + 1)
			)
		)
	}

	function deleteProperty(i) {
		props.onChange(
			new Avial.model.Properties(
				...props.value.slice(0, i),
				...props.value.slice(i + 1)
			)
		)
	}

	return (
		<FoldWrap title={'Properties'} color={'#ff7de9'}>
			{(subProps) => (
				<div
					style={{
						display: 'grid',
						gridTemplateColumns:
							'min-content repeat(2, auto) min-content auto min-content',
					}}
				>
					<>
						<div className="cellHead">Id</div>
						<div className="cellHead">Name</div>
						<div className="cellHead">Key</div>
						<div className="cellHead">Value</div>

						<div className="cellHead">Annot.</div>
						<div className="cellHead"></div>
					</>
					{props.value?.map((property, i) => (
						<>
							<div className="cellHead">{i}</div>

							<div className="cell">
								<input
									type="text"
									style={{ width: '100%', height: '22px' }}
									value={property.Name}
									placeholder="Name"
									onChange={(evt) => {
										editProperty(
											evt.target.value,
											property.Key,
											property.Value,
											property.Annotations,
											i
										)
									}}
								/>
							</div>

							<div className="cell">
								<input
									type="text"
									style={{ width: '100%', height: '22px' }}
									value={property.Key}
									placeholder="Key"
									onChange={(evt) => {
										editProperty(
											property.Name,
											evt.target.value,
											property.Value,
											property.Annotations,
											i
										)
									}}
								/>
							</div>

							<div className="cell">
								<ValueInput
									value={property.Value}
									onChange={(newValue) => {
										editProperty(
											property.Name,
											property.Key,
											newValue,
											property.Annotations,
											i
										)
									}}
								/>
							</div>

							<div
								className="cell"
								style={{
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								<AnnotationsComponent
									value={property.Annotations}
									onChange={(annotations) =>
										editProperty(
											property.Name,
											property.Key,
											property.Value,
											annotations,
											i
										)
									}
								/>
							</div>

							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<button
									style={{
										background: 'rgba(255,0,0,0.2)',
										color: 'rgba(255,0,0,1)',
										padding: '1px',
										margin: '1px',
										height: '100%',
									}}
									onClick={() => deleteProperty(i)}
								>
									<Icon name={'MdOutlineCancel'} size={20} />
								</button>
							</div>
						</>
					))}

					<div style={{ gridColumn: '2/6' }}>
						<button
							style={{
								width: '100%',
								background: 'rgba(0,255,0,0.2)',
								color: 'rgba(0,255,0,1)',
								padding: '1px',
								margin: '1px',
							}}
							onClick={() => addProperty()}
						>
							<Icon name={'MdAddCircleOutline'} size={20} />
						</button>
					</div>
				</div>
			)}
		</FoldWrap>
	)
}

export default PropertiesComponent
