//@ts-nocheck
import { FunctionComponent, useState } from 'react'
import Avial, { HgtpError } from '@ledr/ts-client'
import './Error.scss'

interface ErrorProps {
	error: HgtpError
	children: JSX.Element
}

const Error: FunctionComponent<ErrorProps> = (props) => {
	return !props.error ? (
		<>{props.children}</>
	) : (
		<div className="Error">
			<p>{props.error.toText()}</p>
		</div>
	)
}

export default Error
