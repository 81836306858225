//@ts-ignore
import { notif } from '@ledr/layout'

import {
	FunctionComponent,
	useCallback,
	useState,
	useEffect,
	useMemo,
	useContext,
} from 'react'
import InstrumentsLinkerContext from '../../context/instrumentsLinker'

import './FlowEditor.scss'

import { Print, WindowChild, EntityInput } from '@ledr/instruments'

import Avial, { AvialEntity } from '@ledr/ts-client'

import ApiContext from '../../context/api'

import Flow from './FlowEditor'
import executeProgramm, { flowToProgramm } from './FlowAdapter'
import useEntity from '../../hook/entity'

function loadFlowFromJson(flow) {
	let parsed = JSON.parse(flow)
	parsed.nodes = parsed.nodes?.map((n) => ({
		...n,
		data: {
			...n.data,
			interface: {
				base: Avial.V_ValueFrom(n.data.interface.base),
				args: Avial.V_ValueFrom(n.data.interface.args),
				in: Avial.V_ValueFrom(n.data.interface.in),
				out: Avial.V_ValueFrom(n.data.interface.out),
			},
			value: Avial.V_ValueFrom(n.data.value ?? n.data.interface.in),
		},
	}))

	return parsed
}
function getAdapters(api, mount) {
	return new Promise(async (res, rej) => {
		let m = await api.get(new Avial.values.V_Entity(mount))
		let list = m?.contents?.Properties
		//	api.getByAttribute('TEST_ATTRIBUTE').Facets
		Promise.all(list?.map((f) => api.get(f.Value)))
			.then((r) => {
				res(r)
			})
			.catch((e) => {
				rej(e)
			})
	})
}

async function saveProgramm(flow, entity, api) {
	console.log('SAVE', flow, entity, api)
	let avialEntity = new AvialEntity()
	console.log(JSON.stringify(flow))
	console.log(new Avial.values.V_String(JSON.stringify(flow)))
	avialEntity.setFact([
		'PROGRAM_ATTRIBUTE',
		new Avial.values.V_String(JSON.stringify(flow)),
	])
	console.log(avialEntity)

	await api.purgeEntity(entity)
	await api.storeEntity(entity, avialEntity)
	await api.get(entity)
}

interface FlowInstrumentProps {}
const FlowInstrument: FunctionComponent<FlowInstrumentProps> = () => {
	const [adapters, setAdapters] = useState([])
	const [entity, setEntity] = useState(new Avial.values.V_Entity(''))

	let currentEntity = useEntity(entity)

	const api = useContext(ApiContext)
	useEffect(() => {
		getAdapters(api, '<0|0|4200>')
			.then((res: any[]) => {
				console.log('NEW RES FLOW', res)

				let methods = []

				res.forEach((adapter) => {
					console.log('adapter', adapter)

					let name =
						adapter.contents.Facts?.getByAttribute(
							'NAME_ATTRIBUTE'
						)?.Value.toString()

					adapter.contents.Facts?.getByAttribute(
						'METHOD_ATTRIBUTE'
					)?.Facets.forEach((method) => {
						methods.push({
							adapter: adapter,
							entity: adapter.metadata.Headings.Entity,
							name: name,
							method: method.Name,
							description: method.Value.toString(),
							interface: {
								base: method.Factors[0].Value,
								args: method.Factors[1].Value,
								in: method.Factors[2].Value,
								out: method.Factors[3].Value,
							},
						})
					})
				})
				console.log('Methods', methods)
				//@ts-ignore
				setAdapters(methods)
			})
			.catch((r) => {
				console.log(r)
			})
	}, [])

	const MyContext = useContext(InstrumentsLinkerContext)

	const [selectedEntity, setSelectedEntity] = useState(
		new Avial.values.V_Entity('<0>')
	)

	const [flow, setFlow] = useState({})

	useEffect(() => {
		let prog =
			currentEntity?.contents?.Facts?.getByAttribute?.(
				'PROGRAM_ATTRIBUTE'
			)?.Value?.value
		console.log('SAVED FLOW')
		setFlow(loadFlowFromJson(prog ?? '{}'))
	}, [currentEntity])

	useEffect(() => {
		console.log('OUTPUT SELECTED', selectedEntity)
		MyContext?.out?.['currentEntity']?.(selectedEntity)
	}, [MyContext?.out?.['currentEntity'], selectedEntity])

	useEffect(() => {
		let parsed = JSON.parse(JSON.stringify(flow))
		parsed.nodes = parsed.nodes?.map((n) => ({
			...n,
			data: {
				...n.data,
				interface: {
					base: Avial.V_ValueFrom(n.data.interface.base),
					args: Avial.V_ValueFrom(n.data.interface.args),
					in: Avial.V_ValueFrom(n.data.interface.in),
					out: Avial.V_ValueFrom(n.data.interface.out),
				},
			},
		}))

		MyContext?.out?.['flow']?.({
			reactFlow: flow,
			reactFlowParsed: parsed,
			programm: flowToProgramm(flow),
		})
	}, [MyContext?.out?.['flow'], flow])

	useEffect(() => {
		MyContext.declareInOut({
			in: [
				{
					port: 'currentProgrammEntity',
					setter: (entity) => setEntity(entity),
				},
				//{port: "currentProgrammEntity", setter: () => setSelectedEntity },
			],
			out: [{ port: 'currentEntity' }, { port: 'flow' }],
		})
		return () => {
			MyContext.unDeclareInOut()
		}
	}, [])

	const tab = useMemo(
		() => (
			<>
				<div></div>
				<button
					onClick={() => {
						saveProgramm(flow, entity, api)
					}}
				>
					SAVE
				</button>
				Programm{' '}
				<EntityInput value={entity} onChange={(e) => setEntity(e)} />
				<div></div>
				<button
					onClick={() => {
						executeProgramm(flow, api)
					}}
				>
					EXECUTE
				</button>
			</>
		),
		[api, flow, entity]
	)

	const win = useMemo(
		() => (
			<>
				<Flow
					flow={flow}
					adapters={adapters}
					selectedEntity={setSelectedEntity}
					onFlowChange={(flow) => {
						setFlow(flow)
					}}
				/>
			</>
		),
		[adapters, flow, currentEntity]
	)
	return <WindowChild tab={tab}>{win}</WindowChild>
}

export default FlowInstrument
