import { FunctionComponent, useCallback, useState, useEffect } from 'react'

import './FlowEditor.scss'

import Avial, { V_Entity } from '@ledr/ts-client'

import ReactFlow, {
	MiniMap,
	Controls,
	Background,
	BackgroundVariant,
} from 'react-flow-renderer'
import {
	Node,
	Edge,
	applyEdgeChanges,
	applyNodeChanges,
	addEdge,
} from 'react-flow-renderer'

import Toolbox from '../../components/hud/Toolbox'

import AdapterNode from './nodes/adapterNode'
import operatorNode from './nodes/operatorNode'
import ValueNode from './nodes/valueNode'
import { InputNode, OutputNode } from './nodes/UtilsNode'
import { Tooltip } from '@ledr/instruments'

const nodeTypes = {
	inputNode: InputNode,
	outputNode: OutputNode,
	adapter: AdapterNode,
	operator: operatorNode,
	value: ValueNode,
}

interface FlowProps {
	flow: any
	adapters: any[]
	selectedEntity: (e: V_Entity) => void
	onFlowChange: (e: any) => void
}

const Flow: FunctionComponent<FlowProps> = (props) => {
	console.log('flowflow', props.adapters)
	const [nodes, setNodes] = useState([])
	const [edges, setEdges] = useState([])

	useEffect(() => {
		console.log('WILL LOAD')

		setNodes(
			props.flow?.nodes?.map((n) => ({
				...n,
				onChangeNodeData: handleChangeNodeData,
				data: {
					...n.data,
					onNodeStateChange: handleChangeNodeData,
				},
			})) ?? []
		)

		setEdges(props.flow?.edges ?? [])
	}, [props.flow])

	const handleChangeNodeData = (id, data) => {
		console.log('handleChangeNodeData')

		let newNodes = nodes.map((node) =>
			node.id === id
				? {
						...node,
						data: data,
						onChangeNodeData: handleChangeNodeData,
				  }
				: node
		)

		setNodes(newNodes)
		props.onFlowChange({ nodes: newNodes, edges })
	}

	const onNodesChange = (changes) => {
		console.log('onNodesChange', changes)

		let newNodes = applyNodeChanges(changes, nodes)
		setNodes(newNodes)
		props.onFlowChange({ nodes: newNodes, edges })
	}

	const onEdgesChange = (changes) => {
		console.log('onEdgesChange', changes)

		let newEdges = applyEdgeChanges(changes, edges)
		setEdges(newEdges)
		props.onFlowChange({ nodes, edges: newEdges })
	}

	const onConnect = (params) => {
		console.log('ON CONNECT', params)

		let newEdges = addEdge({ ...params }, edges)
		setEdges(newEdges)
		props.onFlowChange({ nodes, edges: newEdges })
	}

	const onNodesDelete = (nodes) => {
		console.log('DELETE NODES', nodes)
	}

	const onEdgesDelete = (edges) => {
		console.log('DELETE EDGES', edges)
	}

	return (
		<div style={{ height: '100%', width: '100%', position: 'relative' }}>
			<div
				style={{ height: '100%', width: '100%', position: 'absolute' }}
			>
				<ReactFlow
					nodeTypes={nodeTypes}
					nodes={nodes}
					edges={edges}
					onNodesChange={onNodesChange}
					onEdgesChange={onEdgesChange}
					onConnect={onConnect}
					onNodesDelete={onNodesDelete}
					onEdgesDelete={onEdgesDelete}
					onNodeClick={(evt, node) => {
						if (node?.data?.entity)
							props.selectedEntity(
								new Avial.values.V_Entity(node.data.entity)
							)
					}}
					// WARNING !
					// THIS WILL UNMOUNT NON VISIBLE ELEMENTS
					// SO RESET LOCAL STATE OF
					onlyRenderVisibleElements={true}
					minZoom={0.1}
					maxZoom={2}
					fitView
				>
					<Background
						gap={50}
						size={0.5}
						color={'#222'}
						variant={BackgroundVariant.Lines}
					/>
					<Background
						gap={50 * 5}
						size={1}
						color={'#222'}
						variant={BackgroundVariant.Lines}
					/>
				</ReactFlow>
			</div>
			<div style={{ position: 'absolute', zIndex: 1500 }}>
				<div
					className="threeOptionsTools"
					style={{ marginBottom: '10px' }}
				>
					<Toolbox
						tools={[
							{
								icon: 'MdInput',
								label: `Input`,
								state: false,
								onClick: () => {
									setNodes([
										...nodes,
										{
											id: (nodes.length + 1).toString(),
											type: 'inputNode',
											data: {},
											position: { x: 0, y: 0 },
										},
									])
								},
							},
							{
								icon: 'MdOutput',
								label: `outputNodeX`,
								state: false,
								onClick: () => {
									setNodes([
										...nodes,
										{
											id: (nodes.length + 1).toString(),
											type: 'outputNode',
											data: {},
											position: { x: 0, y: 0 },
										},
									])
								},
							},
						]}
					/>
				</div>
				<div
					className="threeOptionsTools"
					style={{ marginBottom: '10px' }}
				>
					<Toolbox
						tools={[
							{
								icon: 'MdOutlineNumbers',
								label: `value`,
								state: false,
								onClick: () => {
									setNodes([
										...nodes,
										{
											id: (nodes.length + 1).toString(),
											type: 'value',
											data: {},
											position: { x: 0, y: 0 },
										},
									])
								},
							},
							{
								icon: 'BiGitRepoForked',
								label: `operator`,
								state: false,
								onClick: () => {
									setNodes([
										...nodes,
										{
											id: (nodes.length + 1).toString(),
											type: 'operator',
											data: {},
											position: { x: 0, y: 0 },
										},
									])
								},
							},
						]}
					/>
				</div>
				<div
					className="threeOptionsTools"
					style={{ marginBottom: '10px' }}
				>
					<Toolbox
						tools={[
							...props.adapters.map((m) => ({
								icon: 'MdOutlineCategory',
								label: `${m.name} ${m.method}`,
								state: false,
								onClick: () => {
									setNodes([
										...nodes,
										{
											id: (nodes.length + 1).toString(),
											type: 'adapter',
											data: {
												name: m.name,
												method: m.method,
												entity: m.entity,
												//adapter: m.adapter,
												description: m.description,
												interface: m.interface,
												onNodeStateChange:
													handleChangeNodeData,

												/*
												(data) => {
													console.log("nodeChanged",data)
												}
											*/
											},
											position: { x: 0, y: 0 },
										},
									])
								},
							})),
						]}
					/>
				</div>
			</div>
		</div>
	)
}

export default Flow
