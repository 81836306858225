import { FunctionComponent, useState, useEffect, useMemo, useContext } from "react";
import  InstrumentsLinkerContext from "../context/instrumentsLinker";
import {WindowChild, Hexdump, Print} from "@ledr/instruments"; 
import Avial  from "@ledr/ts-client";
import hgtpTemplates from "./HexdumpTemplates";

interface AvuProps {}
const Avu: FunctionComponent<AvuProps> = (props) => {
	const MyContext = useContext(InstrumentsLinkerContext); 

	const [content, setContent] = useState( new Uint8Array(new ArrayBuffer(256)));


	const [hover, setHover] = useState(null);
	const [selectedTemplate, setSelectedTemplate] = useState(0);
	const [lineSize, setLineSize] = useState(5);

	useEffect(()=> {
		//MyContext?.out?.["content"]?.(parseContent(content))
	}, [
		//MyContext?.out?.["content"], content
	])


	useEffect(()=>{
		MyContext.declareInOut( {
			in : [ {port: "hgtp", setter: setContent }, ],
			out :[  ]
		})	
		return () => { MyContext.unDeclareInOut() }
	}, [])


	const tab = useMemo( () => (<>

		<div>
		{hgtpTemplates.map((t, i) => 
			<button
				onClick={() => setSelectedTemplate(i)}
				className={i === selectedTemplate ? "active" : ""}
			>
				{t.name}
			</button>
		)}
		</div>

		<input
			onChange={(e)=>{setLineSize( Number(e.target.value))}}
			value={lineSize}
			type="range" id="volume" name="volume" min="1" max="6"
		/>
	</>), [selectedTemplate, setSelectedTemplate, lineSize, setLineSize]);

	const win = useMemo(() => (
		<>
			<div
				style={{
					display: "flex",
					justifyContent:"space-between",
					margin:"10px"
				}}
			>
				<div>
					cursor: [{hover?.offset ?? "?"}]
				</div>
				<div>
					name:   [{hover?.template?.name ?? "?"}]
				</div>
				<div>
					offset: [{hover?.template?.offset ?? "?"}]
				</div>
				<div>
					length: [{hover?.template?.length ?? "?"}]
				</div>
			</div>
			<Hexdump
				template={hgtpTemplates[selectedTemplate].template}
				lineSize={Math.pow(2, lineSize)}
				buffer={content}//new Buffer([0])}//new Avial.HgtpFrame() }
				onHover={setHover}
			/>

		</>
	), [content, hover, selectedTemplate, setSelectedTemplate, lineSize, setLineSize ]);

	return <WindowChild tab={tab}>{win}</WindowChild>;
};

export default Avu;
