import { FunctionComponent, useState } from 'react'
import { Print } from '@ledr/instruments'
import { contextMenuEntry } from '../init'

interface ContextualMenuProps {
	entries: contextMenuEntry[]
	data: any
	close: () => void
	debug?: any
}

const ContextualMenu: FunctionComponent<ContextualMenuProps> = (props) => {
	const [showDebug, setShowDebug] = useState(false)

	return (
		<div
			onMouseLeave={() => props.close()}
			style={{
				border: '1px solid rgba(64, 64, 64, 1)',
				borderRadius: '4px',
				backgroundColor: 'rgba(32,32,32,0.9)',
				display: 'flex',
				flexDirection: 'column',
				minWidth: '150px',
				maxWidth: '700px',
				padding: '1px',
			}}
		>
			{props.entries.map((e) => (
				<button
					style={{ margin: '1px' }}
					onClick={() => {
						e.onClick(props.data.pid)
						props.close()
					}}
				>
					{e.name}
				</button>
			))}
			<hr />
			<button
				style={{ margin: '1px' }}
				className={showDebug ? 'active' : ''}
				onClick={() => setShowDebug(!showDebug)}
			>
				{' '}
				debug
			</button>
			{showDebug && (
				<div
					style={{
						maxHeight: '500px',
						overflow: 'auto',
					}}
				>
					<Print value={props.data} maxDeepness={1} />
					<Print value={props.debug} maxDeepness={1} />
				</div>
			)}
		</div>
	)
}

export default ContextualMenu
