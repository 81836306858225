import { FC, ReactNode } from 'react'
import './TutoSection.scss'

interface TutoSectionProps {
	title: string
	usage: string | JSX.Element
	children: ReactNode
}

const TutoSection: FC<TutoSectionProps> = (props) => {
	return (
		<div className={'tutoSection'}>
			<h2> {props.title} </h2>
			<p> {props.usage} </p>
			<div className={'blackBoard'}>
				<div className={'windowManagerWrapper'}>{props.children}</div>
			</div>
		</div>
	)
}

export default TutoSection
