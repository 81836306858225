//@ts-ignore
import React, {
	FunctionComponent,
	useState,
	useEffect,
	useContext,
	useMemo,
} from 'react'

import Avial from '@ledr/ts-client'
import { WindowChild } from '@ledr/instruments'

import { useSelector } from 'react-redux'

import ApiContext from '../../context/api'
import { AppState } from '../../store/types'
import './Docker.scss'

interface DockerProps {}

let dockerAdapter = new Avial.values.V_Entity('<1000|1000|102914>')

const Docker: FunctionComponent<DockerProps> = (props) => {
	const api = useContext(ApiContext)
	const token = useSelector(
		(state: AppState) =>
			state.user.keychain.accesses[state.user.keychain.current].token
	)
	const [list, setList] = useState({ Properties: [] })

	const [filters, setFilters] = useState({
		string: '',
		isDownloaded: false,
		isRunning: false,
	})

	//const [filtered, setFiltered] = useState({ Properties: [] })
	const [loading, setLoading] = useState(false)

	async function stop(id) {
		setLoading(true)

		let res = await api.session.call({
			command: 'INVOKE',
			method: 'EXECUTE',
			precedence: 'AVESTERRA',
			entity: dockerAdapter,
			name: 'STOP_ADAPTER',
			key: id,
			authorization: new Avial.values.V_Authorization(token),
		})
		fetchGitlab()
	}

	async function start(id) {
		setLoading(true)
		let res = await api.session.call({
			command: 'INVOKE',
			method: 'EXECUTE',
			precedence: 'AVESTERRA',
			entity: dockerAdapter,
			name: 'START_ADAPTER',
			key: id,
			authorization: new Avial.values.V_Authorization(token),
		})
		fetchGitlab()
	}

	async function fetchGitlab() {
		let res = await api.session.call({
			command: 'INVOKE',
			method: 'EXECUTE',
			precedence: 'AVESTERRA',
			entity: dockerAdapter,
			name: 'LIST_ADAPTERS',
			authorization: new Avial.values.V_Authorization(token),
		})
		if (res.isError()) return
		//@ts-ignore
		setList(res.value.value)
		setLoading(false)
	}

	useEffect(() => {
		fetchGitlab()
	}, [])

	const tab = useMemo(
		() => (
			<>
				<input
					type={'text'}
					placeholder="Search Regex"
					value={filters.string}
					onChange={(evt) =>
						setFilters({
							...filters,
							string: evt.target.value,
						})
					}
				/>
				<div>
					Downloaded:
					<input
						type={'checkbox'}
						onChange={(evt) => {
							console.log(evt.target.value)
							setFilters({
								...filters,
								isDownloaded: !filters.isDownloaded,
							})
						}}
					/>
					Running:
					<input
						type={'checkbox'}
						checked={filters.isRunning}
						onChange={(evt) =>
							setFilters({
								...filters,
								isRunning: !filters.isRunning,
							})
						}
					/>
				</div>
			</>
		),
		[filters]
	)

	const filtered = list.Properties.filter((e) => {
		let name = e[0]
		let version = e[2]['TEXT']
		let status = e[3]['STATUS_ATTRIBUTE']['STRING']

		if (filters.isDownloaded && status === 'none') return false
		if (filters.isRunning && status !== 'running') return false

		if (filters.string !== '') {
			try {
				let containName = filters.string.split(' ').some((e) => {
					if (!e.length) return false
					if (name.match(new RegExp(e, 'g')) === null) return false
					return true
				})
				let containVersion = filters.string.split(' ').some((e) => {
					if (!e.length) return false
					if (version.match(new RegExp(e, 'g')) === null) return false
					return true
				})
				if (!containName && !containVersion) return false
			} catch (e) {
				return true
			}
		}
		return true
	})

	const win = useMemo(
		() => (
			<div
				className={'dockerList'}
				style={{
					display: 'grid',
					gridTemplateColumns:
						'repeat(3, auto) max-content max-content',
					filter: loading ? 'blur(4px)' : '',
				}}
			>
				{filtered?.map((e) => {
					let name = e[0]
					let version = e[2]['TEXT']
					let status = e[3]['STATUS_ATTRIBUTE']['STRING']
					return (
						<>
							<div className={status}>{name}</div>
							<div
								className={status}
								style={{ textAlign: 'right' }}
							>
								{version}
							</div>
							<div
								className={status}
								style={{ textAlign: 'right' }}
							>
								{status}
							</div>
							<div className={status}>
								{(status === 'none' ||
									status === 'downloaded') && (
									<button
										onClick={() => {
											start(e[1])
											console.log(e[1])
										}}
									>
										Start
									</button>
								)}
							</div>

							<div className={status}>
								{status === 'running' && (
									<button
										onClick={() => {
											stop(e[1])
											console.log(e[1])
										}}
									>
										Stop
									</button>
								)}
							</div>
						</>
					)
				})}
			</div>
		),
		[filtered, loading]
	)
	return <WindowChild tab={tab}>{win}</WindowChild>
}

export default Docker
