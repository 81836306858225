declare global {
	interface BigInt {
		toJSON(): Number
	}
}

BigInt.prototype.toJSON = function () {
	return Number(this)
}

import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import Avial from '@ledr/ts-client'
import { AppState } from './types'
import { rootReducer } from './mainReducer'
import { loginAction, logoutAction } from './user/actions'
import { clearLogAction } from './log/actions'

const loadState = () => {
	try {
		const serializedState = localStorage.getItem('state')
		console.log('LOADSTATE')
		if (serializedState === null) return undefined
		return JSON.parse(serializedState)
	} catch (err) {
		console.log('LOAD LOCALSTORAGE IN STORE FAILURE')
		console.log(err)
		return undefined
	}
}

const saveState = (state: AppState) => {
	try {
		const serializedState = JSON.stringify(state.user)
		localStorage.setItem('state', serializedState)
	} catch (err) {}
}

window.onstorage = () => {
	console.log('evt win.onstor')
	let storageState = loadState()
	let storeState = store.getState()

	console.log(storageState)

	if (
		(storeState.user.jwt && !storageState.jwt) ||
		(storeState.user.keychain && !storageState.keychain)
	) {
		console.log(
			storeState.user.jwt,
			storageState.jwt,
			storeState.user.keychain,
			storageState.keychain
		)
		store.dispatch(clearLogAction())
		store.dispatch(logoutAction())
		console.log('LOGOUT BY STORE SYNC CONTROLLER')
	}

	if (
		(!storeState.user.jwt && storageState.jwt) ||
		(!storeState.user.keychain && storageState.keychain)
	)
		store.dispatch(loginAction(storageState))
}

const persistedState = { user: loadState() }
console.log('FIRSTLOAD STATE STORAGE')
console.log(persistedState)
let middleware: any = []

console.log('- CREATE STORE --------------------------------------------')

const store = createStore(
	rootReducer,
	persistedState,
	composeWithDevTools(applyMiddleware(...middleware))
)

store.subscribe(() => {
	saveState(store.getState())
})

export default store
