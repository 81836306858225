import {
	T_TwmArbo,
	T_FwmWindows,
	I_Category,
	I_WmOptions,
} from '@ledr/instruments'

import {
	PanelActionType,
	PANEL_LOAD_ALL,
	PANEL_NEW,
	PANEL_CHANGE,
	PANEL_DELETE,
	PANEL_REMOVE_CATEGORY,
	CATEGORY_NEW,
	CATEGORY_CHANGE,
	CATEGORY_DELETE,
} from './types'

interface I_Links {
	outNode: { winId: string; port: String }
	inNode: { winId: string; port: String }
}
interface I_Cat extends I_Category {
	id: string
}

export interface I_Panels {
	id: string
	name: string
	icon: string
	options: I_WmOptions
	twmArbo: T_TwmArbo
	fwmWindows: T_FwmWindows
	categories: string[]
	links: I_Links[]
}

export interface I_PanelsStore {
	version: number
	categories: I_Cat[]
	panels: I_Panels[]
}

// CONTENTS FILES ARE LOADED DYNAMICALY AND SYSTEMATECALY SOMEWHERE
// IF I DELETE FILE WITHOUT REMOVING IT HERE => LOAD => CRASH

let initialState: I_PanelsStore = {
	version: 1,
	categories: [
		{
			id: 'Category_Entity',
			name: 'Entity',
			content: ['EntityViewer', 'createEntity', 'EntityMenu', 'Chart'],
		},
		{
			id: 'Category_Explore',
			name: 'Explore',
			content: ['viewport', 'Laplacian', 'map', 'outliner'], //, "InstrumentSelector"],
		},

		{
			id: 'Category_system',
			name: 'System',
			content: ['Mount', 'Docker', 'Package', 'FlowEditor'],
		},
		{
			id: 'Category_Dev',
			name: 'Developer',
			content: [
				'Terminal',
				'logger',
				'Printer',
				'EntityLinkerDebug',
				'Hexdump',
				'ValueBuilder',
			],
		},
		{
			id: 'Category_User',
			name: 'User',
			content: ['windowManager', 'Settings', 'Keyboard'],
		},
	],
	panels: [
		{
			id: 'PanelEntityNid_Y',
			name: 'Browser',
			icon: 'BiNetworkChart',
			options: {
				debug: false,
				hideWinTabs: false,
				showNewWindow: false,
				twmResizeable: true,
				showPad: true,
			},
			categories: ['Category_Entity', 'Category_Explore', 'Category_Dev'],
			links: [
				{
					outNode: { winId: 'NHU_ID_C_5', port: 'selectedEntity' },
					inNode: { winId: 'NHU_ID_C_5', port: 'currentEntity' },
				},
				{
					outNode: { winId: 'NHU_ID_C_2', port: 'selectedEntity' },
					inNode: { winId: 'NHU_ID_C_5', port: 'currentEntity' },
				},
				{
					outNode: { winId: 'NHU_ID_C_2', port: 'graph' },
					inNode: { winId: 'NHU_ID_C_1', port: 'graph' },
				},
			],
			twmArbo: {
				direction: 0,
				ratio: 100,
				children: [
					// ====================
					{
						direction: 1,
						ratio: 25,
						children: [
							{
								id: 'NHU_ID_C_0',
								component: 'outliner',
								ratio: 25,
							},
							{
								id: 'NHU_ID_C_1',
								component: 'Laplacian',
								ratio: 25,
							},
							{
								id: 'NHU_ID_C_X',
								component: 'logger',
								ratio: 50,
							},
						],
					},
					{
						id: 'NHU_ID_C_2',
						component: 'viewport',
						ratio: 50,
					},
					{
						id: 'NHU_ID_C_5',
						component: 'EntityViewer',
						ratio: 25,
					},
				],
			},
			fwmWindows: [],
		},
		{
			id: 'PanelEntityNid_C',
			name: 'Edit',
			icon: 'BiEdit',
			options: {
				debug: false,
				hideWinTabs: false,
				showNewWindow: false,
				twmResizeable: true,
				showPad: true,
			},
			categories: ['Category_Entity', 'Category_Explore', 'Category_Dev'],
			links: [
				{
					outNode: { winId: 'NHU_ID_C_2', port: 'BFS' },
					inNode: { winId: 'NHU_ID_C_0', port: 'currentValue' },
				},
				{
					outNode: { winId: 'NHU_ID_C_2', port: 'selectedEntity' },
					inNode: { winId: 'NHU_ID_C_2', port: 'currentEntity' },
				},
				{
					outNode: { winId: 'NHU_ID_C_2', port: 'selectedEntity' },
					inNode: { winId: 'NHU_ID_C_5', port: 'currentEntity' },
				},
				{
					outNode: { winId: 'NHU_ID_C_5', port: 'selectedEntity' },
					inNode: { winId: 'NHU_ID_C_5', port: 'currentEntity' },
				},

				{
					outNode: { winId: 'NHU_ID_C_4', port: 'hgtp' },
					inNode: { winId: 'NHU_ID_C_3', port: 'hgtp' },
				},
				{
					outNode: { winId: 'NHU_ID_C_2', port: 'selectedEntities' },
					inNode: { winId: 'NHU_ID_C_4', port: 'value' },
				},

				{
					outNode: { winId: 'NHU_ID_C_2', port: 'selectedEntity' },
					inNode: { winId: 'NHU_ID_C_4', port: 'entity' },
				},

				{
					outNode: { winId: 'NHU_ID_C_0', port: 'THEN' },
					inNode: { winId: 'NHU_ID_C_2', port: 'reload' },
				},

				{
					outNode: { winId: 'NHU_ID_C_4', port: 'THEN' },
					inNode: { winId: 'NHU_ID_C_2', port: 'reload' },
				},

				{
					outNode: { winId: 'NHU_ID_C_2', port: 'currentEntity' },
					inNode: { winId: 'NHU_ID_C_0', port: 'registry' },
				},
			],
			twmArbo: {
				direction: 0,
				ratio: 100,
				children: [
					{
						direction: 1,
						ratio: 25,
						children: [
							{
								id: 'NHU_ID_C_0',
								component: 'createEntity',
								ratio: 60,
							},

							{
								id: 'NHU_ID_C_1',
								component: 'logger',
								ratio: 40,
							},
						],
					},
					// ====================
					//

					{
						id: 'NHU_ID_C_2',
						component: 'viewport',
						ratio: 50,
					},
					{
						direction: 1,
						ratio: 25,
						children: [
							{
								id: 'NHU_ID_C_4',
								component: 'Terminal',
								ratio: 30,
							},
							{
								id: 'NHU_ID_C_5',
								component: 'EntityViewer',
								ratio: 70,
							},
						],
					},
				],
			},
			fwmWindows: [],
		},

		{
			id: 'PanelEntityNid_E',
			name: 'Space',
			icon: 'BiWorld',
			options: {
				debug: false,
				hideWinTabs: false,
				showNewWindow: false,
				twmResizeable: true,
				showPad: true,
			},
			categories: ['Category_Explore', 'Category_Entity'],
			links: [
				{
					outNode: { winId: 'NHU_ID_E_3', port: 'points' },
					inNode: { winId: 'NHU_ID_E_5', port: 'currentValue' },
				},
				{
					outNode: { winId: 'NHU_ID_E_3', port: 'selectedEntity' },
					inNode: { winId: 'NHU_ID_E_4', port: 'currentEntity' },
				},
				{
					outNode: { winId: 'NHU_ID_E_3', port: 'volume' },
					inNode: { winId: 'NHU_ID_E_2', port: 'volume' },
				},
			],
			twmArbo: {
				direction: 0,
				ratio: 100,
				children: [
					{
						id: 'NHU_ID_E_3',
						component: 'map',
						ratio: 70,
					},
					{
						direction: 1,
						ratio: 30,
						children: [
							{
								id: 'NHU_ID_E_6',
								component: 'logger',
								ratio: 30,
							},
							{
								id: 'NHU_ID_E_4',
								component: 'EntityViewer',
								ratio: 70,
							},
						],
					},
				],
			},
			fwmWindows: [],
		},

		{
			id: 'PanelEntityNid_X',
			name: 'Chart',
			icon: 'AiOutlineBarChart',
			options: {
				debug: false,
				hideWinTabs: false,
				showNewWindow: false,
				twmResizeable: true,
				showPad: true,
			},
			categories: ['Category_Explore', 'Category_Entity'],
			links: [
				{
					outNode: { winId: 'NHU_ID_E_3', port: 'selectedEntity' },
					inNode: { winId: 'NHU_ID_C_2', port: 'rootEntity' },
				},
				{
					outNode: { winId: 'NHU_ID_C_2', port: 'selectedEntity' },
					inNode: { winId: 'NHU_ID_C_2', port: 'currentEntity' },
				},
				{
					outNode: { winId: 'NHU_ID_E_3', port: 'selectedEntity' },
					inNode: { winId: 'NHU_ID_X_3', port: 'currentEntity' },
				},
				{
					outNode: { winId: 'NHU_ID_C_2', port: 'selectedEntity' },
					inNode: { winId: 'NHU_ID_X_4', port: 'currentEntity' },
				},
				{
					outNode: { winId: 'NHU_ID_C_2', port: 'selectedEntity' },
					inNode: { winId: 'NHU_ID_X_3', port: 'currentEntity' },
				},
			],
			twmArbo: {
				direction: 0,
				ratio: 100,
				children: [
					{
						direction: 1,
						ratio: 70,
						children: [
							{
								id: 'NHU_ID_E_3',
								component: 'map',
								ratio: 40,
							},
							{
								direction: 0,
								ratio: 60,
								children: [
									{
										id: 'NHU_ID_C_2',
										component: 'viewport',
										ratio: 50,
									},
									{
										id: 'NHU_ID_X_3',
										component: 'Chart',
										ratio: 50,
									},
								],
							},
						],
					},
					{
						id: 'NHU_ID_X_4',
						component: 'EntityViewer',
						ratio: 30,
					},
				],
			},
			fwmWindows: [],
		},

		{
			id: 'PanelEntityNid_Modeler',
			name: 'Modeler',
			icon: 'BiGitCompare',
			options: {
				debug: false,
				hideWinTabs: false,
				showNewWindow: false,
				twmResizeable: true,
				showPad: true,
			},
			categories: ['Category_system', 'Category_Dev'],
			links: [],
			twmArbo: {
				direction: 0,
				ratio: 100,
				children: [
					{
						id: 'NHU_ID_C_0',
						component: 'EntityViewer',
						ratio: 30,
					},
					{
						id: 'NHU_ID_C_1',
						component: 'Printer',
						ratio: 40,
					},
					{
						id: 'NHU_ID_C_2',
						component: 'EntityViewer',
						ratio: 30,
					},
				],
			},
			fwmWindows: [],
		},

		{
			id: 'PanelEntityNid_system',
			name: 'System',
			icon: 'BiServer',
			options: {
				debug: false,
				hideWinTabs: false,
				showNewWindow: false,
				twmResizeable: true,
				showPad: true,
			},
			categories: ['Category_system', 'Category_Dev'],
			links: [],
			twmArbo: {
				direction: 0,
				ratio: 100,
				children: [
					{
						id: 'NHU_ID_C_1',
						component: 'Mount',
						ratio: 50,
					},
					{
						id: 'NHU_ID_C_2',
						component: 'Package',
						ratio: 50,
					},
				],
			},
			fwmWindows: [],
		},

		/*
		{
			id     : "PanelEntityNid_D",
			name   : "entityLinkerDebug",
			icon   : "MdBorderAll",
			options: {
				debug: false, hideWinTabs: false, showNewWindow: false, twmResizeable: true, showPad: true,
			},
			categories: ["Category_Entity", "Category_User"],
	//@ts-expect-error
			links     : [
				{
					outNode: { winId: "WindowEntityNid_D_3", port: "someOutPort" },
					inNode : { winId: "WindowEntityNid_D_1", port: "someInPort" },
				},
			],
			twmArbo: {
				direction: 0,
				ratio    : 100,
				children : [
					{
						direction: 1,
						ratio    : 50,
						children : [
							{
								id       : "NHU_ID_D_1",
								component: "EntityLinkerDebug",
								ratio    : 50,
							},
							{
								id       : "NHU_ID_D_2",
								component: "EntityLinkerDebug",
								ratio    : 50,
							},
						],
					},
					{
						id       : "NHU_ID_D_3",
						component: "EntityLinkerDebug",
						ratio    : 50,
					},
				],

			},
	fwmWindows: [],
},
	*/
		{
			id: 'PanelEntityNid_Dev',
			name: 'Dev',
			icon: 'RiCodeSSlashFill',
			options: {
				debug: false,
				hideWinTabs: false,
				showNewWindow: false,
				twmResizeable: true,
				showPad: true,
			},
			categories: ['Category_Dev', 'Category_Entity'],
			links: [
				{
					outNode: { winId: 'winID2', port: 'hgtp' },
					inNode: { winId: 'winID3', port: 'hgtp' },
				},
				{
					outNode: { winId: 'winID2', port: 'content' },
					inNode: { winId: 'winID4', port: 'currentValue' },
				},
				{
					outNode: { winId: 'winID1', port: 'value' },
					inNode: { winId: 'winID2', port: 'value' },
				},
			],
			twmArbo: {
				direction: 0,
				ratio: 100,
				children: [
					{
						direction: 1,
						ratio: 50,
						children: [
							{
								direction: 0,
								ratio: 60,
								children: [
									{
										direction: 1,
										ratio: 50,
										children: [
											{
												id: 'winID1',
												component: 'ValueBuilder',
												ratio: 20,
											},
											{
												id: 'winID2',
												component: 'Terminal',
												ratio: 80,
											},
										],
									},
									{
										id: 'winID9',
										component: 'logger',
										ratio: 50,
									},
								],
							},

							{
								id: 'winID3',
								component: 'Hexdump',
								ratio: 40,
							},
						],
					},
					{
						direction: 0,
						ratio: 50,
						children: [
							{
								id: 'winID4',
								component: 'EntityViewer',
								ratio: 50,
							},
							{
								id: 'winID5',
								component: 'EntityViewer',
								ratio: 50,
							},
						],
					},
				],
			},
			fwmWindows: [],
		},
		{
			id: 'PanelEntityNid_Flow',
			name: 'Flow',
			icon: 'RiFlowChart',
			options: {
				debug: false,
				hideWinTabs: false,
				showNewWindow: false,
				twmResizeable: true,
				showPad: true,
			},
			categories: ['Category_Dev', 'Category_Entity', 'Category_system'],
			links: [
				{
					outNode: { winId: 'winID0', port: 'selectedEntity' },
					inNode: { winId: 'winID1', port: 'currentEntity' },
				},
				{
					outNode: { winId: 'winID3', port: 'flow' },
					inNode: { winId: 'winID4', port: 'currentValue' },
				},
				{
					outNode: { winId: 'winID13', port: 'value' },
					inNode: { winId: 'winID14', port: 'locutor' },
				},
				{
					outNode: { winId: 'winID2', port: 'selectedEntity' },
					inNode: { winId: 'winID3', port: 'currentProgrammEntity' },
				},
			],
			twmArbo: {
				direction: 0,
				ratio: 100,
				children: [
					{
						direction: 1,
						ratio: 25,
						children: [
							{
								id: 'winID10',
								component: 'Docker',
								ratio: 50,
							},
							{
								id: 'winID0',
								component: 'EntityViewer',
								ratio: 20,
							},
							{
								id: 'winID1',
								component: 'EntityViewer',
								ratio: 30,
							},
						],
					},
					{
						id: 'winID3',
						component: 'FlowEditor',
						ratio: 55,
					},
					{
						direction: 1,
						ratio: 20,
						children: [
							{
								id: 'winID4',
								component: 'Printer',
								ratio: 60,
							},
							{
								id: 'winID5',
								component: 'logger',
								ratio: 40,
							},
						],
					},
				],
			},
			fwmWindows: [],
		},

		{
			id: 'PanelEntityNid_A',
			name: 'Settings',
			icon: 'BiCog',
			options: {
				debug: false,
				hideWinTabs: false,
				showNewWindow: false,
				twmResizeable: true,
				showPad: true,
			},
			categories: ['Category_User'],
			links: [],
			twmArbo: {
				direction: 0,
				ratio: 100,
				children: [
					{
						direction: 1,
						ratio: 50,
						children: [
							{
								id: 'NHU_ID_A_2',
								component: 'Settings',
								ratio: 60,
							},
							{
								id: 'NHU_ID_A_3',
								component: 'Keyboard',
								ratio: 40,
							},
						],
					},
					{
						id: 'NHU_ID_A_1',
						component: 'windowManager',
						ratio: 50,
					},
				],
			},
			fwmWindows: [],
		},
	],
}

function panelLoadAll(state: I_PanelsStore, payload): I_PanelsStore {
	return payload.panelsStore
}

function newPanel(state: I_PanelsStore, payload): I_PanelsStore {
	return {
		...state,
		panels: [
			...state.panels,
			{
				id: payload.hash,
				name: 'tmp',
				icon: 'MdBorderAll',
				options: {
					debug: false,
					hideWinTabs: false,
					showNewWindow: false,
					twmResizeable: true,
					showPad: true,
				},
				categories: ['Category_User'],
				links: [],
				twmArbo: {
					direction: 0,
					ratio: 100,
					children: [
						{
							id: 'NHU_ID_C_0',
							component: 'windowManager',
							ratio: 100,
						},
					],
				},
				fwmWindows: [],
			},
		],
	}
}

function panelChange(state: I_PanelsStore, payload): I_PanelsStore {
	return {
		...state,
		panels: state.panels.map((e) =>
			e.id !== payload.panel.id ? e : { ...e, ...payload.panel }
		),
	}
}

function panelDelete(state: I_PanelsStore, payload): I_PanelsStore {
	return {
		...state,
		panels: state.panels.filter((e) => e.id !== payload.hash),
	}
}

function newCategory(state: I_PanelsStore, payload): I_PanelsStore {
	return {
		...state,
		categories: [
			...state.categories,
			{
				id: payload.hash,
				name: 'New category',
				content: [],
			},
		],
	}
}

function categoryChange(state: I_PanelsStore, payload): I_PanelsStore {
	return {
		...state,
		categories: state.categories.map((e, i) =>
			i !== payload.categoryId ? e : payload.newCategory
		),
	}
}

function panelRemoveCategory(state: I_PanelsStore, payload): I_PanelsStore {
	return {
		...state,
		panels: state.panels.map((p) =>
			p.id !== payload.panelId
				? p
				: {
						...p,
						categories: p.categories.filter(
							(c) => c !== payload.categoryId
						),
				  }
		),
	}
}

function deleteCategory(state: I_PanelsStore, payload): I_PanelsStore {
	return {
		...state,
		categories: state.categories.filter((e) => e.id !== payload.hash),
	}
}

let RESET = 'RESET'
function panelsStoreReducer(
	state = initialState,
	action: PanelActionType
): I_PanelsStore {
	switch (action.type) {
		case PANEL_LOAD_ALL:
			return panelLoadAll(state, action.payload)

		case PANEL_NEW:
			return newPanel(state, action.payload)
		case PANEL_CHANGE:
			return panelChange(state, action.payload)
		case PANEL_DELETE:
			return panelDelete(state, action.payload)

		case PANEL_REMOVE_CATEGORY:
			return panelRemoveCategory(state, action.payload)

		case CATEGORY_NEW:
			return newCategory(state, action.payload)
		case CATEGORY_CHANGE:
			return categoryChange(state, action.payload)
		case CATEGORY_DELETE:
			return deleteCategory(state, action.payload)

		case RESET:
			return initialState

		default:
			return state
	}
}

export default panelsStoreReducer
