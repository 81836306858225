import Avial, { V_Aggregate } from '@ledr/ts-client'
import monaco from 'monaco-editor'

const keywords = [
	'COMMAND',
	'ERROR',
	'VERSION',
	'REPORT',
	'METHOD',
	'ATTRIBUTE',
	'EVENT',
	'MODE',
	'CATEGORY',
	'CLASS',
	'CONTEXT',
	'ASPECT',
	'STATE',
	'PRECEDENCE',
	'TAG',
	'CONDITION',
	'INSTANCE',
	'OFFSET',
	'TIME',
	'INDEX',
	'COUNT',
	'EXTENSION',
	'PARAMETER',
	'RESULTANT',
	'TIMEOUT',
	'ENTITY',
	'OUTLET',
	'AUXILIARY',
	'ANCILLARY',
	'AUTHORIZATION',
	'AUTHORITY',
	'NAME',
	'KEY',
]

function taxoToNames(taxo) {
	return Avial.Taxonomy[taxo].byId
		.map((name) => name.toString().toLowerCase())
		.join('|')
}

let CommandNames = taxoToNames('Command')
let ReportNames = taxoToNames('Report')
let MethodNames = taxoToNames('Method')
let ContextNames = taxoToNames('Context')
let ClassNames = taxoToNames('Class')
let CategoryNames = taxoToNames('Category')
let AttributeNames = taxoToNames('Attribute')
let EventNames = taxoToNames('Event')
let AspectNames = taxoToNames('Aspect')
let PrecedenceNames = taxoToNames('Precedence')
let TagNames = taxoToNames('Tag')
let ModeNames = taxoToNames('Mode')
let StateNames = taxoToNames('State')
let ConditionNames = taxoToNames('Condition')
let ErrorNames = taxoToNames('Error')

export const completionHGTP = (monaco) => {
	const taxo = []
	taxo.push({
		label: 'SAVE',
		insertText: '',
		description: `HGTP COMMAND`,
		kind: monaco.languages.CompletionItemKind.Method,
		insertTextRules:
			monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
	})

	taxo.push({
		label: 'LOAD avial fetch',
		insertText: '',
		description: `HGTP COMMAND`,
		kind: monaco.languages.CompletionItemKind.Method,
		insertTextRules:
			monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
	})
	taxo.push({
		label: 'LOAD avial invoke',
		insertText: '',
		description: `HGTP COMMAND`,
		kind: monaco.languages.CompletionItemKind.Method,
		insertTextRules:
			monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
	})

	taxo.push({
		label: 'EXECUTE THIS COMMAND',
		insertText: '',
		description: `HGTP COMMAND`,
		kind: monaco.languages.CompletionItemKind.Method,
		insertTextRules:
			monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
	})
	// // // // // // // // // // // // // // // // // // // // // // // // //
	// // // // // // // // // // // // // // // // // // // // // // // // //
	// // // // // // // // // // // // // // // // // // // // // // // // //
	/*
	let ReportNames =
		Object.keys(Avial.Report)
		.filter((report) => !Number.isNaN(Number(Avial.Report[report])))
		.forEach((name) => {
			taxo.push({
				label: `REPORT ${name.toLowerCase()}`,
				insertText: `REPORT ${name.toLowerCase()}`,
				description: `HGTP REPORT`,
				kind: monaco.languages.CompletionItemKind.Method,
				insertTextRules:
					monaco.languages.CompletionItemInsertTextRule
						.InsertAsSnippet,
			})
		})
	keywords.forEach((e) => {
		taxo.push({
			label: e,
			insertText: e,
			description: `HGTP COMMAND`,
			kind: monaco.languages.CompletionItemKind.Method,
			insertTextRules:
				monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
		})
	})

	Object.keys(Avial.Command).forEach((command) => {
		if (Number.isNaN(Number(Avial.Command[command]))) {
			taxo.push({
				label: `COMMAND ${Avial.Command[command].toLowerCase()}`,
				insertText: `COMMAND ${Avial.Command[command].toLowerCase()}`,
				description: `HGTP COMMAND`,
				kind: monaco.languages.CompletionItemKind.Method,
				insertTextRules:
					monaco.languages.CompletionItemInsertTextRule
						.InsertAsSnippet,
			})
		}
	})
*/

	Object.keys(Avial.Taxonomy).forEach((element) => {
		Object.keys(Avial.Taxonomy[element].byName).forEach((item) => {
			taxo.push({
				label: `${element.toUpperCase()} ${item.toLowerCase()}`,
				insertText: `${element.toUpperCase()} ${item.toLowerCase()}`,
				description: `HGTP ${element}`,
				kind: monaco.languages.CompletionItemKind.Class,
				insertTextRules:
					monaco.languages.CompletionItemInsertTextRule
						.InsertAsSnippet,
			})
		})
	})

	return taxo
}

///
/// REGEX ARE "GREEDY" BY DEFAULT
/// SET "LAZY" MODE ENABLED TO CATCH FULL WORD
/// (MATCH)      "HOST"
/// (BUT SHOULD) "HOSTel"
/// ON ENUMS
///

export const monarchHGTP: monaco.languages.IMonarchLanguage = {
	keywords: [],
	tokenizer: {
		root: [
			[/COMMAND/, 'keyword', '@commandEnum'],
			[/METHOD/, 'keyword', '@methodEnum'],
			[/CONTEXT/, 'keyword', '@contextEnum'],

			[/ATTRIBUTE/, 'keyword', '@attributeEnum'],
			[/CATEGORY/, 'keyword', '@categoryEnum'],
			[/CLASS/, 'keyword', '@classEnum'],

			[/EVENT/, 'keyword', '@eventEnum'],
			[/ASPECT/, 'keyword', '@aspectEnum'],
			[/PRECEDENCE/, 'keyword', '@precedenceEnum'],
			[/TAG/, 'keyword', '@tagEnum'],
			[/MODE/, 'keyword', '@modeEnum'],
			[/STATE/, 'keyword', '@stateEnum'],
			[/REPORT/, 'keyword', '@reportEnum'],

			[/CONDITION/, 'keyword', '@conditionEnum'],
			[/ERROR/, 'keyword', '@errorEnum'],

			[/NAME/, 'keyword', '@string'],
			[/KEY/, 'keyword', '@string'],

			[/ENTITY/, 'keyword', '@entity'],
			[/OUTLET/, 'keyword', '@entity'],
			[/AUXILIARY/, 'keyword', '@entity'],
			[/ANCILLARY/, 'keyword', '@entity'],
			[/OUTLET/, 'keyword', '@entity'],

			[/INSTANCE/, 'keyword', '@number'],
			[/OFFSET/, 'keyword', '@number'],
			[/INDEX/, 'keyword', '@number'],
			[/COUNT/, 'keyword', '@number'],
			[/PARAMETER/, 'keyword', '@number'],
			[/RESULTANT/, 'keyword', '@number'],
			[/TIMEOUT/, 'keyword', '@number'],
			[/TIME/, 'keyword', '@number'],
			[/VERSION/, 'keyword', '@number'],

			[/AUTHORIZATION/, 'keyword', '@authorization'],
			[/AUTHORITY/, 'keyword', '@authorization'],

			[/VALUE/, 'keyword', '@string'],

			[/.+/, 'type.other'],
		],

		number: [[/[0-9]+/, 'type.number', '@pop']],
		string: [[/".+"/, 'type.string', '@pop']],

		entity: [[/<([0-9]+)(\|[0-9]+)?(\|[0-9]+)?>/, 'type.entity', '@pop']],
		authorization: [
			[
				/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/g,
				'type.auth',
				'@pop',
			],
		],

		commandEnum: [[new RegExp(CommandNames), 'type.enum', '@pop']],
		methodEnum: [[new RegExp(MethodNames), 'type.enum', '@pop']],
		contextEnum: [[new RegExp(ContextNames), 'type.enum', '@pop']],
		attributeEnum: [[new RegExp(AttributeNames), 'type.enum', '@pop']],
		categoryEnum: [[new RegExp(CategoryNames), 'type.enum', '@pop']],
		classEnum: [[new RegExp(ClassNames), 'type.enum', '@pop']],
		eventEnum: [[new RegExp(EventNames), 'type.enum', '@pop']],
		aspectEnum: [[new RegExp(AspectNames), 'type.enum', '@pop']],
		precedenceEnum: [[new RegExp(PrecedenceNames), 'type.enum', '@pop']],
		tagEnum: [[new RegExp(TagNames), 'type.enum', '@pop']],
		modeEnum: [[new RegExp(ModeNames), 'type.enum', '@pop']],
		stateEnum: [[new RegExp(StateNames), 'type.enum', '@pop']],
		reportEnum: [[new RegExp(ReportNames), 'type.enum', '@pop']],
		conditionEnum: [[new RegExp(ConditionNames), 'type.enum', '@pop']],
		errorEnum: [[new RegExp(ErrorNames), 'type.enum', '@pop']],
	},
}

export function textToFrame(content: string) {
	let NEW = {}

	content
		?.split(/(\r\n|\n|\r)/gm)
		?.map((e) => {
			const regex = /\s+(?=(?:[^"]*"[^"]*")*[^"]*$)/g
			let newContent = e?.replace(/\s+/g, ' ').trim().split(regex)
			newContent[0] = newContent[0]?.replace(/(\r\n|\n|\r)/gm, '')
			newContent[1] = newContent[1]?.replace(/(\r\n|\n|\r)/gm, '')
			if (
				newContent[0] === 'NAME' ||
				newContent[0] === 'KEY' ||
				newContent[0] === 'VALUE'
			)
				newContent[1] = newContent[1]?.slice(
					1,
					newContent[1]?.length - 1
				)
			return newContent
		})
		.filter((e) => e[0].length !== 0)
		.map((e) => {
			if (e[0] && e[1] !== undefined) {
				if (
					typeof e[1] === 'string' &&
					e[0] !== 'NAME' &&
					e[0] !== 'KEY' &&
					e[0] !== 'VALUE' &&
					e[0] !== 'TIME' &&
					e[0] !== 'INDEX' &&
					e[0] !== 'COUNT' &&
					e[0] !== 'PARAMETER' &&
					e[0] !== 'RESULTANT' &&
					e[0] !== 'TIMEOUT'
				)
					NEW[e[0]] = e[1].toUpperCase()
				else if (
					e[0] === 'TIME' ||
					e[0] === 'INDEX' ||
					e[0] === 'COUNT' ||
					e[0] === 'PARAMETER' ||
					e[0] === 'RESULTANT' ||
					e[0] === 'TIMEOUT'
				) {
					console.log('TO BIGINT')
					try {
						NEW[e[0]] = BigInt(e[1])
					} catch (e) {}
				} else NEW[e[0]] = e[1]
			}
		})

	if (NEW['ENTITY'])
		try {
			NEW['ENTITY'] = new Avial.values.V_Entity(NEW['ENTITY']).value
		} catch (e) {}
	if (NEW['OUTLET'])
		try {
			NEW['OUTLET'] = new Avial.values.V_Entity(NEW['OUTLET']).value
		} catch (e) {}
	if (NEW['AUXILIARY'])
		try {
			NEW['AUXILIARY'] = new Avial.values.V_Entity(NEW['AUXILIARY']).value
		} catch (e) {}
	if (NEW['ANCILLARY'])
		try {
			NEW['ANCILLARY'] = new Avial.values.V_Entity(NEW['ANCILLARY']).value
		} catch (e) {}

	if (NEW['AUTHORIZATION'])
		NEW['AUTHORIZATION'] = new Avial.values.V_Authorization(
			NEW['AUTHORIZATION']
		).value
	if (NEW['AUTHORITY'])
		NEW['AUTHORITY'] = new Avial.values.V_Authorization(
			NEW['AUTHORITY']
		).value

	NEW['VALUE'] = Avial.V_ValueFrom(NEW['VALUE'])

	/*
	if (NEW['VALUE']) {
		try {
			let tmp = JSON.parse(NEW['VALUE'])
			let tag = Object.keys(tmp)[0]

			if (tag === undefined) throw new Error('Invalid V_VALUE')

			let value = tmp[tag]
			NEW['VALUE'] = {}
			NEW['VALUE'].tag = Avial.Taxonomy.Tag.byName[tag]
			NEW['VALUE'].data = new TextEncoder().encode(value)

		} catch (e) {
			console.log(e)

			NEW['VALUE'] = {
				tag: 0,
				data: new Uint8Array(),
			}
		}
	}
*/
	let NEW2 = {}
	for (let key in NEW) {
		NEW2[key.toLowerCase()] = NEW[key]
	}
	let frame = new Avial.Frame(NEW2)
	return frame
}
