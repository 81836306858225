import { useState, useEffect, useRef, useContext, ReactNode } from 'react'
import Avial, { V_Array, V_Entity } from '@ledr/ts-client'
import { Print, ArrayInput } from '@ledr/instruments'
import * as THREE from 'three'

import HudContext from './../../context/hud'
import { FunctionComponent } from 'react'
import { PanelContent } from '../hud/Panel'
import Toolbox from '../hud/Toolbox'

import ThreeContext from '../../context/three'
import { ThreeInit, contextMenuEntry } from './init'

import { SubclassIcon } from '../../components/three/components/Label'
import './hud.scss'

import { Icon, wmContext } from '@ledr/instruments'

interface ThreeProps {
	nodes: any
	links: any

	rootEntity: V_Entity
	currentEntity: V_Entity
	selectedEntity: V_Entity

	onSelect: (any) => void
	onSelected: (entities: V_Array) => void

	contextMenu?: contextMenuEntry[]

	offset: number

	classes: any
	setClasses: (index: number, content: any) => void
	children?: ReactNode
}

const Three: FunctionComponent<ThreeProps> = (props) => {
	const mountRef = useRef(null)
	const hud = useContext(HudContext)

	const [three, setThree] = useState(null)

	const [filtersLoc, setFiltersLoc] = useState(new Avial.values.V_Array([]))

	const [passes, setPasses] = useState(1)
	const [gravity, setGravity] = useState(0.01)
	const [repulsion, setRepulsion] = useState(0.6)
	const [linkStrength, setlinkStrength] = useState(0.6)
	const [childFree, setChildFree] = useState(false)

	useEffect(() => {
		console.log('========== THREE INIT ==========')
		let t
		try {
			t = new ThreeInit(mountRef.current)
			t.setRoot(props.rootEntity)
			setThree(t)
		} catch (e) {
			console.log(e)
		}

		return () => {
			console.log('========== THREE EXIT ==========')
			t.dismount?.()
		}
	}, [])

	useEffect(() => {
		hud.declare([
			{
				name: 'Camera',
				x: 'left',
				y: 'top',
				widget: (data) => <Toolbox tools={data} />,
			},
			{
				name: 'Graph',
				x: 'left',
				y: 'top',
				widget: (data) => <Toolbox tools={data} />,
			},
			{
				name: 'Classes',
				x: 'center',
				y: 'center',
				widget: (data) => (
					<PanelContent title={'Classes'} isOpen={true}>
						<div className="classElementList">
							{data?.classes.map((c, i) => (
								<div
									className={`classElement ${
										c.display ? '' : 'hidded'
									}`}
									onMouseEnter={() => {
										data.three.nodes2.forEach((n) => {
											n.JSXNode.div.style.opacity = '0.1'
											if (n.data.class === c.class)
												n.styles.activate('highlight')
										})

										data.three.nodes2.forEach((n) => {
											if (n.data.class === c.class)
												n.styles.activate('highlight')
										})
									}}
									onMouseLeave={() => {
										data.three.nodes2.forEach((n) => {
											n.styles.desactivate('highlight')
										})
									}}
								>
									<div
										style={{
											margin: '1px',
											display: 'flex',
											alignItems: 'center',
											width: '25px',
										}}
									>
										<SubclassIcon
											class={c.class}
											size={25}
										/>
									</div>

									<div
										style={{
											width: '100%',
											justifyContent: 'space-between',
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<div
											style={{
												padding: '0px 5px',
											}}
										>
											{c?.class?.split('_')[0]}
										</div>
										<div>{c.count}</div>
									</div>

									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											cursor: 'pointer',
											justifyContent: 'right',
										}}
										onClick={() => {
											data.setClasses(i, {
												display: !c.display,
											})
										}}
									>
										{c.display ? (
											<Icon
												size={20}
												name={'AiOutlineEye'}
											/>
										) : (
											<Icon
												size={20}
												name={'AiOutlineEyeInvisible'}
											/>
										)}
									</div>

									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											cursor: 'pointer',
											justifyContent: 'right',
										}}
										onClick={() => {
											data.three.nodes2.forEach((n) => {
												if (n.data.class === c.class) {
													if (!c.edge) {
														n.styles.activate(
															'edge'
														)
														n.isEdge = true
													} else {
														n.styles.desactivate(
															'edge'
														)
														n.isEdge = false
													}
												}
											})
											data.setClasses(i, {
												edge: !c.edge,
											})
										}}
									>
										{c.edge ? (
											<Icon
												size={20}
												name={'BiShapePolygon'}
												style={{ color: 'white' }}
											/>
										) : (
											<Icon
												size={20}
												name={'BiShapePolygon'}
												style={{ color: 'grey' }}
											/>
										)}
									</div>
								</div>
							))}
						</div>
					</PanelContent>
				),
			},
			{
				name: 'Info',
				y: 'top',
				x: 'right',
				widget: (data) => (
					<PanelContent title={'Info'} isOpen={true}>
						Nodes : {data?.nodes}
						<br />
						Links : {data?.links}
						<br />
						Show Child Free:{' '}
						<input
							style={{
								height: '20px',
								width: '20px',
							}}
							type="checkbox"
							value={data?.childFree ? 'true' : 'false'}
							onChange={() => {
								setChildFree(!data?.childFree)
								data.three.force.opts.childFree =
									!data?.childFree
								data.three.force.hide()
							}}
							onMouseEnter={() => {
								data.three.nodes2.forEach((n) => {
									n.JSXNode.div.style.opacity = '0.1'
									if (n.linkChildren.length !== 0)
										n.styles.activate('highlight')
								})

								data.three.nodes2.forEach((n) => {})
							}}
							onMouseLeave={() => {
								data.three.nodes2.forEach((n) => {
									n.styles.desactivate('highlight')
								})
							}}
						/>
					</PanelContent>
				),
			},
			{
				name: 'Force',
				y: 'top',
				x: 'right',
				widget: (data) => (
					<PanelContent title={'Force'} isOpen={true}>
						PASSES [{data?.passes}]
						<input
							type="range"
							min={0}
							max={100}
							step={1}
							value={data?.passes}
							onChange={(evt) =>
								setPasses(Number(evt.target.value))
							}
						/>
						GRAVITY [{data?.gravity}]
						<input
							type="range"
							min={0}
							max={0.1}
							step={0.001}
							value={data?.gravity}
							onChange={(evt) =>
								setGravity(Number(evt.target.value))
							}
						/>
						REPULSION [{data?.repulsion}]
						<input
							type="range"
							min={0}
							max={10}
							step={0.01}
							value={data?.repulsion}
							onChange={(evt) =>
								setRepulsion(Number(evt.target.value))
							}
						/>
						LINK STRENGTH [{data?.linkStrength}]
						<input
							type="range"
							min={0}
							max={10}
							step={0.01}
							value={data?.linkStrength}
							onChange={(evt) =>
								setlinkStrength(Number(evt.target.value))
							}
						/>
					</PanelContent>
				),
			},
			{
				name: 'Filters',
				y: 'top',
				x: 'right',
				widget: (data) => (
					<PanelContent title={'Filters'} isOpen={true}>
						<div style={{ padding: '5px' }}>
							{data?.filtersLoc && (
								<ArrayInput
									value={data?.filtersLoc}
									onChange={(v) => {
										setFiltersLoc(v)
									}}
								/>
							)}
						</div>
					</PanelContent>
				),
			},
		])
	}, [])

	useEffect(() => {
		if (!three) return
		three.force.setOpts({
			...three.force.opts,
			passes,
			linkStrength,
			repulsion,
			gravity,
		})
		hud.setData('Force', three.force.opts)
	}, [three, passes, linkStrength, repulsion, gravity])

	useEffect(() => {
		hud.setData('Classes', {
			classes: props.classes,
			setClasses: props.setClasses,
			three: three,
		})
	}, [three, props.classes, props.setClasses])

	useEffect(() => {
		if (!three) return

		three.force.setOpts({ ...three.force.opts, childFree })

		hud.setData('Info', {
			nodes: props.nodes.length,
			links: props.links.length,
			childFree: childFree,
			three: three,
		})
	}, [three, props.nodes, props.links, childFree])

	useEffect(() => {
		if (!three) return

		hud.setData('Camera', [
			{
				label: 'Control Orbit/Flat',
				icon: 'MdCameraswitch',
				state: three.simpleCamera.orbit,
				onClick: () => {
					three.simpleCamera.switchOrbit()
					three.triggerReactLifeCycle()
				},
			},
			{
				label: 'Camera Perspective/Orthographic',
				icon: 'BsBox',
				state: three.simpleCamera.ortho,
				onClick: () => {
					three.simpleCamera.switchPerspective()
					three.triggerReactLifeCycle()
				},
			},
			{
				label: 'Camera Auto track',
				icon: 'MdCenterFocusStrong',
				state: three.simpleCamera.autoTrack,
				onClick: () => {
					three.simpleCamera.switchAutoTrack()
					three.triggerReactLifeCycle()
				},
			},
		])

		hud.setData('Graph', [
			{
				label: 'Graph switchFlat',
				icon: 'BiLogoGraphql',
				state: three.nodes.isFlat,
				onClick: () => {
					three.nodes.switchFlat()
					three.triggerReactLifeCycle()
				},
			},
		])
	}, [three])

	useEffect(() => {
		if (three)
			hud.setData('Filters', {
				three: three,
				filtersLoc: filtersLoc,
			})
	}, [three, filtersLoc])

	useEffect(() => {
		console.log('UPDATE JSX EFFECT')
		three?.nodes?.update(props.nodes, props.links)
	}, [props.nodes]) //, props.links])

	useEffect(() => {
		three?.setRoot(props.rootEntity)
	}, [props.rootEntity])

	useEffect(() => {
		three?.setCurrent(props.currentEntity)
	}, [props.currentEntity])

	useEffect(() => {
		if (three) three.contextMenu = props.contextMenu
	}, [three, props.contextMenu])

	useEffect(() => {
		if (three?.selectedEntity) props.onSelect(three.selectedEntity)
	}, [three?.selectedEntity])

	useEffect(() => {
		if (three?.selectedEntities) props.onSelected(three.selectedEntities)
	}, [three?.selectedEntities])

	if (three)
		three.reactLifeCycle = (three) => {
			const clone = Object.assign({}, three)
			Object.setPrototypeOf(clone, ThreeInit.prototype)
			setThree(clone)
		}

	return (
		<>
			<div
				style={{
					display: 'flex',
					flex: '1 1 auto',
					height: '100%',
					width: '100%',
					position: 'relative',
					overflow: 'hidden',
				}}
				ref={mountRef}
			></div>

			{three ? (
				<ThreeContext.Provider value={three}>
					{props.children}
				</ThreeContext.Provider>
			) : (
				<div>ERROR: NO WEBGL CONTEXT</div>
			)}
		</>
	)
}

export default Three
