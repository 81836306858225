import * as THREE from 'three'
import { ThreeInit } from '../init'

function workerLoader() {
	let toto = new URL('./monWorker.worker.js', import.meta.url)
	const url = toto.origin + '/monWorker.worker.worker.js'
	// DOWNLOAD BLOB WORKER (aside CORS) THEN LOAD IT
	const content = `importScripts( "${url}" );`
	const worker_url = URL.createObjectURL(
		new Blob([content], { type: 'text/javascript' })
	)
	return new Worker(worker_url)
}

class Force {
	group: THREE.Group
	nodes: any
	links: any

	three: ThreeInit

	worker: Worker

	opts = {
		passes: 1,
		gravity: 0.0001,
		repulsion: 0.006,
		linkStrength: 0.006,
		childFree: true,
	}

	constructor(three) {
		console.log('BUILD FORCE ')
		this.three = three

		this.nodes = []
		this.links = []

		try {
			this.worker = workerLoader()

			//	SHARED_ARRAY_BUFFER NEED CORS (because of spectre/meltdown, cpu archi issue)
			//	Cross-Origin-Opener-Policy: same-origin
			//	const sab = new SharedArrayBuffer(1024)
			//	worker.postMessage(sab)
		} catch (e) {
			console.log('worker error: ', e)
		}
	}

	destruct() {
		this.worker.terminate()
	}

	setOpts(opts) {
		this.opts = opts
		this.worker.postMessage({
			type: 'setOpts',
			opts: this.opts,
		})
	}

	moveNode(id, pos) {
		console.log('moveNode_____')
		this.worker.postMessage({
			type: 'moveNode',
			id: id,
			pos: {
				x: pos.x,
				y: pos.y,
				z: pos.z,
			},
		})
	}

	updateFixed() {
		this.worker.postMessage({
			type: 'updateFixed',
			fixed: this.three.nodes.nodes
				.filter((n) => n.fixed || n.dragging)
				.map((n) => n.actualID),
		})
	}

	async update() {
		this.nodes = this.three.nodes.nodes
		this.nodes.forEach((n, id) => {
			n.actualID = id
		})

		console.log('LOOK HERE', this.nodes)
		//this.links = this.three.nodes.links

		let buf = new Float32Array(this.nodes.length * 3)

		for (let i = 0; i < this.nodes.length; i++) {
			let offset = i * 3
			let np = this.nodes[i].group.position
			buf[offset + 0] = np.x
			buf[offset + 1] = np.y
			buf[offset + 2] = np.z
		}
		//geom.setAttribute('position', new THREE.Float32BufferAttribute(pointData , 3));

		this.worker.onmessage = (evt) => {
			//console.log('Worker has send :', evt.data)
			let positions = evt.data.positions
			let nodesLen = evt.data.positions.length / 3

			for (let i = 0; i < nodesLen; i++) {
				let offset = i * 3
				this.nodes[i]?.nextPos.set(
					positions[offset + 0],
					positions[offset + 1],
					positions[offset + 2]
				)
			}
		}
		this.worker.postMessage({
			type: 'startSimu',
			opts: this.opts,
			positions: buf,

			links: this.nodes.map((n) =>
				n.linkChildren.map((lc) => lc.dst.actualID)
			),
			fixed: this.three.nodes.nodes
				.filter((n) => n.fixed || n.dragging)
				.map((n) => n.actualID),
		})
	}
}

export default Force
