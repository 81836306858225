import * as THREE from 'three'
import Avial from '@ledr/ts-client'

import ViewportElement from '../ViewportElement'
import ForceNode from '../force/forceNode'
import ForceLink from '../force/forceLink'
import { iconClassColor } from '../../../style/icons'

//import ForceLink from './forceLink';
// import Edge from './edge';

const level = {
	NULL_CLASS: 0,
	SERVER_CLASS: 1,
	RESTAURANT_CLASS: 2,
	AVESTERRA_CLASS: 3,
	HOTEL_CLASS: 4,
	LIST_CLASS: 5,
	ADAPTER_CLASS: 6,
	INSTRUMENT_CLASS: 7,
	REGISTRY_CLASS: 0,
	CITY_CLASS: 10,
	IDENTITY_CLASS: 11,
	FOLDER_CLASS: 12,
	SPACE_CLASS: 13,
	PERSON_CLASS: 14,
	FAMILY_CLASS: 15,
	EXAMPLE_CLASS: 16,
	TABLE_CLASS: 17,
	AIRPORT_CLASS: 18,
	EMPLOYEE_CLASS: 19,
	HOUSEKEEPING_CLASS: 20,
	ROOM_CLASS: 21,
	LOCATION_CLASS: 22,
	ACCOUNT_CLASS: 23,
	RECEPTION_CLASS: 24,
	TEST_CLASS: 25,
	COUNTRY_CLASS: 26,
	PUBLICATION_CLASS: 27,
	TIMER_CLASS: 28,
	CATEGORY_CLASS: 29,
	ANALYTIC_CLASS: 30,
	TEMPLATE_CLASS: 31,
	VEHICLE_CLASS: 32,
	FEATURE_CLASS: 33,
	UNIVERSITY_CLASS: 34,
	CONFERENCE_CENTER_CLASS: 35,
	RELATIONSHIP_CLASS: 36,
	BUSINESS_CLASS: 37,
	TEAM_CLASS: 38,
	EVENT_CLASS: 39,
	MONEY_CLASS: 40,
	TRANSACTION_CLASS: 41,
	FILTER_CLASS: 42,
	// PATCH FOR MISSING CLASSES TAXO
}
// EXTENDS OVER ORCHESTRA THREE CLASS
class Nodes extends ViewportElement {
	nodes: ForceNode[]
	links: ForceLink[]
	isFlat = true
	meshLinks: THREE.LineSegments
	meshNodes: THREE.Points

	constructor(three) {
		super(three)
		this.nodes = []
		this.links = []
		//this.initView();

		// NODES
		const materialNodes = new THREE.PointsMaterial({
			size: 0.05,
			vertexColors: true,
		})
		let geometryNodes = new THREE.BufferGeometry()
		this.meshNodes = new THREE.Points(geometryNodes, materialNodes)
		this.updateNodesPoints()
		this.group.add(this.meshNodes)

		// LINKS
		const materialLinks = new THREE.LineBasicMaterial({
			vertexColors: true,
			blending: THREE.AdditiveBlending,
			transparent: true,
		})

		let geometryLinks = new THREE.BufferGeometry()
		this.meshLinks = new THREE.LineSegments(geometryLinks, materialLinks)
		this.updateLinksLines()
		this.group.add(this.meshLinks)
	}

	getNodeById(pid) {
		return this.nodes.find((n) => {
			n.data.pid === pid
		})
	}

	updateNodes(nodes) {
		// REMOVE OLD
		this.nodes = this.nodes.filter((node) => {
			if (!nodes.find((n) => n.pid === node.data.pid)) {
				node.destruct()
				this.group.remove(node.group)
				return false
			}
			return true
		})

		// ADD NEW
		nodes
			.filter((n) => !this.nodes.find((nn) => nn.data.pid === n.pid))
			.forEach((node) => {
				//this.three.nodes.getNodeById()

				let newNode = new ForceNode(
					{
						pos: {
							x: (Math.random() - 0.5) * 1,
							y: (Math.random() - 0.5) * 1,
							// z: (Math.random() - 0.5) * 1,
							z: 0, //(Math.random() - 0.5)
						},
						data: {
							name: node.name,
							class: node.class,
							category: node.category,
							pid: node.pid,
						},
					},
					this.three
				)

				this.nodes.push(newNode)
				this.group.add(newNode.group)
			})

		//@ts-ignore
		this.three.nodes2 = this.nodes
	}

	updateLinks(links) {
		// REMOVE OLD
		this.links = this.links.filter((link) => {
			let nodeSource = this.nodes.find((n) => n.data.pid === link.src)
			let nodeTarget = this.nodes.find((n) => n.data.pid === link.src)
			if (!nodeSource || !nodeTarget) {
				link.destruct()
				this.group.remove(link.group)
				return false
			}
			return true
		})

		// ADD NEW
		links.forEach((l) => {
			let nodeSource = this.nodes.find((n) => n.data.pid === l.src)
			let nodeTarget = this.nodes.find((n) => n.data.pid === l.dst)
			if (nodeSource && nodeTarget) {
				if (
					this.links.find(
						(ll) => ll.src === nodeSource && ll.dst === nodeTarget
					)
				)
					return

				let link = new ForceLink(
					nodeSource,
					nodeTarget,
					l.locutor,
					this.three
				)

				this.links.push(link)

				this.group.add(link.group)
			}
		})

		// @ts-ignore
		this.three.links2 = this.links
	}

	async update(nodes: any[], links: any[]) {
		console.log('UPDATE')

		this.updateNodes(nodes)
		this.updateLinks(links)

		this.nodes.forEach((n) => {
			n.initView()
		})

		// this.hide()
		// nodes.links = new Links() ?
		// REFRESH LAYERS.INIT TO REFRESH LAYERS.
		// layers.forEach(l.init(nodes))

		this.updateLinksLines()
		this.updateNodesPoints()

		this.three?.force?.update()
	}

	updateLinksLines() {
		this.meshLinks.geometry.dispose()
		const pos = new Float32Array(this.links.length * 3 * 2)
		const col = new Float32Array(this.links.length * 3 * 2)
		let geometry = new THREE.BufferGeometry()
		geometry.setAttribute('position', new THREE.BufferAttribute(pos, 3))
		geometry.setAttribute('color', new THREE.BufferAttribute(col, 3))
		geometry.computeBoundingSphere()
		this.meshLinks.geometry = geometry
	}

	updateNodesPoints() {
		this.meshNodes.geometry.dispose()
		const pos = new Float32Array(this.nodes.length * 3)
		const col = new Float32Array(this.nodes.length * 3)
		let geometry = new THREE.BufferGeometry()
		geometry.setAttribute('position', new THREE.BufferAttribute(pos, 3))
		geometry.setAttribute('color', new THREE.BufferAttribute(col, 3))
		geometry.computeBoundingSphere()
		this.meshNodes.geometry = geometry
	}

	animate(d) {
		super.animate(d)
		let isHovering = false

		this.nodes.forEach((n) => {
			this.applyLayers(n)

			if (!this.isFlat)
				n.group.position.setZ(-(level[n.data.class] ?? 0) / 5)

			n.animate(d)

			if (n.hovered) isHovering = true
		})

		const color = new THREE.Color(0x000000)

		const t0 = performance.now()

		let pN = this.meshNodes.geometry.attributes.position.array
		let cN = this.meshNodes.geometry.attributes.color.array
		this.nodes.forEach((n, i) => {
			let offset = i * 3
			pN[offset + 0] = n.group.position.x
			pN[offset + 1] = n.group.position.y
			pN[offset + 2] = n.group.position.z

			if (isHovering) {
				if (n.hovered || n.linkParents.find((L) => L.src.hovered))
					color.setHSL(
						iconClassColor(n.data.class ?? '') / 360,
						1.0,
						0.5
					)
				else color.setHSL(0, 0.0, 0.0)
			} else
				color.setHSL(iconClassColor(n.data.class ?? '') / 360, 1.0, 0.5)
			cN[offset + 0] = color.r
			cN[offset + 1] = color.g
			cN[offset + 2] = color.b
		})

		this.meshNodes.geometry.attributes.position.needsUpdate = true
		this.meshNodes.geometry.attributes.color.needsUpdate = true
		this.meshNodes.geometry.computeBoundingSphere()

		let pL = this.meshLinks.geometry.attributes.position.array
		let cL = this.meshLinks.geometry.attributes.color.array

		this.links.forEach((l, i) => {
			// NEEDED FOR LOCUTOR LINK
			//l.animate(d)
			// NEEDED FOR LOCUTOR LINK

			let offset = i * 6
			pL[offset + 0] = l.src.group.position.x
			pL[offset + 1] = l.src.group.position.y
			pL[offset + 2] = l.src.group.position.z
			pL[offset + 3] = l.dst.group.position.x
			pL[offset + 4] = l.dst.group.position.y
			pL[offset + 5] = l.dst.group.position.z

			if (isHovering) {
				color.setHSL(
					0, //iconClassColor(l.dst.data.class ?? '') / 360,
					0.0,
					0.0
				)
			} else {
				color.setHSL(
					iconClassColor(l.dst.data.class ?? '') / 360,
					1.0,
					0.5
				)
			}

			if (l.src.hovered) {
				color.setHSL(
					iconClassColor(l.dst.data.class ?? '') / 360,
					1.0,
					0.5
				)
			}

			cL[offset + 0] = 0 //color.r
			cL[offset + 1] = 0 //color.g
			cL[offset + 2] = 0 //color.b

			cL[offset + 3] = color.r
			cL[offset + 4] = color.g
			cL[offset + 5] = color.b
		})

		this.meshLinks.geometry.attributes.position.needsUpdate = true
		this.meshLinks.geometry.attributes.color.needsUpdate = true
		this.meshLinks.geometry.computeBoundingSphere()

		const t1 = performance.now()

		//console.log('BUFF', t1 - t0)
	}

	destruct() {}

	switchFlat() {
		this.isFlat = !this.isFlat
	}

	applyLayers(n) {
		/*
			this.layers = [
				{
					name: "byClass",
					scale: 1,
					opts: {},
					init: {},
					var: {},
					projectMatrice: (n, var) => {
						let id = var.classes.findIndex(c => c === n.class)
						if (n.class === 0)
							n.pos.z = id;
						}
				}
			];

			this.layers.forEach(l => {
				n.position.project(l.projectMatrice(n))
			})
			n.nextPos()
		*/
	}
}

export default Nodes
