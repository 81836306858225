import { FunctionComponent } from 'react'

import keyboardContext from '../../context/keyboard'
import Tuto from './Tuto'
import { Provider } from 'react-redux'
import ApiContext from '../../context/api'

import { useKeyboardMain } from '../../hook/keyboard'

interface MainAppProps {
	basename: string
	overseer: any
	api: any
}

const MainApp: FunctionComponent<MainAppProps> = (props) => {
	return (
		<ApiContext.Provider value={props.api}>
			<Provider store={props.overseer.store}>
				<keyboardContext.Provider value={useKeyboardMain()}>
					<Tuto />
				</keyboardContext.Provider>
			</Provider>
		</ApiContext.Provider>
	)
}

export default MainApp
