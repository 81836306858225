import Avial  from "@ledr/ts-client";
import {ValueInput} from "@ledr/instruments";
import { FunctionComponent, useState, useEffect } from "react";
import { useCallback } from "react";
import { Handle, Position } from "react-flow-renderer";
import "./adapterNode.scss";
import windows from "../index";
import { Icon, Print } from "@ledr/instruments";

interface OutputNodeProps {}
const InputNode: FunctionComponent<OutputNodeProps> = (props) => {
  return (
    //@ts-ignor
    <div className={`flowNode`}>
			<h1 style={{}}>
				[START NODE]
			</h1>

						<Handle
							className="handle"
							key={"0"}
							type="source"
							position={Position.Right}
							id={"0"}
						>
						</Handle>

    </div>
  );
};

interface OutputNodeProps {}
const OutputNode: FunctionComponent<OutputNodeProps> = (props) => {
  return (
    //@ts-ignor
    <div className={`flowNode`}>
			<h1 style={{}}>
				[END NODE]
			</h1>
						<Handle
							className="handle"
							key={"0"}
							type="target"
							position={Position.Left}
							id={"0"}
						>
						</Handle>

    </div>
  );
};

export {InputNode, OutputNode};


